import { makeStyles } from "@material-ui/core";

export const homeStyles = makeStyles(() => ({
  welcome: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  username: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "400px",
    textOverflow: "ellipsis",
  },
}));
