import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { DataGrid } from "../../../components/datagrid/DataGrid";
import { ConfirmModal } from "../../../components/modals/ConfirmModal";
//Redux
import {
  deleteChargePointType,
  fetchChargePointTypes,
  getChargePointTypes,
  tryToDelete,
  unsetChargePointTypeError,
} from "../../../store/chargePointTypes";
//Other
import { tableColumns } from "./assets/fields";
import { ChargePointType } from "../../../store/chargePointTypes/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { CHARGE_POINT_TYPE_TITLE } from "./assets/namings";

interface Props {}

export const ChargePointTypesTable: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { navigateToAdd, navigateToDetail } = useNavigation();
  useErrorModal(getChargePointTypes, unsetChargePointTypeError);

  const { isRequesting, data, deleteId } = useSelector(getChargePointTypes);

  const handleDetail = useCallback(
    (event: React.FormEvent<HTMLDivElement>, row: object) => {
      navigateToDetail(row as ChargePointType);
    },
    [navigateToDetail]
  );

  const handleDelete = useCallback(
    (event: React.FormEvent<HTMLDivElement>, row: object) => {
      const { id } = row as ChargePointType;
      dispatch(tryToDelete(id));
    },
    [dispatch]
  );

  const secondaryActions = useMemo(
    () => [
      {
        label: "View/Edit",
        action: handleDetail,
      },
      {
        label: "Delete",
        action: handleDelete,
      },
    ],
    [handleDetail, handleDelete]
  );

  const handleDeleteCancel = useCallback(() => dispatch(tryToDelete(null)), [
    dispatch,
  ]);

  const handleDeleteConfirm = useCallback(() => {
    if (!deleteId) {
      return;
    }

    dispatch(deleteChargePointType(deleteId));
  }, [deleteId, dispatch]);

  useEffect(() => {
    dispatch(fetchChargePointTypes());
  }, [dispatch]);

  return (
    <WidgetContainer>
      <DataGrid
        columns={tableColumns}
        data={data}
        noDataLabel={`There are no ${CHARGE_POINT_TYPE_TITLE}s`}
        secondaryActions={secondaryActions}
        isLoading={isRequesting}
        onAdd={navigateToAdd}
      />
      <ConfirmModal
        open={!!deleteId}
        title={`Delete ${CHARGE_POINT_TYPE_TITLE}`}
        content={`Are you sure you want to delete this ${CHARGE_POINT_TYPE_TITLE}?`}
        onConfirm={handleDeleteConfirm}
        onClose={handleDeleteCancel}
      />
    </WidgetContainer>
  );
};
