import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
//Components
import { HvLoading } from "@hitachivantara/uikit-react-core";
//Styles
import { loadingStyles } from "./assets/styles";

interface Props {
  isLoading?: boolean;
}

export const Loading: React.FC<Props> = ({
  children,
  isLoading,
  ...others
}) => {
  const ref = useRef(null);
  const classes = loadingStyles();
  const [overlayPosition, setOverlayPosition] = useState({});

  useEffect(() => {
    if (children && ref.current) {
      const {
        clientHeight,
        clientWidth,
        offsetTop,
        offsetLeft,
      } = ref.current as any;
      setOverlayPosition({
        top: offsetTop,
        left: offsetLeft,
        height: clientHeight,
        width: clientWidth,
      });
    }
  }, [children]);
  return (
    <>
      <div
        style={{ ...overlayPosition }}
        className={clsx(classes.overlay, { [classes.blur]: isLoading })}
      >
        <HvLoading
          small
          classes={{ root: classes.loading }}
          hidden={!isLoading}
          {...others}
        />
      </div>
      <div ref={ref}>{children}</div>
    </>
  );
};
