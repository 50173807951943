import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { HvSnackbar } from "@hitachivantara/uikit-react-core";
//Redux
import { getNotification } from "../../store/notifications/selectors";
import { unsetNotification } from "../../store/notifications";

interface Props { }

export const SuccessNotification: React.FC<Props> = () => {
    const dispatch = useDispatch();

    const { type, message } = useSelector(getNotification);

    // const handleClose = (event, reason) => {
    //   if (reason === "clickaway") return;

    // };

    const handleClose = useCallback(() =>
        dispatch(unsetNotification())
        , [dispatch]);

    return (
        <HvSnackbar
            open={type === "success"}
            onClose={handleClose}
            offset={10}
            variant={"success"}
            label={message ?? ""}
            showIcon
        />
    );
};