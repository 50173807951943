import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { Form } from "../../../components/forms/Form";
//Redux
import { getSites, insertSite, unsetSiteError, cloneSitePeriods } from "../../../store/sites";
//Others
import { MainComponentProps } from "../../Layout/types/types";
import { detailFields } from "./assets/fields";
import { FormValues, FormValuesLine } from "../../../components/forms/assets/types";
import { Site } from "../../../store/sites/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { SITE_TITLE } from "./assets/namings";
import { SiteDescription } from "./assets/Tooltips";

export const SitesAdd: React.FC<MainComponentProps> = () => {
  const dispatch = useDispatch();
  const { navigateToTable } = useNavigation();
  useErrorModal(getSites, unsetSiteError);

  const fields = useMemo(() => detailFields.filter(({ id }) => !id), []);

  const [isInserting, setIsInserting] = useState(false);
  const { isRequesting, detail, error } = useSelector(getSites);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const site = values as Site;
      dispatch(insertSite(site));
    },
    [dispatch]
  );

  const handleOnClone = useCallback((formValues: FormValues) => {
    const detail = formValues["cloneDetail"] as Array<FormValuesLine>;
    const siteTariff = detail.map((formLine) => ({
      electricity_tariff_start_daily_ptu: formLine.electricity_tariff_start_daily_ptu,
      electricity_tariff_end_daily_ptu: formLine.electricity_tariff_end_daily_ptu,
      electricity_tariff_pence_per_kwh: formLine.electricity_tariff_pence_per_kwh
    }));

    const siteDetail = formValues as Site;
    if (siteDetail.electricity_tariff_pence_per_kwh_variable) {
      siteDetail.electricity_tariff_pence_per_kwh_variable.mon = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.tue = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.wed = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.thu = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.fri = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.sat = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.sun = siteTariff;
    };

    dispatch(cloneSitePeriods(siteDetail));
  }, []);

  useEffect(() => {
    if (isRequesting) {
      setIsInserting(true);
    } else if (isInserting) {
      if (error) {
        setIsInserting(false);
      } else {
        navigateToTable();
      }
    }
  }, [isRequesting, error, isInserting, setIsInserting, navigateToTable]);

  return (
    <WidgetContainer>
      <Form
        isLoading={isRequesting}
        title={`Add ${SITE_TITLE}`}
        description={<SiteDescription />}
        fields={fields}
        initialValues={detail}
        formErrors={error?.formErrors}
        onSubmit={handleSubmit}
        onCancel={navigateToTable}
        onClone={handleOnClone}
      />
    </WidgetContainer>
  );
};
