import { StoreValues } from "../../../../components/forms/assets/types";
import { fetchSchedules, getSchedules } from "../../../../store/schedules";
import { Schedule } from "../../../../store/schedules/type";
import { formatScheduleDescription } from "./formattings";
import { ListValues } from "../../../../components/forms/assets/types";

export const weekDaysListValues: ListValues[] = [
  {
    id: "1",
    label: "Sunday",
  },
  {
    id: "2",
    label: "Monday",
  },
  {
    id: "3",
    label: "Tuesday",
  },
  {
    id: "4",
    label: "Wednesday",
  },
  {
    id: "5",
    label: "Thursday",
  },
  {
    id: "6",
    label: "Friday",
  },
  {
    id: "7",
    label: "Saturday",
  },
];

export const loadSchedulesList: StoreValues = {
  action: fetchSchedules,
  selector: getSchedules,
  format: (values: Schedule[]) =>
    values.map((schedule) => ({
      id: String(schedule.id),
      label: formatScheduleDescription(schedule),
    })),
};
