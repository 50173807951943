import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
//Components
import { ConfirmModal } from "../../components/modals/ConfirmModal";
//Hooks
import useMsal from "../../components/msal/assets/useMsal";

interface Props {}

export const Logout: React.FC<Props> = () => {
  const { logout } = useMsal();
  const history = useHistory();
  const [isLogoutConfirmed, setIsLogoutConfirmed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleCancelLogout = () => {
    setIsModalOpen(false);
    history.goBack();
  };

  useEffect(() => {
    if (isLogoutConfirmed) {
      logout();
    }
  }, [logout, isLogoutConfirmed]);

  return (
    <>
      <ConfirmModal
        open={isModalOpen}
        title={"Logout"}
        content={"Are you sure you want to leave?"}
        onConfirm={() => setIsLogoutConfirmed(true)}
        onClose={handleCancelLogout}
      />
      {isLogoutConfirmed && <Redirect to="/" />}
    </>
  );
};
