import * as msal from "@azure/msal-browser";
import { MsalProviderRedirectConfig } from "../types/types";

const clientId = process.env.REACT_APP_AAD_APP_CLIENTID ?? "missing-client-id";
const redirectUri =
  process.env.REACT_APP_AAD_REDIRECT_URI ?? "missing-redirect-uri";
const b2cScopePath =
  process.env.REACT_APP_AAD_B2C_SCOPE_PATH ?? "missing-b2c-scope";

const b2cSusiPolicyName =
  process.env.REACT_APP_AAD_POLICY_SUSI ?? "missing-susi-policy";
const b2cForgotPasswordPolicyName =
  process.env.REACT_APP_AAD_POLICY_FORGOT_PASSWORD ??
  "missing-forgot-passoword-policy";
const b2cEditProfilePolicyName =
  process.env.REACT_APP_AAD_POLICY_EDIT_PROFILE ??
  "missing-edit-profile-policy";
const b2cAadAuthority =
  process.env.REACT_APP_AAD_AUTHORITY ?? "missing-b2c-authority";

const b2cAadAuthorityDomain = b2cAadAuthority + ".b2clogin.com";
const policyUrl =
  "https://" +
  b2cAadAuthorityDomain +
  "/" +
  b2cAadAuthority +
  ".onmicrosoft.com/";
const b2cSusiPolicyUrl = policyUrl + b2cSusiPolicyName;
const b2cForgotPasswordPolicyUrl = policyUrl + b2cForgotPasswordPolicyName;
const b2cEditProfilePolicyUrl = policyUrl + b2cEditProfilePolicyName;
const b2cScopeUrl = [
  "https://" + b2cAadAuthority + "onmicrosoft.com/" + b2cScopePath,
];

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: b2cSusiPolicyName,
    forgotPassword: b2cForgotPasswordPolicyName,
    editProfile: b2cEditProfilePolicyName,
  },
  authorities: {
    signUpSignIn: {
      authority: b2cSusiPolicyUrl,
      scopes: b2cScopeUrl,
    },
    forgotPassword: {
      authority: b2cForgotPasswordPolicyUrl,
      scopes: b2cScopeUrl,
    },
    editProfile: {
      authority: b2cEditProfilePolicyUrl,
      scopes: b2cScopeUrl,
    },
  },
  authorityDomain: b2cAadAuthorityDomain,
};

export const msalConfig: MsalProviderRedirectConfig = {
  type: "redirect",
  msalConfig: {
    auth: {
      clientId: clientId, // This is the ONLY mandatory field; everything else is optional.
      authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
      knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
      redirectUri: redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
      cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  },
  silentRequestConfig: {
    scopes: b2cScopeUrl,
  },
  endSessionRequestConfig: {},
  redirectRequestConfig: {
    scopes: b2cScopeUrl,
  },
};

export const msalInstance = new msal.PublicClientApplication(
  msalConfig.msalConfig
);
