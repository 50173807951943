import { Schedule, Trip } from "../../../../store/schedules/type";
import { getApiHoursString } from "../../../../utils/dates";

export const mapScheduleToApiSchedule = (schedule: Schedule): Schedule => ({
  id: schedule.id ?? 0,
  name: schedule.name,
  mon: schedule.mon ? mapTripsToApiTrips(schedule.mon) : [],
  tue: schedule.tue ? mapTripsToApiTrips(schedule.tue) : [],
  wed: schedule.wed ? mapTripsToApiTrips(schedule.wed) : [],
  thu: schedule.thu ? mapTripsToApiTrips(schedule.thu) : [],
  fri: schedule.fri ? mapTripsToApiTrips(schedule.fri) : [],
  sat: schedule.sat ? mapTripsToApiTrips(schedule.sat) : [],
  sun: schedule.sun ? mapTripsToApiTrips(schedule.sun) : [],
});

export const mapTripsToApiTrips = (trips: Trip[]) =>
  trips.map((trip) => ({
    trip_start_time: getApiHoursString(new Date(trip.trip_start_time)),
    trip_end_time: getApiHoursString(new Date(trip.trip_end_time)),
    trip_distance_miles: trip.trip_distance_miles,
  }));
