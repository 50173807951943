import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { DataGrid } from "../../../components/datagrid/DataGrid";
import { ConfirmModal } from "../../../components/modals/ConfirmModal";
import { HvTypography } from "@hitachivantara/uikit-react-core";
//Redux
import {
  deleteScenario,
  fetchScenarios,
  getScenarios,
  tryToDelete,
  unsetScenarioError,
} from "../../../store/scenarios";
//Other
import { tableColumns } from "./assets/fields";
import { Scenario, ResultStatus } from "../../../store/scenarios/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { SCENARIO_TITLE } from "./assets/namings";
import { Refresh } from "@hitachivantara/uikit-react-icons";
import { tableActionStyles } from "../ScenariosManage/assets/styles";

interface Props {}

export const ScenariosTable: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = tableActionStyles();
  const { navigateToAdd, navigateToDetail } = useNavigation();
  useErrorModal(getScenarios, unsetScenarioError);

  const {
    isRequesting: isRequestingScenarios,
    data: scenariosData,
    deleteId,
  } = useSelector(getScenarios);

  const formatData = useMemo(
    () =>
      scenariosData
        ? scenariosData.map((scenario) => {
            return {
              ...scenario,
            };
          })
        : [],
    [scenariosData]
  );

  const handleDetail = useCallback(
    (event: React.FormEvent<HTMLDivElement>, row: object) => {
      navigateToDetail(row as Scenario);
    },
    [navigateToDetail]
  );

  const handleDelete = useCallback(
    (event: React.FormEvent<HTMLDivElement>, row: object) => {
      const { id } = row as Scenario;
      dispatch(tryToDelete(id));
    },
    [dispatch]
  );

  const secondaryActions = useMemo(
    () => [
      {
        label: "View/Edit",
        action: handleDetail,
      },
      {
        label: "Delete",
        action: handleDelete,
      },
    ],
    [handleDetail, handleDelete]
  );

  const handleDeleteCancel = useCallback(
    () => dispatch(tryToDelete(null)),
    [dispatch]
  );

  const handleDeleteConfirm = useCallback(() => {
    if (!deleteId) {
      return;
    }

    dispatch(deleteScenario(deleteId));
  }, [deleteId, dispatch]);

  const getStatusLabels = () => {
    return (
      <>
        {scenariosData.filter(function (scenario) {
          return (
            scenario.scenario_result_status === ResultStatus.Waiting ||
            scenario.scenario_result_status === ResultStatus.Processing ||
            scenario.scenario_result_status === ResultStatus.Started
          );
        }).length > 0 && (
          <>
            <HvTypography variant="normalText" className={classes.label}>
              *Please refresh the page with the <Refresh /> button to check the
              current status.
            </HvTypography>
          </>
        )}
        {scenariosData.filter(function (scenario) {
          return scenario.scenario_result_status === ResultStatus.Failed;
        }).length > 0 && (
          <HvTypography variant="normalText">
            **Try pressing recalculate again. If it continues to fail please
            email us at:{" "}
            <a href="mailto: spt.feedback@optimise-prime.com" target="__blank">
              spt.feedback@optimise-prime.com
            </a>
            .
          </HvTypography>
        )}
      </>
    );
  };

  useEffect(() => {
    dispatch(fetchScenarios());
  }, [dispatch]);

  return (
    <WidgetContainer>
      <DataGrid
        columns={tableColumns}
        data={formatData}
        noDataLabel={`There are no ${SCENARIO_TITLE}s`}
        labels={getStatusLabels()}
        secondaryActions={secondaryActions}
        isLoading={isRequestingScenarios}
        onAdd={navigateToAdd}
      />
      <ConfirmModal
        open={!!deleteId}
        title={`Delete ${SCENARIO_TITLE}`}
        content={`Are you sure you want to delete this ${SCENARIO_TITLE}? Deleting a scenario will delete any results calculated previously.`}
        onConfirm={handleDeleteConfirm}
        onClose={handleDeleteCancel}
      />
    </WidgetContainer>
  );
};
