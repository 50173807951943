import React, { ReactNode, useCallback, useEffect, useState } from "react";
import useForm from "./assets/useForm";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import { StyledButton } from "../styled/inputs";
import { Loading } from "../loading/Loading";
import { FormFields } from "./FormFields";
//Styles
import { formStyles } from "./assets/styles";
import { FormErrors, FormField, FormValues, FormValuesLine } from "./assets/types";
import validate from "./assets/validate";
import { ConfirmModal } from "../modals/ConfirmModal";

interface Props {
  title: string;
  description?: string | ReactNode;
  fields: FormField[];
  formErrors?: FormErrors;
  initialValues?: { [key: string]: any } | null;
  isLoading?: boolean;
  allowSubmitWithoutChanges?: boolean;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onSubmit: (values: FormValues) => void;
  onCancel?: () => void;
  onClone?: (lines: FormValues) => any | null;
}

export const Form: React.FC<Props> = ({
  title,
  description,
  fields,
  formErrors,
  initialValues,
  isLoading = false,
  allowSubmitWithoutChanges = false,
  confirmButtonLabel = "Confirm",
  cancelButtonLabel = "Cancel",
  onSubmit,
  onCancel,
  onClone,
}) => {
  const classes = formStyles();

  const [tryingToLeave, setTryingToLeave] = useState(false);
  const {
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handleReset,
    errors,
    values,
    isPristine,
  } = useForm(initialValues ?? {}, formErrors ?? {}, onSubmit, (values) =>
    validate(fields, values)
  );

  const handleOnClone = useCallback((cloneDetail: Array<FormValuesLine>) => {
   
    let formValues: FormValues = [];
    setValues((values) => {
      formValues = values;
      return formValues;
    });
    formValues["cloneDetail"] = cloneDetail;

    if (onClone)
      onClone(formValues);

  }, []);

  const handleConfirmTryingToLeave = useCallback(() => onCancel && onCancel(), [
    onCancel,
  ]);
  const handleCancelTryingToLeave = useCallback(
    () => setTryingToLeave(false),
    []
  );

  const handleCancel = useCallback(
    () => (isPristine ? handleConfirmTryingToLeave() : setTryingToLeave(true)),
    [isPristine, handleConfirmTryingToLeave]
  );

  useEffect(() => setValues(initialValues ?? {}), [setValues, initialValues]);
  useEffect(() => formErrors && setErrors(formErrors), [setErrors, formErrors]);
  useEffect(() => handleReset(), [initialValues, handleReset]);

  return (
    <HvGrid container>
      <HvGrid item xs={12} className={classes.title}>
        <HvTypography variant="xsTitle">{title}</HvTypography>
      </HvGrid>
      {description && (
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">{description}</HvTypography>
        </HvGrid>
      )}
      <Loading isLoading={isLoading}>
        <HvGrid item xs={12}>
          <FormFields
            initialValues={initialValues ?? {}}
            values={values}
            fields={fields}
            errors={errors}
            onChange={handleChange}
            onClone={handleOnClone}
          />
        </HvGrid>
      </Loading>
      <HvGrid item xs={12} className={classes.actions}>
        <StyledButton
          type="button"
          category="primary"
          onClick={handleSubmit}
          disabled={
            allowSubmitWithoutChanges ? isLoading : isPristine || isLoading
          }
        >
          {confirmButtonLabel}
        </StyledButton>
        <StyledButton
          type="button"
          category="secondary"
          onClick={handleCancel}
          disabled={isLoading}
        >
          {cancelButtonLabel}
        </StyledButton>
      </HvGrid>
      <ConfirmModal
        open={tryingToLeave}
        title={"Are you sure you want to leave this page?"}
        content={"Changes you made so far will not be saved"}
        onConfirm={handleConfirmTryingToLeave}
        onClose={handleCancelTryingToLeave}
      />
    </HvGrid>
  );
};
