import React, { useState, useCallback } from "react";
//Components
import { StyledFormInput } from "../../styled/inputs";
import { InputBaseComponentProps } from "@material-ui/core";

interface Props {
  name: string;
  onBlur?: (name: string, value: string) => void;
  initialValue?: string;
  invalid?: boolean;
  readOnly?: boolean;
  inputProps?: InputBaseComponentProps;
  integer?: boolean;
}

export const FormNumeric: React.FC<Props> = ({
  name,
  onBlur,
  initialValue,
  invalid,
  readOnly,
  inputProps,
  integer,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, val: string) => {
      if (integer && val && !val.match(new RegExp("^\\d+$"))) {
        return "";
      }

      if (isNaN(Number(val))) {
        return "";
      }

      setValue(val);
      return val;
    },
    [integer, setValue]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onBlur && onBlur(name, event?.target?.value),
    [name, onBlur]
  );

  return (
    <StyledFormInput
      disabled={readOnly}
      onBlur={handleBlur}
      onChange={handleChange}
      invalid={invalid}
      inputProps={inputProps}
      value={value}
    />
  );
};
