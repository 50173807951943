import React, { useCallback } from "react";
//Components
import { Dropdown } from "../../inputs";
//Types
import { ListValueProp } from "@hitachivantara/uikit-react-core";

interface Props {
  values: ListValueProp[];
  name: string;
  onChange: (name: string, value: string) => void;
  initialValue?: string;
  invalid?: boolean;
  readOnly?: boolean;
  className?: string;
}

export const FormDropdown: React.FC<Props> = ({
  values,
  name,
  onChange,
  initialValue,
  invalid,
  readOnly,
  className,
}) => {
  const handleChange = useCallback((value: string) => onChange(name, value), [
    name,
    onChange,
  ]);

  return (
    <Dropdown
      name={name}
      className={`${className} ${invalid ? "invalid" : ""}`}
      initialValue={initialValue}
      values={values}
      onChange={handleChange}
      readOnly={readOnly}
    />
  );
};
