import { TariffSchedule } from "../../../../store/sites/type";

export const formatTariffsToTable = (tariffs: TariffSchedule) => {
  if (!isTariffScheduleEmpty(tariffs)) return `Custom Tariffs`;
  return "—";
};

export const isTariffScheduleEmpty = (
  tariffs: TariffSchedule | null | string
) => {
  let isEmpty =
    !tariffs ||
    tariffs === "—" ||
    Object.values(tariffs).every(({ length }) => !Boolean(length));

  return isEmpty;
};
