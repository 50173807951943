import { combineReducers, Reducer } from "redux";

import { Actions, State } from "./type";
import { reducer as account } from "./account";
import { reducer as sites } from "./sites";
import { reducer as chargePointTypes } from "./chargePointTypes";
import { reducer as electricVehicleTypes } from "./electricVehicleTypes";
import { reducer as notifications } from "./notifications";
import { reducer as results } from "./results";
import { reducer as scenarios } from "./scenarios";
import { reducer as schedules } from "./schedules";

export const reducer: Reducer<State, Actions> = combineReducers({
  account,
  sites,
  chargePointTypes,
  electricVehicleTypes,
  notifications,
  results,
  scenarios,
  schedules,
});
