import React, { ReactNode } from "react";
import { useHistory } from "react-router";
//Components
import { StyledButton } from "../../../components/styled/inputs";
//Other
import { Scenario } from "../../../store/scenarios/type";
import { ResultStatus } from "../../../store/scenarios/type";
import { tableActionStyles } from "../ScenariosManage/assets/styles";
//Icons
import { Refresh } from "@hitachivantara/uikit-react-icons";

interface ShowResultsProps {
  original: Scenario;
}

interface ShowResultsButtonProps {
  scenario: Scenario;
  children: ReactNode;
}

export const ShowResults: React.FC<ShowResultsProps> = ({
  original: scenario,
}) => {
  const status = scenario.scenario_result_status;
  const getResultStatus = () => {
    switch (status) {
      case ResultStatus.Started:
      case ResultStatus.Waiting:
      case ResultStatus.Processing:
      case ResultStatus.Failed:
        for (var resultStatus in ResultStatus) {
          if (
            ResultStatus[resultStatus as keyof typeof ResultStatus] ===
            Object.values(ResultStatus).filter((value) => value === status)[0]
          ) {
            return (
              <>
                {`${resultStatus}${
                  status === ResultStatus.Failed ? `**` : `*`
                }`}
                {status !== ResultStatus.Failed && <RefreshButton />}
              </>
            );
          }
        }
        break;
      case ResultStatus.Completed:
        return (
          <ShowResultsButton scenario={scenario}>
            Show Results
          </ShowResultsButton>
        );
      default:
        return <>—</>;
    }
  };

  return <>{getResultStatus()}</>;
};

const ShowResultsButton: React.FC<ShowResultsButtonProps> = ({
  scenario,
  children,
}) => {
  const classes = tableActionStyles();
  const history = useHistory();

  const handleSubmit = () => {
    history.push(`/scenario-results/${scenario.scenario_result_id}`);
  };

  return (
    <StyledButton
      type="button"
      category="primary"
      onClick={handleSubmit}
      className={classes.action}
    >
      {children}
    </StyledButton>
  );
};

const RefreshButton: React.FC<{}> = () => {
  const classes = tableActionStyles();
  return (
    <StyledButton
      type="button"
      category="semantic"
      onClick={() => window.location.reload()}
      className={classes.refresh}
    >
      <Refresh />
    </StyledButton>
  );
};
