import { TableColumn } from "@hitachivantara/uikit-react-core";
import { FormField } from "../../../../components/forms/assets/types";
import { EnergyCostModelFormComponent } from "../../CustomFormComponents";
import { CalculateResults, ShowResults } from "../../CustomTableComponents";
import {
  scenarioFieldNamings,
  cpTypeEnumerationFieldNamings,
  evTypeEnumerationFieldNamings,
} from "./namings";
import {
  loadChargePointTypesList,
  loadElectricVehicleTypesList,
  loadSchedulesList,
  loadSitesList,
} from "./dropdowns";
import { getEnergyDistributionModel } from "./formattings";
import {
  validateChargePointSelection,
  validateElectricVehicleForScheduleSelection,
} from "./validations";
import {
  ScenarioNameTooltip,
  ScenarioSiteTooltip,
  ScenarioChargePointTypesTooltip,
  ScenarioElectricVehiclesTypesTooltip,
  ScenarioEnergyCostModelTooltip,
} from "./Tooltips";

export const tableColumns: TableColumn[] = [
  {
    headerText: scenarioFieldNamings.name.label,
    accessor: scenarioFieldNamings.name.accessor,
    cellType: "alpha-numeric",
  },
  {
    headerText: scenarioFieldNamings.site_name.description,
    accessor: scenarioFieldNamings.site_name.accessor,
    cellType: "alpha-numeric",
  },
  {
    headerText: scenarioFieldNamings.energy_cost_model.description,
    accessor: scenarioFieldNamings.energy_cost_model.accessor,
    cellType: "alpha-numeric",
    format: (value) =>
      getEnergyDistributionModel(
        value.original[scenarioFieldNamings.energy_cost_model.accessor]
      ),
  },
  {
    headerText: scenarioFieldNamings.calculate_results!.description,
    accessor: scenarioFieldNamings.calculate_results!.accessor,
    cellType: "alpha-numeric",
    Cell: CalculateResults,
  },
  {
    headerText: scenarioFieldNamings.scenario_result_id!.description,
    accessor: scenarioFieldNamings.scenario_result_id!.accessor,
    cellType: "alpha-numeric",
    Cell: ShowResults,
  },
];

export const detailFields: FormField[] = [
  {
    label: scenarioFieldNamings.name.description,
    accessor: scenarioFieldNamings.name.accessor,
    required: true,
    maxCharacters: 50,
    tooltipContent: ScenarioNameTooltip,
  },
  {
    label: scenarioFieldNamings.site.description,
    accessor: scenarioFieldNamings.site.accessor,
    required: true,
    inputType: "list",
    loadValues: loadSitesList,
    tooltipContent: ScenarioSiteTooltip,
  },
  {
    label: scenarioFieldNamings.energy_cost_model.description,
    accessor: scenarioFieldNamings.energy_cost_model.accessor,
    required: true,
    inputType: "customComponent",
    CustomComponent: EnergyCostModelFormComponent,
    tooltipContent: ScenarioEnergyCostModelTooltip,
  },
  {
    label: scenarioFieldNamings.cp_type_enumeration.description,
    accessor: scenarioFieldNamings.cp_type_enumeration.accessor,
    inputType: "multiline",
    required: true,
    maxLines: 5,
    tooltipContent: ScenarioChargePointTypesTooltip,
    customValidation: (value) => validateChargePointSelection(value),
    multiLineFields: [
      {
        label: cpTypeEnumerationFieldNamings.cp_type.description,
        accessor: cpTypeEnumerationFieldNamings.cp_type.accessor,
        required: true,
        loadValues: loadChargePointTypesList,
        inputType: "list",
      },
      {
        label: cpTypeEnumerationFieldNamings.quantity.description,
        accessor: cpTypeEnumerationFieldNamings.quantity.accessor,
        required: true,
        inputType: "number",
        dataType: "integer",
        maxCharacters: 3,
      },
    ],
  },
  {
    label: scenarioFieldNamings.ev_type_enumeration.description,
    accessor: scenarioFieldNamings.ev_type_enumeration.accessor,
    inputType: "multiline",
    required: true,
    maxLines: 25,
    tooltipContent: ScenarioElectricVehiclesTypesTooltip,
    customValidation: (value) =>
      validateElectricVehicleForScheduleSelection(value),
    multiLineFields: [
      {
        label: evTypeEnumerationFieldNamings.ev_type.description,
        accessor: evTypeEnumerationFieldNamings.ev_type.accessor,
        inputType: "list",
        required: true,
        loadValues: loadElectricVehicleTypesList,
      },
      {
        label: evTypeEnumerationFieldNamings.schedule.description,
        accessor: evTypeEnumerationFieldNamings.schedule.accessor,
        inputType: "list",
        required: true,
        loadValues: loadSchedulesList,
      },
      {
        label: evTypeEnumerationFieldNamings.quantity.description,
        accessor: evTypeEnumerationFieldNamings.quantity.accessor,
        required: true,
        inputType: "number",
        dataType: "integer",
        maxCharacters: 3,
      },
    ],
  },
];
