import { makeStyles } from "@material-ui/core";

export const sideMenuStyles = makeStyles(() => ({
  menuItens: {
     width: "100%",
     paddingLeft: "39px",
     backgroundColor: "#ffffff",
 },
  alignMenuBar: {
    maxWidth: "1920px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  fixedSideBar: {
    '@media print': {
      display: 'none',
    }, 
    border: "0.0625rem solid #e0e0e0",
    height: "110px",
    width: "100%",
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    backgroundColor: "#ffffff",
    overflowX: "hidden",
    overflowY: "hidden",
    padding: "20px 0 0 0",
  },
  logo: {
    width: "61px",
    float: "left",
    margin: "0px 15px",
  },
}));

export const layoutStyles = makeStyles(() => ({
  
  layout: {
    '@media print': {
      marginLeft: "0px",
      marginRight: "20px",
      marginTop: "10px",
    }, 
    '@media screen': {
      marginTop: "140px",
    }, 
  },
}));