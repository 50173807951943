import { Results } from "../../../../store/results/type";
import { costsGbpNamings } from "./namings";
import { getCurrencyString } from "../../../../utils/currency";

export const layout = {
  xaxis: {
    tickmode: "array",
    tickvals: [0, 48, 96, 144, 192, 240, 288],
    ticktext: [
      "Mon 00:00",
      "Tue 00:00",
      "Wed 00:00",
      "Thu 00:00",
      "Fri 00:00",
      "Sat 00:00",
      "Sun 00:00",
    ],
  },
};

export const getChargingCostsData = (detail: Results) => {
  return [
    {
      asset: costsGbpNamings.site.description,
      capex_cost_gbp: detail.asset_costs.capex_cost_gbp.site
        ? getCurrencyString(detail.asset_costs.capex_cost_gbp.site)
        : "N/A",
      opex_cost_gbp_per_month: detail.asset_costs.opex_cost_gbp_per_month.site
        ? getCurrencyString(detail.asset_costs.opex_cost_gbp_per_month.site)
        : "N/A",
    },
    {
      asset: costsGbpNamings.electric_vehicles.description,
      capex_cost_gbp: detail.asset_costs.capex_cost_gbp.electric_vehicles
        ? getCurrencyString(detail.asset_costs.capex_cost_gbp.electric_vehicles)
        : "N/A",
      opex_cost_gbp_per_month: detail.asset_costs.opex_cost_gbp_per_month
        .electric_vehicles
        ? getCurrencyString(
            detail.asset_costs.opex_cost_gbp_per_month.electric_vehicles
          )
        : "N/A",
    },
    {
      asset: costsGbpNamings.chargepoints.description,
      capex_cost_gbp: detail.asset_costs.capex_cost_gbp.chargepoints
        ? getCurrencyString(detail.asset_costs.capex_cost_gbp.chargepoints)
        : "N/A",
      opex_cost_gbp_per_month: detail.asset_costs.opex_cost_gbp_per_month
        .chargepoints
        ? getCurrencyString(
            detail.asset_costs.opex_cost_gbp_per_month.chargepoints
          )
        : "N/A",
    },
    {
      asset: costsGbpNamings.total.description,
      capex_cost_gbp: detail.asset_costs.capex_cost_gbp.total
        ? getCurrencyString(detail.asset_costs.capex_cost_gbp.total)
        : "N/A",
      opex_cost_gbp_per_month: detail.asset_costs.opex_cost_gbp_per_month.total
        ? getCurrencyString(detail.asset_costs.opex_cost_gbp_per_month.total)
        : "N/A",
    },
  ];
};

export const getSiteAscKva = (detail: Results) => {
  return {
    type: "scatter",
    x: Object.values(detail.ptu_mapping),
    y: Array(Object.keys(detail.background_demand_max_kva).length).fill(
      detail.scenario_summary.site.asc_kva
    ),
    mode: "lines",
    name: "Existing ASC (kVA)",
    line: {
      dash: "dot",
      color: "rgb(219, 64, 82)",
      width: 3,
    },
  };
};

export const getSupplyCapacitySmart = (detail: Results) => {
  return {
    type: "scatter",
    x: Object.values(detail.ptu_mapping),
    y: Array(Object.keys(detail.background_demand_max_kva).length).fill(
      detail.supply_capacity_smart_kva
    ),
    mode: "lines",
    name: "Supply capacity required (kVA)",
    line: {
      dash: "dashdot",
      color: "rgb(38, 100, 0)",
      width: 3,
    },
  };
};

export const getSupplyCapacityUnmanaged = (detail: Results) => {
  return {
    type: "scatter",
    x: Object.values(detail.ptu_mapping),
    y: Array(Object.keys(detail.background_demand_max_kva).length).fill(
      detail.supply_capacity_unmanaged_kva
    ),
    mode: "lines",
    name: "Supply capacity required (kVA)",
    line: {
      dash: "dashdot",
      color: "rgb(38, 100, 0)",
      width: 3,
    },
  };
};

export const getTotalLoadSmart = (detail: Results) => {
  return {
    type: "scatter",
    x: Object.values(detail.ptu_mapping),
    y: detail.total_load_smart_kva
      ? Object.values(detail.total_load_smart_kva)
      : [],
    mode: "lines",
    fill: "tozeroy",
    name: "Total site load (kVA)",
    line: {
      color: "rgb(55, 128, 191)",
      width: 3,
    },
  };
};

export const getTotalLoadUnmanaged = (detail: Results) => {
  return {
    type: "scatter",
    x: Object.values(detail.ptu_mapping),
    y: detail.total_load_unmanaged_kva
      ? Object.values(detail.total_load_unmanaged_kva)
      : [],
    mode: "lines",
    fill: "tozeroy",
    name: "Total site load (kVA)",
    line: {
      color: "rgb(55, 128, 191)",
      width: 3,
    },
  };
};

export const getVehicleDemandSmart = (detail: Results) => {
  return {
    type: "scatter",
    x: Object.values(detail.ptu_mapping),
    y: detail.vehicle_demand_smart_kva
      ? Object.values(detail.vehicle_demand_smart_kva)
      : [],
    mode: "lines",
    name: "EV Charging load (kVA)",
    line: {
      color: "rgb(75, 0, 130)",
      width: 3,
    },
  };
};

export const getVehicleDemandUnmanaged = (detail: Results) => {
  return {
    type: "scatter",
    x: Object.values(detail.ptu_mapping),
    y: detail.vehicle_demand_unmanaged_kva
      ? Object.values(detail.vehicle_demand_unmanaged_kva)
      : [],
    mode: "lines",
    name: "EV Charging load (kVA)",
    line: {
      color: "rgb(75, 0, 130)",
      width: 3,
    },
  };
};

export const getBackgroundDemand = (detail: Results) => {
  return {
    type: "scatter",
    x: Object.values(detail.ptu_mapping),
    y: detail.background_demand_max_kva
      ? Object.values(detail.background_demand_max_kva)
      : [],
    mode: "lines",
    fill: "tozeroy",
    name: "Background Demand (kVA)",
    line: {
      color: "rgb(55, 128, 191)",
      width: 2,
    },
  };
};
