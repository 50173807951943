import { ActionsUnion, DispatchAction } from "../type";
import { createAction } from "../action";

export enum NOTIFICATIONS_MESSAGES {
    error = "We could not process your request. Please try again",
    update = "The record was successfully updated",
    insert = "The record was successfully included",
    delete = "The record was successfully deleted"
}

export enum ActionTypes {
    SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE",
    SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE",
    UNSET_NOTIFICATION = "UNSET_NOTIFICATION"
}

export const Actions = {
    setErrorMessage: (message: string) => createAction(ActionTypes.SET_ERROR_MESSAGE, message),
    setSuccessMessage: (message: string) => createAction(ActionTypes.SET_SUCCESS_MESSAGE, message),
    unsetNotification: () => createAction(ActionTypes.UNSET_NOTIFICATION)
};

export type Actions = ActionsUnion<typeof Actions>;

export function setError(message?: string): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setErrorMessage(!message || message === "" ? NOTIFICATIONS_MESSAGES.error : message));
    }
}

export function setSuccess(message: string): DispatchAction {
    return async dispatch => {
        dispatch(Actions.setSuccessMessage(message));
    }
}

export function unsetNotification(): DispatchAction {
    return async dispatch => {
        dispatch(Actions.unsetNotification());
    }
}