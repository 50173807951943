import * as React from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import { WidgetContainer } from "../../../../components/layout/WidgetContainer";

interface Props {}

export const ScenariosInfo: React.FC<Props> = () => {
  return (
    <WidgetContainer>
      <HvGrid container>
        <HvGrid item xs={12}>
          <HvTypography variant="xsTitle">
           Step 2 – Build your scenario
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            Scenarios allow you to select a site, combine Electric Vehicles, Charge Points and
            Schedules and calculate electricity load.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            To create a new scenario, click on 'Scenarios' above, select 'Add'
            and follow the steps.
          </HvTypography>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            To compare the impact of different vehicle/charger combinations, 
            or to model multiple depots, you can create as many scenarios as required.
          </HvTypography>
        </HvGrid>
        </HvGrid>
        <HvGrid item>
          <HvTypography variant="normalText">
           For guidance on scenario creation, visit our <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-guide" target="__blank">help pages</a>.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            When you’ve created your assets, move on to <strong>Step 3 – Viewing the results</strong>.
          </HvTypography>
          <HvTypography variant="normalText">
            Press the 'Calculate' button in the <a href="scenarios/manage" target="__blank">scenarios list</a> to 
            generate your results - once it's ready you will be able to select the results button.
          </HvTypography>
          <HvTypography variant="normalText">
            Normally your results will be visible straight away, but this may take slightly longer for larger sites.
          </HvTypography>
          <HvTypography variant="normalText">
            You can update or delete a scenario from the menu to the right of each item. If you make any changes to the scenario, or the underlying assets, press 'recalculate' to refresh your results.
          </HvTypography>
        </HvGrid>
      </HvGrid>
    </WidgetContainer>
  );
};
