import React from "react";
import { StoreProvider } from "./store";

//Other
import { AuthenticationGate } from "./components/authenticationGate/AuthenticationGate";

const App: React.FC = () => {
  return (
    <div className="App">
      <StoreProvider>
        <AuthenticationGate />
      </StoreProvider>
    </div>
  );
};

export default App;
