import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { DataGrid } from "../../../components/datagrid/DataGrid";
import { ConfirmModal } from "../../../components/modals/ConfirmModal";
//Redux
import {
  clearDetailSchedule,
  deleteSchedule,
  fetchSchedules,
  getSchedules,
  tryToDelete,
  unsetScheduleError,
} from "../../../store/schedules";
//Other
import { tableColumns } from "./assets/fields";
import { Schedule } from "../../../store/schedules/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { SCHEDULE_TITLE } from "./assets/namings";

interface Props {}

export const SchedulesTable: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { navigateToAdd, navigateToDetail } = useNavigation();
  useErrorModal(getSchedules, unsetScheduleError);

  const { isRequesting, data, deleteId } = useSelector(getSchedules);

  const handleDetail = useCallback(
    (event: React.FormEvent<HTMLDivElement>, row: object) => {
      navigateToDetail(row as Schedule);
    },
    [navigateToDetail]
  );

  const handleDelete = useCallback(
    (event: React.FormEvent<HTMLDivElement>, row: object) => {
      const { id } = row as Schedule;
      dispatch(tryToDelete(id));
    },
    [dispatch]
  );

  const secondaryActions = useMemo(
    () => [
      {
        label: "View/Edit",
        action: handleDetail,
      },
      {
        label: "Delete",
        action: handleDelete,
      },
    ],
    [handleDetail, handleDelete]
  );

  const handleDeleteCancel = useCallback(() => dispatch(tryToDelete(null)), [
    dispatch,
  ]);

  const handleDeleteConfirm = useCallback(() => {
    if (!deleteId) {
      return;
    }

    dispatch(deleteSchedule(deleteId));
  }, [deleteId, dispatch]);

  useEffect(() => {
    dispatch(fetchSchedules());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearDetailSchedule());
  }, [dispatch]);
  
  return (
    <WidgetContainer>
      <DataGrid
        columns={tableColumns}
        data={data}
        noDataLabel={`There are no ${SCHEDULE_TITLE}s`}
        secondaryActions={secondaryActions}
        isLoading={isRequesting}
        onAdd={navigateToAdd}
      />
      <ConfirmModal
        open={!!deleteId}
        title={`Delete ${SCHEDULE_TITLE}`}
        content={`Are you sure you want to delete this ${SCHEDULE_TITLE}?`}
        onConfirm={handleDeleteConfirm}
        onClose={handleDeleteCancel}
      />
    </WidgetContainer>
  );
};
