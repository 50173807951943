import React from "react";

interface Props {}

export const ChargePointTypeDescription: React.FC<Props> = () => (
  <>
 Use this page to specify the charge points that you plan to use at your sites. 
 If you're unsure about which charge points you will use, you can find specifications 
 of common models at 
 {" "}
    <a href="https://www.zap-map.com/charge-points/charging-work/"
      target="__blank"
    > zap-map</a> or see our <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-example"
      target="__blank"
    >worked example</a>. 
    Up to five charge point types can be specified, allowing you to compare 
    the impact of different speeds or combinations.  
    <br/>
    Fields marked in{" "}<strong>bold</strong> are required.
  </>
);

export const ChargePointTypeNameTooltip: string =
  "Choose a unique name to identify this charge point.";

export const ChargePointTypeEvseMaxEventsTooltip: string =
  "Select how many simultaneous charging sessions the charge point can provide. This is usually the same as the number of physical connectors on the charge point.";

export const ChargePointTypeChargeSpeedTooltip: string =
  "Enter the maximum charge rate for the charge point, this should be stated in the charge point specifications. Note that if you only have a single phase connection, or you connect your charge points to a single phase, your charge point will be limited to 7.4kW and you should enter this number here.";

export const ChargePointElectricityDistributionModelTooltip: string =
  "If the charge point has more than one socket, each socket may be wired individually (and all can provide the full charge rate simultaneously). Alternatively, the charge rate may be split between the active connectors. Choose the mode of operation here. If you don't know, we'll assume that all sockets can charge at the maximum capacity.";

export const ChargePointTypeCapexCostTooltip: string =
  "If you know how much each charge point will cost to purchase and install, enter the details here.";

export const ChargePointTypeOpexCostTooltip: string =
  "If you know how much recurring costs (such as charge point rental, maintenance, etc.) will be per charge point enter it here.";
