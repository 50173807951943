import { Actions, CRUDState } from "../type";

import { ActionTypes } from "./action";
import { Site } from "./type";

export type SiteState = CRUDState<Site>;

const initialState: Readonly<SiteState> = {
  data: [],
  isRequesting: false,
  detail: null,
  deleteId: null,
  error: null,
};

export function reducer(
  state: SiteState = initialState,
  action: Actions
): SiteState {
  switch (action.type) {
    case ActionTypes.CLEAR_DETAIL_SITE: {
      return {
        ...state,
        detail: null,
      }
    }
    case ActionTypes.CLONE_SITE_PERIODS: {
      const detail = action.payload;
      if (state.detail) {
        detail.id = state.detail.id;
      };
      return {
        ...state,
        isRequesting: false,
        detail,
      };
    }
    case ActionTypes.FETCH_SITES_REQUEST:
    case ActionTypes.FETCH_SITE_REQUEST:
    case ActionTypes.DELETE_SITE_REQUEST:
    case ActionTypes.UPDATE_SITE_REQUEST:
    case ActionTypes.INSERT_SITE_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        error: null,
        deleteId: null,
      };
    }
    case ActionTypes.FETCH_SITES_FAILURE:
    case ActionTypes.FETCH_SITE_FAILURE:
    case ActionTypes.DELETE_SITE_FAILURE:
    case ActionTypes.UPDATE_SITE_FAILURE:
    case ActionTypes.INSERT_SITE_FAILURE: {
      const error = action.payload;
      return {
        ...state,
        isRequesting: false,
        error,
      };
    }
    case ActionTypes.FETCH_SITES_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        isRequesting: false,
        data: data.sort((a: Site, b: Site) => a.id - b.id),
      };
    }
    case ActionTypes.FETCH_SITE_SUCCESS:
    case ActionTypes.UPDATE_SITE_SUCCESS: {
      const detail = action.payload;
      return {
        ...state,
        isRequesting: false,
        detail,
      };
    }
    case ActionTypes.DELETE_SITE_SUCCESS: {
      const deletedId = action.payload;
      const newData = state.data.filter(({ id }) => id !== deletedId);
      return {
        ...state,
        isRequesting: false,
        data: newData,
        deleteId: null,
      };
    }
    case ActionTypes.INSERT_SITE_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
      };
    }
    case ActionTypes.TRY_TO_DELETE_SITE: {
      const deleteId = action.payload;
      return {
        ...state,
        deleteId,
      };
    }
    case ActionTypes.UNSET_SITE_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
}
