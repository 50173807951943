import { Actions } from "../type";

import { ActionTypes } from "./action";

export type NotificationsState = {
    type: "success" | "error" | null;
    message: string | null;
};

const initialState: Readonly<NotificationsState> = {
    type: null,
    message: null
};

export function reducer(
    state: NotificationsState = initialState,
    action: Actions
): NotificationsState {
    switch (action.type) {
        case ActionTypes.SET_ERROR_MESSAGE: {
            const message = action.payload;
            return {
                ...state,
                type: "error",
                message
            };
        }
        case ActionTypes.SET_SUCCESS_MESSAGE: {
            const message = action.payload;
            return {
                ...state,
                type: "success",
                message
            };
        }
        case ActionTypes.UNSET_NOTIFICATION: {
            return {
                ...state,
                type: null,
                message: null
            };
        }
        default:
            return state;
    }
}