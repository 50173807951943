import React, { useEffect, useState } from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import { FormMultiline } from "../../../../components/forms/FormMultiline";

//Types
import {
  FormComponent,
  FormErrors,
  FormValues,
} from "../../../../components/forms/assets/types";
//Styles
import { formStyles } from "../../../../components/forms/assets/styles";

export const SiteTariffSchedule: React.FC<FormComponent> = ({
  name,
  onChange,
  onClone,
  initialValue,
  fields,
  errors,
}) => {
  const classes = formStyles();
  const [values, setValues] = useState(initialValue);

  const handleChange = (key: string, value: any) => {
    let newValues: FormValues = {};
    newValues = {
      ...values,
      [key]: value,
    };

    setValues(newValues);
  };

  useEffect(() => {
    onChange(name, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <HvGrid container className={classes.fieldsContainer}>
      {fields &&
        fields.map(
          (
            {
              label,
              accessor,
              required,
              maxLines,
              multiLineFields,
              getDefaultValue,
              cloneOption,
              tooltipCloneContent,
            },
            index
          ) => (
            <React.Fragment key={`${index}${accessor ?? ""}`}>
              {multiLineFields && (
                <React.Fragment
                  key={`${index}${
                    (initialValue && initialValue[accessor]) ?? ""
                  }`}
                >
                  <HvGrid item xs={3} className={classes.fieldLabelTop}>
                    <HvTypography
                      variant={required ? "highlightText" : "normalText"}
                    >
                      {label}
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={9}>
                    <FormMultiline
                      fields={multiLineFields}
                      name={accessor}
                      onChange={handleChange}
                      cloneOption={cloneOption}
                      tooltipCloneContent={tooltipCloneContent}
                      onClone={onClone}
                      errors={
                        errors &&
                        ((errors as { [key: string]: any })[
                          accessor
                        ] as FormErrors[])
                      }
                      getDefaultValue={getDefaultValue}
                      initialValues={initialValue && initialValue[accessor]}
                      maxLines={maxLines}
                    />
                  </HvGrid>
                </React.Fragment>
              )}
            </React.Fragment>
          )
        )}
    </HvGrid>
  );
};
