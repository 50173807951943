import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { HvGrid, HvTable, HvTypography } from "@hitachivantara/uikit-react-core";
import { Loading } from "../../../components/loading/Loading";
import ErrorBoundary from "../../../components/errorBoundary/ErrorBoundary";
//Assets
import { MainComponentProps } from "../../Layout/types/types";
import {
  electricVehiclesTableColumns,
  chargepointsTableColumns,
} from "./assets/fields";
import {
  ElectricVehiclesSummary,
  ChargePointsSummary,
  SiteSummary,
} from "../../../store/results/type";
//Redux
import { getResults, unsetResultsError } from "../../../store/results";
//Hooks
import { useErrorModal } from "../../../hooks/useErrorModal";

export const ScenariosResultsScenarioDetails: React.FC<MainComponentProps> = () => {
  const { isRequesting, detail } = useSelector(getResults);

  useErrorModal(getResults, unsetResultsError);

  const [electricVehiclesData, setElectricVehiclesData] = useState<
    ElectricVehiclesSummary[]
  >([]);
  const [chargePointsData, setChargePointsData] = useState<
    ChargePointsSummary[]
  >([]);
  const [sitesData, setSitesData] = useState<SiteSummary>();

  useEffect(() => {
    if (detail && detail.scenario_summary) {
      setSitesData(detail.scenario_summary.site);
      setElectricVehiclesData(detail.scenario_summary.electric_vehicles);
      setChargePointsData(detail.scenario_summary.chargepoints);
    }
  }, [detail]);

  return (
    <WidgetContainer>
      <HvGrid container>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            These demand profiles were calculated based upon the following
            details:
          </HvTypography>
        </HvGrid>
        {sitesData && (
          <>
            <HvGrid item xs={12}>
              <HvTypography variant="xsTitle">Site</HvTypography>
            </HvGrid>
            <HvGrid item xs={12}>
              <HvTypography variant="normalText">
                Name: <strong>{sitesData.name}</strong>
              </HvTypography>
              <HvTypography variant="normalText">
                Location: <strong>{sitesData.location}</strong>
              </HvTypography>
              <HvTypography variant="normalText">
                Available Supply Capacity:{" "}
                <strong>{sitesData.asc_kva}kVA</strong>
              </HvTypography>
            </HvGrid>
          </>
        )}
        <HvGrid item xs={12}>
          <HvTypography variant="xsTitle">EV & Schedules</HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <Loading isLoading={isRequesting}>
            <ErrorBoundary>
              <HvTable
                columns={electricVehiclesTableColumns}
                data={electricVehiclesData}
                sortable={false}
                showPagination={false}
              />
            </ErrorBoundary>
          </Loading>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="xsTitle">Charge Points</HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <Loading isLoading={isRequesting}>
            <ErrorBoundary>
              <HvTable
                columns={chargepointsTableColumns}
                data={chargePointsData}
                sortable={false}
                showPagination={false}
              />
            </ErrorBoundary>
          </Loading>
        </HvGrid>
      </HvGrid>
    </WidgetContainer>
  );
};
