import * as React from "react";
//Components
import { HvGrid } from "@hitachivantara/uikit-react-core";
import { Paper } from "@material-ui/core";
//Styles
import { widgetContainerStyles } from "./styles";

interface Props {}

export const WidgetContainer: React.FC<Props> = ({ children }) => {
  const classes = widgetContainerStyles();

  return (
    <HvGrid container className={classes.root}>
      <HvGrid item xs={12}>
        <Paper className={classes.paper}>{children}</Paper>
      </HvGrid>
    </HvGrid>
  );
};

export const WidgetContainerHome: React.FC<Props> = ({ children }) => {
  const classes = widgetContainerStyles();

  return (
    <HvGrid>
        {children}
    </HvGrid>
  );
};