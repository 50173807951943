import * as React from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import { WidgetContainer, WidgetContainerHome } from "../../components/layout/WidgetContainer";
//Hooks
import useMsal from "../../components/msal/assets/useMsal";
//Styles
import { homeStyles } from "./styles";
//Images
import HomeSteps from "../../assets/images/home_steps.jpeg";
import { LeftAlign } from "@hitachivantara/uikit-react-icons";
//Images
import LogoUKPN from "../../assets/images/ukpn-logo-color-transparent.png";

interface Props {}

export const Home: React.FC<Props> = () => {
  const { name } = useMsal();
  const classes = homeStyles();

  return (
    <WidgetContainerHome>
      <WidgetContainer>
        <HvGrid container>
          <HvGrid item className={classes.welcome}>
            <HvTypography variant="mTitle">Welcome&nbsp;</HvTypography>
            {name && (
              <HvTypography variant="mTitle" className={classes.username}>
                {name}&nbsp;
              </HvTypography>
            )}
            <HvTypography variant="mTitle">
              to the Site Planning Tool
            </HvTypography>
          </HvGrid>
          <HvGrid item>
            <HvTypography variant="normalText">
              This tool has been developed as part of the Optimise Prime Network 
              Innovation Competition project to help fleets plan their investment 
              in depot electrification. The Site Planning Tool will analyse 
              charging requirements and calculate the requirement for network 
              capacity. Where an upgraded connection is required, you can use this
              information to support an application to your distribution network 
              operator. The tool will demonstrate what may be possible with smart 
              control of charging.
            </HvTypography>
          </HvGrid>
          <HvGrid item>
            <HvTypography variant="mTitle">Before you start</HvTypography>
            <HvTypography variant="normalText">
            You will need some basic data about your site and the vehicles that you plan to use in order to use the tool. 
            Details of this can be found in <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-guide" target="__blank">our guide</a>, 
            where you’ll also find a <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-example" target="__blank">worked example</a> using sample data. 
            Throughout the tool you can find additional help by selecting the ‘?’ icons.
            </HvTypography>
          </HvGrid>
        </HvGrid>
      </WidgetContainer>
      <WidgetContainer>
        <HvGrid container>
          <HvGrid item>
            <HvTypography variant="mTitle">Using the tool</HvTypography>
            <HvTypography variant="normalText">
            There are three main steps to using the tool: creating assets, building scenarios and viewing the results.
            </HvTypography>
            <img src={HomeSteps} alt="Steps" width="70%"  style={{marginLeft:'auto', marginRight:'auto', marginTop:10, display:'block'}}/>
          </HvGrid>
          <HvGrid item>
            <HvTypography variant="xsTitle">Step 1 - Specify your assets</HvTypography>
            <HvTypography variant="normalText">
            The first step is to specify your site and the assets (
              <a href="catalogue/electric-vehicle-types" target="__blank">Electric Vehicles </a>, <a href="catalogue/charge-point-types" target="__blank">Charge Points</a> and <a href="catalogue/schedules" target="__blank">Schedules</a>
            ) that you plan to use on your site - to do this, select <a href="catalogue" target="__blank">'Catalogue'</a> from
            the menu above. There are also optional inputs to help understand the cost of electrification. 
            </HvTypography>
          </HvGrid>
          <HvGrid item>
            <HvTypography variant="xsTitle">Step 2 - Build your scenario</HvTypography>
            <HvTypography variant="normalText">
            Once your assets have been specified you need to combine them to create a scenario. 
            At this step you select a site, assign vehicles to schedules and select the number of charge points the site can accommodate. 
            To do this, choose <a href="scenarios" target="__blank">'Scenarios'</a> from the menu above.
            </HvTypography>
          </HvGrid>
          <HvGrid item>
            <HvTypography variant="xsTitle">Step 3 - Viewing the results</HvTypography>
            <HvTypography variant="normalText">
            Once the <a href="scenarios/manage" target="__blank">scenario</a> has been built, 
            select 'calculate' to generate load profiles based on your scenario, you will also be presented with calculated load profiles and costs. 
            You can revisit the results later from the Scenario menu. 
            </HvTypography>
          </HvGrid>
          <HvGrid item>
            <HvTypography variant="xsTitle">Step 4 - Try new scenarios</HvTypography>
            <HvTypography variant="normalText">
            Optionally, you can change your scenario, or make multiple scenarios to compare the impact of different combinations of assets, or plan multiple sites. 
            If you change the underlying assets, make sure that you recalculate the scenario if you want this to be reflected in the results.
            </HvTypography>
          </HvGrid>
          <HvGrid item>
            <HvTypography variant="xsTitle">Next steps</HvTypography>
            <HvTypography variant="normalText">
            For more details on applying for a new connection, a connection upgrade or to install 
            electric vehicle charge points visit the <a href="https://www.ukpowernetworks.co.uk/electricity/electric-vehicle-charging-point" target="__blank">UK Power Networks website</a>. 
            If you’re not in the UK Power Networks Region you can find your local network operator 
            through the <a href="https://www.energynetworks.org/operating-the-networks/whos-my-network-operator" target="__blank">Energy Networks Association website</a>.
            <br/><br/>
            Please note that connection costs and timeframes can vary significantly dependent on location 
            and local network capacity.
            </HvTypography>
          </HvGrid>
          </HvGrid>
      </WidgetContainer>
      <WidgetContainer>
        <HvGrid container style={{flexFlow: "row"}}>
          <img style={{width: "15%", height: "15%", marginTop: "15px", marginLeft: "10px", marginRight: "10px"}} src={LogoUKPN} alt="UKPN" />
          <HvGrid item style={{marginTop: "5px"}}>
            <HvGrid item>
              <HvTypography variant="mTitle">About Optimise Prime</HvTypography>
              <HvTypography variant="normalText">
              Optimise Prime is an industry-led, Network Innovation Competition (NIC) funded project investigating the effects of commercial electric vehicles on the electricity distribution network.<br/>
              The programme unites UK Power Networks with a cross-sector team of fleet operators and technology providers in order to help Great Britains's networks plan for, and promote, the electric vehicle revolution.<br/> 
              To find out more about Optimise Prime visit the <a href="https://innovation.ukpowernetworks.co.uk/projects/optimise-prime/" target="__blank">project website</a>.<br/>
              To find out more about how UK Power Networks is preparing for the electric vehicle transition visit <a href="https://innovation.ukpowernetworks.co.uk/facilitating-net-zero/electric-vehicles/" target="__blank">UK Power Networks Innovation</a>.    
              </HvTypography>
            </HvGrid>
            <br />
            <HvGrid item>
              <HvTypography variant="mTitle">Feedback on the tool</HvTypography>
              <HvTypography variant="normalText">
              We're keen to hear if you found the tool useful or if you have suggestions on how it could be improved. 
              To get in contact with the team please email {" "} <a href="mailto: SitePlanningTool@ukpowernetworks.co.uk" target="__blank">SitePlanningTool@ukpowernetworks.co.uk</a>.
              Use of the tool should be in accordance with our <a href="https://www.ukpowernetworks.co.uk/terms-and-conditions" target="__blank">terms and conditions</a>.
              </HvTypography>
            </HvGrid>
          </HvGrid>
        </HvGrid>
        <br />
      </WidgetContainer>
    </WidgetContainerHome>
  );
};
