import React, { memo, useCallback } from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import { DatePicker, TimePicker, DropdownMulti, Numeric } from "../inputs";
import { StyledButton } from "../styled/inputs";
import { FormDropdown, FormDropdownAsync } from "./fields";
//Others
import { StyledFormMultiline } from "../styled/inputs";
import { FormErrors, FormField, FormValues } from "./assets/types";
import { weekDaysListValues } from "../../pages/Catalogue/Schedules/assets/dropdowns";

interface Props {
  id: number;
  fields: FormField[];
  onRemove: (id: number) => void;
  onChange: (id: number, name: string, value: any) => void;
  errors?: FormErrors;
  initialValues?: FormValues;
  values?: FormValues;
}

export const FormMultilineLine: React.FC<Props> = memo(
  ({ id, fields, onRemove, onChange, errors, initialValues, values = [] }) => {
    const classes = StyledFormMultiline();

    const handleChange = useCallback(
      (field, value) => {
        onChange(id, field, value);
      },
      [id, onChange]
    );
    const handleRemove = useCallback(() => onRemove(id), [id, onRemove]);
    return (
      <HvGrid container>
        <HvGrid item xs={12}>
          <HvGrid container>
            {fields.map(
              (
                {
                  accessor,
                  affectedFields,
                  CustomComponent,
                  dateRange,
                  dataType,
                  inputType,
                  label,
                  listValues,
                  loadValues,
                  maxCharacters,
                  readOnly,
                },
                index
              ) => (
                <HvGrid
                  item
                  lg={2}
                  md={3}
                  xs={3}
                  key={`${index}${
                    initialValues && initialValues[accessor]
                      ? initialValues[accessor]
                      : ""
                  }`}
                >
                  {inputType === "datePicker" ? (
                    <DatePicker
                      onBlur={(value) => handleChange(accessor, value)}
                      minDate={dateRange?.min?.toDateString()}
                      maxDate={dateRange?.max?.toDateString()}
                      initialValue={initialValues && initialValues[accessor]}
                    />
                  ) : inputType === "timePicker" ? (
                    <TimePicker
                      onBlur={(value) => handleChange(accessor, value)}
                      initialValue={initialValues && initialValues[accessor]}
                    />
                  ) : inputType === "multilist" ? (
                    <DropdownMulti
                      values={weekDaysListValues}
                      onBlur={(value) => handleChange(accessor, value)}
                      initialValue={initialValues && initialValues[accessor]}
                    />
                  ) : inputType === "list" && loadValues ? (
                    <FormDropdownAsync
                      name={accessor}
                      initialValue={initialValues && initialValues[accessor]}
                      onChange={(key, value) => handleChange(key, value)}
                      loadValues={loadValues}
                      affectedFields={affectedFields}
                      className="multiline"
                    />
                  ) : inputType === "list" ? (
                    <FormDropdown
                      name={accessor}
                      values={listValues ?? []}
                      initialValue={initialValues && initialValues[accessor]}
                      onChange={(key, value) => handleChange(key, value)}
                      className="multiline"
                    />
                  ) : inputType === "customComponent" ? (
                    CustomComponent && (
                      <CustomComponent
                        name={accessor}
                        readOnly={readOnly}
                        initialValue={initialValues && initialValues[accessor]}
                        onChange={(key, value) => handleChange(key, value)}
                        label={label}
                        formValues={values}
                        loadValues={loadValues}
                      />
                    )
                  ) : (
                    <Numeric
                      className={classes.fullWidth}
                      onBlur={(value) => handleChange(accessor, value)}
                      integer={dataType === "integer"}
                      inputProps={{ maxLength: maxCharacters }}
                      initialValue={
                        initialValues && String(initialValues[accessor])
                      }
                    />
                  )}
                  {errors && errors[accessor] && (
                    <HvTypography className={classes.fieldError}>
                      {errors && errors[accessor]}
                    </HvTypography>
                  )}
                </HvGrid>
              )
            )}
            <HvGrid item lg={2} md={3} xs={3} className={classes.label}>
              <StyledButton
                type="button"
                category="secondary"
                className={classes.circularButton}
                onClick={handleRemove}
              >
                -
              </StyledButton>
            </HvGrid>
          </HvGrid>
        </HvGrid>
      </HvGrid>
    );
  },
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.values) === JSON.stringify(nextProps.values) &&
    JSON.stringify(prevProps.errors) === JSON.stringify(nextProps.errors) &&
    JSON.stringify(prevProps.initialValues) ===
      JSON.stringify(nextProps.initialValues) &&
    prevProps.onChange === nextProps.onChange
);
