import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { DataGrid } from "../../../components/datagrid/DataGrid";
import { ConfirmModal } from "../../../components/modals/ConfirmModal";
//Redux
import {
  clearDetailSite,
  deleteSite,
  fetchSites,
  getSites,
  tryToDelete,
  unsetSiteError,
} from "../../../store/sites";
//Other
import { tableColumns } from "./assets/fields";
import { Site } from "../../../store/sites/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { SITE_TITLE } from "./assets/namings";

interface Props {}

export const SitesTable: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { navigateToAdd, navigateToDetail } = useNavigation();
  useErrorModal(getSites, unsetSiteError);

  const { isRequesting, data, deleteId } = useSelector(getSites);

  const handleDetail = useCallback(
    (event: React.FormEvent<HTMLDivElement>, row: object) => {
      navigateToDetail(row as Site);
    },
    [navigateToDetail]
  );

  const handleDelete = useCallback(
    (event: React.FormEvent<HTMLDivElement>, row: object) => {
      const { id } = row as Site;
      dispatch(tryToDelete(id));
    },
    [dispatch]
  );

  const secondaryActions = useMemo(
    () => [
      {
        label: "View/Edit",
        action: handleDetail,
      },
      {
        label: "Delete",
        action: handleDelete,
      },
    ],
    [handleDetail, handleDelete]
  );

  const handleDeleteCancel = useCallback(() => dispatch(tryToDelete(null)), [
    dispatch,
  ]);

  const handleDeleteConfirm = useCallback(() => {
    if (!deleteId) {
      return;
    }

    dispatch(deleteSite(deleteId));
  }, [deleteId, dispatch]);

  useEffect(() => {
    dispatch(fetchSites());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearDetailSite());
  }, [dispatch]);

  return (
    <WidgetContainer>
      <DataGrid
        columns={tableColumns}
        data={data}
        noDataLabel={`There are no ${SITE_TITLE}s`}
        secondaryActions={secondaryActions}
        isLoading={isRequesting}
        onAdd={navigateToAdd}
      />
      <ConfirmModal
        open={!!deleteId}
        title={`Delete ${SITE_TITLE}`}
        content={`Are you sure you want to delete this ${SITE_TITLE}?`}
        onConfirm={handleDeleteConfirm}
        onClose={handleDeleteCancel}
      />
    </WidgetContainer>
  );
};
