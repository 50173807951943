import {
  Scenario,
  EVTypeEnumeration,
  CPTypeEnumeration,
} from "../../../../store/scenarios/type";

export const mapScenarioToApiScenario = (scenario: Scenario): Scenario => ({
  id: scenario.id,
  name: scenario.name,
  site: scenario.site,
  site_name: scenario.site_name,
  energy_cost_model: scenario.energy_cost_model,
  ev_type_enumeration: mapEvTypesApiEvTypes(scenario.ev_type_enumeration),
  cp_type_enumeration: mapCpTypesApiCpTypes(scenario.cp_type_enumeration),
});

const mapCpTypesApiCpTypes = (cpTypes: CPTypeEnumeration[]) =>
  cpTypes.map((cp) => ({
    cp_type: cp.cp_type,
    quantity: cp.quantity,
  }));

const mapEvTypesApiEvTypes = (evTypes: EVTypeEnumeration[]) =>
  evTypes.map((ev) => ({
    ev_type: ev.ev_type,
    schedule: ev.schedule,
    quantity: ev.quantity,
  }));
