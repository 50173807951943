import { getDateString, isValidDate } from "../../../utils/dates";
import { FormErrors, FormField, FormValues } from "./types";

const validate = (fields: FormField[], values: FormValues) => {
  let errors: FormErrors = {};

  fields.forEach(
    ({
      accessor,
      label,
      required,
      maxCharacters,
      range,
      dateRange,
      dataType,
      regex,
      inputType,
      errorMessage,
      customValidation,
      multiLineFields,
      multiFields,
    }) => {
      if (multiFields) {
        const fieldErrors = validate(multiFields, values[accessor]);
        if (fieldErrors && Object.keys(fieldErrors).length > 0) {
          errors[accessor] = fieldErrors;
        }
        return;
      }

      if (multiLineFields) {
        const lines = values && (values[accessor] as Array<FormValues>);

        if (!lines || lines.length === 0) {
          if (required) {
            errors[accessor] = `${label} is required`;
          }
          return;
        }
        if (lines && lines.length > 0) {
          const multilineErrors = lines
            .map((line) => {
              const lineErrors = validate(multiLineFields, line);
              if (lineErrors && Object.keys(lineErrors).length > 0) {
                return { lineId: line.lineId, ...lineErrors };
              }

              return null;
            })
            .filter((lineError) => !!lineError) as FormErrors[];

          if (multilineErrors && multilineErrors.length > 0) {
            errors[accessor] = multilineErrors;
          }

          let customValidationMessage =
            customValidation && customValidation(values[accessor], values);
          if (customValidationMessage && customValidationMessage !== "") {
            errors[accessor] = customValidationMessage;
          }

          return;
        }
      }

      const value = values[accessor];

      if (
        value === undefined ||
        value === null ||
        value === "" ||
        value === "0" ||
        value.length === 0
      ) {
        if (required) {
          errors[accessor] = `${label} is required`;
        }

        return;
      }

      const min = Number(range?.min);
      const max = Number(range?.max);
      if (
        maxCharacters &&
        !min &&
        !max &&
        String(value).length > maxCharacters
      ) {
        errors[
          accessor
        ] = `The ${label} cannot exceed the ${maxCharacters} characteres`;
        return;
      }

      if (dataType === "number" && isNaN(value)) {
        errors[accessor] = `The ${label} must be a number`;
        return;
      }

      if (dataType === "integer" && !Number.isInteger(Number(value))) {
        errors[accessor] = `The ${label} must be an integer number`;
        return;
      }

      if (
        !isNaN(min) &&
        !isNaN(max) &&
        (Number(value) < min || Number(value) > max)
      ) {
        errors[
          accessor
        ] = `The ${label} must be a number between ${min} and ${max}`;
        return;
      }

      if (!isNaN(min) && Number(value) < min) {
        errors[accessor] = `The ${label} cannot be less than ${min}`;
        return;
      }

      if (!isNaN(max) && Number(value) > max) {
        errors[accessor] = `The ${label} cannot be greater than ${max}`;
        return;
      }

      if (regex && !String(value).match(regex)) {
        errors[accessor] = errorMessage ?? `The ${label} is not valid`;
        return;
      }

      if (dataType === "date" && isValidDate(value)) {
        errors[accessor] = `The ${label} is invalid`;
        return;
      }

      const dateMin = dateRange?.min;
      const dateMax = dateRange?.max;
      if (dateMin && value < dateMin) {
        errors[accessor] = `The ${label} cannot be before ${getDateString(
          dateMin
        )}`;
        return;
      }

      if (dateMax && value > dateMax) {
        errors[accessor] = `The ${label} cannot be after ${getDateString(
          dateMax
        )}`;
        return;
      }

      let customValidationMessage =
        customValidation && customValidation(value, values);
      if (customValidationMessage && customValidationMessage !== "") {
        errors[accessor] = customValidationMessage;
        return;
      }
    }
  );

  return errors;
};

export default validate;
