import { EntityWithID } from "../type";

export interface Scenario extends EntityWithID {
  name: string;
  site: string;
  site_name: string;
  energy_cost_model: number;
  ev_type_enumeration: EVTypeEnumeration[];
  cp_type_enumeration: CPTypeEnumeration[];
  scenario_result_id?: string;
  scenario_result_status?: ResultStatus;
  calculate_results?: boolean;
  results?: any;
}

export enum ResultStatus {
  Started = "S",
  Waiting = "W",
  Processing = "P",
  Completed = "C",
  Failed = "F",
}

export interface EVTypeEnumeration {
  ev_type: string;
  schedule: string;
  quantity: number;
}

export interface CPTypeEnumeration {
  cp_type: string;
  quantity: number;
}
