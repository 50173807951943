import {
  Scenario,
  CPTypeEnumeration,
  EVTypeEnumeration,
} from "../../../../store/scenarios/type";
import { FieldNamings } from "../../../types";

export const SCENARIO_TITLE: string = "Scenario";

export const scenarioFieldNamings: FieldNamings<Scenario> = {
  id: {
    accessor: "id",
    description: "ID",
  },
  name: {
    accessor: "name",
    description: "Scenario name",
    label: "Name",
  },
  site: {
    accessor: "site",
    description: "Site",
  },
  site_name: {
    accessor: "site_name",
    description: "Site",
  },
  energy_cost_model: {
    accessor: "energy_cost_model",
    description: "Tariff type",
  },
  ev_type_enumeration: {
    accessor: "ev_type_enumeration",
    description: "Electric Vehicles and Schedules",
  },
  cp_type_enumeration: {
    accessor: "cp_type_enumeration",
    description: "Charge Points",
  },
  calculate_results: {
    accessor: "calculate_results",
    description: "Calculate Results",
  },
  scenario_result_id: {
    accessor: "scenario_result_id",
    description: "Results",
  },
  scenario_result_status: {
    accessor: "scenario_result_status",
    description: "Result Status",
  },
};

export const cpTypeEnumerationFieldNamings: FieldNamings<CPTypeEnumeration> = {
  cp_type: {
    accessor: "cp_type",
    description: "Charge Point Type",
  },
  quantity: {
    accessor: "quantity",
    description: "Quantity",
  },
};

export const evTypeEnumerationFieldNamings: FieldNamings<EVTypeEnumeration> = {
  ev_type: {
    accessor: "ev_type",
    description: "Electric Vehicle Type",
  },
  schedule: {
    accessor: "schedule",
    description: "Schedule",
  },
  quantity: {
    accessor: "quantity",
    description: "Quantity",
  },
};
