import { ElectricVehicleType } from "../../../../store/electricVehicleTypes/type";
import { FieldNamings } from "../../../types";

export const ELECTRIC_VEHICLE_TYPE_TITLE: string = "Electric Vehicle Type";

export const electricVehicleTypesFieldNamings: FieldNamings<ElectricVehicleType> = {
  id: {
    accessor: "id",
    description: "ID",
  },
  name: {
    accessor: "name",
    description: "EV type name",
    label: "Name",
  },
  batteryCapacityKwh: {
    accessor: "battery_capacity_kwh",
    description: "Battery capacity (kWh)",
  },
  maximumChargeSpeedKw: {
    accessor: "maximum_charge_speed_kw",
    description: "Maximum charge speed (kW)",
  },
  rangeMiles: {
    accessor: "range_miles",
    description: "Vehicle range (miles)",
  },
  capexCostGbp: {
    accessor: "capex_cost_gbp",
    description: "CAPEX (£/vehicle)",
  },
  opexCostGbpPerMonth: {
    accessor: "opex_cost_gbp_per_month",
    description: "OPEX (£/vehicle/month)",
  },
};
