import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
//Components
import { StyledButton } from "../../../components/styled/inputs";
//Redux
import { calculateScenario } from "../../../store/scenarios";
//Other
import { Scenario } from "../../../store/scenarios/type";
import { ResultStatus } from "../../../store/scenarios/type";
import { tableActionStyles } from "../ScenariosManage/assets/styles";

interface CalculateResultsProps {
  original: Scenario;
}

interface CalculateResultsButtonProps {
  id: number;
  children: ReactNode;
}

export const CalculateResults: React.FC<CalculateResultsProps> = ({
  original: scenario,
}) => {
  const getCalculateResultsStatus = () => {
    const status = scenario.scenario_result_status;
    switch (status) {
      case ResultStatus.Started:
      case ResultStatus.Waiting:
      case ResultStatus.Processing:
        return "—";
      case ResultStatus.Failed:
      case ResultStatus.Completed:
        return (
          <CalculateResultsButton id={scenario.id}>
            Recalculate
          </CalculateResultsButton>
        );
      default:
        return (
          <CalculateResultsButton id={scenario.id}>
            Calculate
          </CalculateResultsButton>
        );
    }
  };

  return <>{getCalculateResultsStatus()}</>;
};

const CalculateResultsButton: React.FC<CalculateResultsButtonProps> = ({
  children,
  id,
}) => {
  const dispatch = useDispatch();
  const classes = tableActionStyles();

  const handleSubmit = () => {
    dispatch(calculateScenario(id));
  };

  return (
    <StyledButton
      type="button"
      category="primary"
      onClick={handleSubmit}
      className={classes.action}
    >
      {children}
    </StyledButton>
  );
};
