import {
  HvDialog,
  HvDialogActions,
  HvDialogContent,
  HvDialogTitle,
} from "@hitachivantara/uikit-react-core";
import { withStyles } from "@material-ui/styles";

export const StyledModal = withStyles({
  paper: {
    width: "500px",
  },
})(HvDialog);

export const StyledModalTitle = withStyles({
  root: {
    "& .HvIconBase-s svg": {
      width: "32px",
      height: "32px",
      "& path": {
        fill: "orange",
      },
    },
  },
  messageContainer: {
    alignItems: "unset",
  },
})(HvDialogTitle);

export const StyledModalContent = withStyles({
  root: {
    paddingLeft: "61px",
  },
})(HvDialogContent);

export const StyledModalActions = withStyles({
  root: {
    borderTop: "3px solid #C4C4C4",
    marginLeft: "5px",
    marginRight: "5px",
  },
})(HvDialogActions);
