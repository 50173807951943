import React, { useCallback } from "react";
//Components
import { StyledChipInput } from "../../styled/inputs";

interface Props {
    name: string;
    onChange: (name: string, value: string) => void;
    initialValue?: string;
    invalid?: boolean;
    label?: string;
    readOnly?: boolean;
}

export const FormChip: React.FC<Props> = ({
    name,
    onChange,
    initialValue,
    invalid,
    label,
    readOnly
}) => {

    const handleChange = useCallback((chips: Array<string>) => {
        const trimmedLowerCaseChips = chips.map(chip => chip.trim().toLowerCase());
        onChange(name, trimmedLowerCaseChips.join(";"));
    }, [name, onChange]);

    const handleBeforeAdd= useCallback((chip: string) => {
        if (!chip.match(new RegExp('^[A-Za-z0-9]+$'))) {
            return false;
        }

        if (chip.trim().length > 50) {
            return false;
        }

        return true;
    }, []);


    return (
        <StyledChipInput
            defaultValue={initialValue?.split(";").filter(c => c !== "") ?? []}
            onChange={handleChange}
            disabled={readOnly}
            className={invalid ? "invalid" : ""}
            onBeforeAdd={handleBeforeAdd}
            id={label}
        />
    )
} 