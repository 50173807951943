import {
  CPTypeEnumeration,
  EVTypeEnumeration,
} from "../../../../store/scenarios/type";
import {
  cpTypeEnumerationFieldNamings,
  evTypeEnumerationFieldNamings,
} from "./namings";

export const validateChargePointSelection = (value: CPTypeEnumeration[]) => {
  let ids: string[] = value.map((cpte) => cpte.cp_type);
  let quantities: number[] = value.map((cpte) => cpte.quantity);

  if (ids.length !== new Set(ids).size)
    return `Please avoid duplicate entries of ${cpTypeEnumerationFieldNamings.cp_type.description}`;

  if (quantities.reduce((a, b) => a + b, 0) > 500)
    return `The overall ${cpTypeEnumerationFieldNamings.quantity.description} of Charge Points cannot exceed 500`;

  return "";
};

export const validateElectricVehicleForScheduleSelection = (
  value: EVTypeEnumeration[]
) => {
  let values: string[] = value.map((evte) => evte.ev_type + evte.schedule);
  let quantities: number[] = value.map((evte) => evte.quantity);

  if (values.length !== new Set(values).size)
    return `Please avoid duplicate entries of ${evTypeEnumerationFieldNamings.ev_type.description} with the same ${evTypeEnumerationFieldNamings.schedule.description}`;

  if (quantities.reduce((a, b) => a + b, 0) > 500)
    return `The overall ${evTypeEnumerationFieldNamings.quantity.description} of Electric Vehicles cannot exceed 500`;

  return "";
};
