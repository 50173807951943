import * as React from "react";
//Components
import {
  StyledModal,
  StyledModalActions,
  StyledModalContent,
  StyledModalTitle,
} from "../styled/modals";
import { StyledButton } from "../styled/inputs";

interface Props {
  open: boolean;
  title: string;
  buttonLabel?: string;
  onClose: () => void;
}

export const SimpleModal: React.FC<Props> = ({
  open,
  title,
  children,
  buttonLabel = "OK",
  onClose,
}) => {
  return (
    <StyledModal disableBackdropClick open={open} onClose={onClose}>
      <StyledModalTitle variant="warning">{title}</StyledModalTitle>
      {children && <StyledModalContent children={children} />}
      <StyledModalActions>
        <StyledButton type="button" category="primary" onClick={onClose}>
          {buttonLabel}
        </StyledButton>
      </StyledModalActions>
    </StyledModal>
  );
};
