import React, { useCallback, useEffect, useState } from "react";
//Components
import { InputBaseComponentProps } from "@material-ui/core";
//Styles
import { formStyles } from "../assets/styles";
interface Props {
  name: string;
  onChange: (name: string, value: File) => void;
  initialValue?: string;
  invalid?: boolean;
  readOnly?: boolean;
  inputProps?: InputBaseComponentProps;
}

export const FormFileUpload: React.FC<Props> = ({
  name,
  onChange,
  initialValue,
  invalid,
  readOnly,
}) => {
  const classes = formStyles();
  const [inputKey, setInputKey] = useState("file-upload");
  const [filename, setFilename] = useState(initialValue);

  const handleChange = useCallback(
    (e) => {
      setFilename(e?.target?.files[0].name);
      onChange(name, e?.target?.files[0]);
    },
    [name, onChange]
  );

  useEffect(() => {
    //Resets input file on error
    if (invalid) {
      let randomString = Math.random().toString(36);
      setInputKey(randomString);
    }
  }, [invalid]);

  useEffect(() => {
    setFilename(initialValue);
  }, [initialValue]);

  return (
    <>
      <input
        className={`${classes.inputFile} ${invalid ? `invalid` : ``}`}
        readOnly={readOnly}
        name={name}
        accept=".csv"
        onChange={handleChange}
        type="file"
        key={inputKey}
      />
      <label className={classes.inputFileLabel}>
        {filename || "No file chosen"}
      </label>
      <a href="/files/example.csv" download="example.csv">
        Download Example
      </a>
    </>
  );
};
