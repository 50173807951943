import {
  StoreValues,
  ListValues,
} from "../../../../components/forms/assets/types";
//Actions & Selectors
import {
  fetchChargePointTypes,
  getChargePointTypes,
} from "../../../../store/chargePointTypes";
import {
  fetchElectricVehicleTypes,
  getElectricVehicleTypes,
} from "../../../../store/electricVehicleTypes";
import { fetchSchedules, getSchedules } from "../../../../store/schedules";
import { fetchSites, getSites } from "../../../../store/sites";
//Types
import { ChargePointType } from "../../../../store/chargePointTypes/type";
import { ElectricVehicleType } from "../../../../store/electricVehicleTypes/type";
import { Schedule } from "../../../../store/schedules/type";
import { Site } from "../../../../store/sites/type";
//Formattings
import {
  formatChargePointTypeDescription,
  formatElectricVehicleTypeDescription,
  formatScheduleDescription,
  formatSiteDescription,
} from "./formattings";

export const energyCostModelListValues: ListValues[] = [
  {
    id: "1",
    label: "Fixed Electricity Price",
  },
  {
    id: "2",
    label: "Time of use Tariff",
  },
];

export const loadChargePointTypesList: StoreValues = {
  action: fetchChargePointTypes,
  selector: getChargePointTypes,
  format: (values: ChargePointType[]) =>
    values.map((chargePointType) => ({
      id: String(chargePointType.id),
      label: formatChargePointTypeDescription(chargePointType),
    })),
};

export const loadElectricVehicleTypesList: StoreValues = {
  action: fetchElectricVehicleTypes,
  selector: getElectricVehicleTypes,
  format: (values: ElectricVehicleType[]) =>
    values.map((electricVehicleType) => ({
      id: String(electricVehicleType.id),
      label: formatElectricVehicleTypeDescription(electricVehicleType),
    })),
};

export const loadSchedulesList: StoreValues = {
  action: fetchSchedules,
  selector: getSchedules,
  format: (values: Schedule[]) =>
    values.map((schedule) => ({
      id: String(schedule.id),
      label: formatScheduleDescription(schedule),
    })),
};

export const loadSitesList: StoreValues = {
  action: fetchSites,
  selector: getSites,
  format: (values: Site[]) =>
    values.map((site) => ({
      id: String(site.id),
      label: formatSiteDescription(site),
    })),
};
