import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//Components
import MsalProvider from "./components/msal/MsalProvider";
import App from "./App";
//Other
import { msalConfig } from "./components/msal/assets/msalConfig";

import './fonts/DaxlinePro/DaxlinePro-Black.ttf';
import './fonts/DaxlinePro/DaxlinePro-Bold.ttf';
import './fonts/DaxlinePro/DaxlinePro-ExtraBold.ttf';
import './fonts/DaxlinePro/DaxlinePro-Light.ttf';
import './fonts/DaxlinePro/DaxlinePro-Regular.ttf';
import './fonts/DaxlinePro/DaxlinePro-Thin.ttf';

ReactDOM.render(
  <MsalProvider config={msalConfig}>
    <App />
  </MsalProvider>,
  document.getElementById("root")
);
