import { TableColumn } from "@hitachivantara/uikit-react-core";
import { FormField } from "../../../../components/forms/assets/types";
import { formatTripsToTable } from "./formattings";
import { schedulesFieldNamings, tripsFieldNamings } from "./namings";
import { validateDay, validateTripEndTime } from "./validations";
import { ScheduleNameTooltip, ScheduleDayTooltip, ScheduleCloneTooltip } from "./Tooltips";

export const createDefaultTrip = () => ({
  trip_start_time: new Date(new Date().toDateString()),
  trip_end_time: new Date(new Date().toDateString()),
  trip_distance_miles: 0,
});

export const tableColumns: TableColumn[] = [
  {
    headerText: schedulesFieldNamings.name.description,
    accessor: schedulesFieldNamings.name.accessor,
    cellType: "alpha-numeric",
  },
  {
    headerText: schedulesFieldNamings.mon.description,
    accessor: schedulesFieldNamings.mon.accessor,
    format: (value) => formatTripsToTable(value.value),
    cellType: "alpha-numeric",
  },
  {
    headerText: schedulesFieldNamings.tue.description,
    accessor: schedulesFieldNamings.tue.accessor,
    format: (value) => formatTripsToTable(value.value),
    cellType: "alpha-numeric",
  },
  {
    headerText: schedulesFieldNamings.wed.description,
    accessor: schedulesFieldNamings.wed.accessor,
    format: (value) => formatTripsToTable(value.value),
    cellType: "alpha-numeric",
  },
  {
    headerText: schedulesFieldNamings.thu.description,
    accessor: schedulesFieldNamings.thu.accessor,
    format: (value) => formatTripsToTable(value.value),
    cellType: "alpha-numeric",
  },
  {
    headerText: schedulesFieldNamings.fri.description,
    accessor: schedulesFieldNamings.fri.accessor,
    format: (value) => formatTripsToTable(value.value),
    cellType: "alpha-numeric",
  },
  {
    headerText: schedulesFieldNamings.sat.description,
    accessor: schedulesFieldNamings.sat.accessor,
    format: (value) => formatTripsToTable(value.value),
    cellType: "alpha-numeric",
  },
  {
    headerText: schedulesFieldNamings.sun.description,
    accessor: schedulesFieldNamings.sun.accessor,
    format: (value) => formatTripsToTable(value.value),
    cellType: "alpha-numeric",
  },
];

export const detailFields: FormField[] = [
  {
    label: schedulesFieldNamings.name.description,
    accessor: schedulesFieldNamings.name.accessor,
    required: true,
    maxCharacters: 20,
    tooltipContent: ScheduleNameTooltip,
  },
  {
    label: schedulesFieldNamings.mon.description,
    accessor: schedulesFieldNamings.mon.accessor,
    inputType: "multiline",
    maxLines: 3,
    tooltipContent: ScheduleDayTooltip,
    customValidation: (value, values) => validateDay(value),
    getDefaultValue: createDefaultTrip,
    cloneOption: true,
    tooltipCloneContent: ScheduleCloneTooltip,
    multiLineFields: [
      {
        label: tripsFieldNamings.trip_start_time.description,
        accessor: tripsFieldNamings.trip_start_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
      },
      {
        label: tripsFieldNamings.trip_end_time.description,
        accessor: tripsFieldNamings.trip_end_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
        customValidation: (value, values) => validateTripEndTime(values),
      },
      {
        label: tripsFieldNamings.trip_distance_miles.description,
        accessor: tripsFieldNamings.trip_distance_miles.accessor,
        inputType: "number",
        dataType: "number",
        maxCharacters: 3,
        range: { min: 1, max: 999 },
        required: true,
      },
    ],
  },
  {
    label: schedulesFieldNamings.tue.description,
    accessor: schedulesFieldNamings.tue.accessor,
    inputType: "multiline",
    maxLines: 3,
    tooltipContent: ScheduleDayTooltip,
    customValidation: (value, values) => validateDay(value),
    getDefaultValue: createDefaultTrip,
    multiLineFields: [
      {
        label: tripsFieldNamings.trip_start_time.description,
        accessor: tripsFieldNamings.trip_start_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
      },
      {
        label: tripsFieldNamings.trip_end_time.description,
        accessor: tripsFieldNamings.trip_end_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
        customValidation: (value, values) => validateTripEndTime(values),
      },
      {
        label: tripsFieldNamings.trip_distance_miles.description,
        accessor: tripsFieldNamings.trip_distance_miles.accessor,
        inputType: "number",
        dataType: "number",
        maxCharacters: 3,
        range: { min: 1, max: 999 },
        required: true,
      },
    ],
  },
  {
    label: schedulesFieldNamings.wed.description,
    accessor: schedulesFieldNamings.wed.accessor,
    inputType: "multiline",
    maxLines: 3,
    tooltipContent: ScheduleDayTooltip,
    customValidation: (value, values) => validateDay(value),
    getDefaultValue: createDefaultTrip,
    multiLineFields: [
      {
        label: tripsFieldNamings.trip_start_time.description,
        accessor: tripsFieldNamings.trip_start_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
      },
      {
        label: tripsFieldNamings.trip_end_time.description,
        accessor: tripsFieldNamings.trip_end_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
        customValidation: (value, values) => validateTripEndTime(values),
      },
      {
        label: tripsFieldNamings.trip_distance_miles.description,
        accessor: tripsFieldNamings.trip_distance_miles.accessor,
        inputType: "number",
        dataType: "number",
        maxCharacters: 3,
        range: { min: 1, max: 999 },
        required: true,
      },
    ],
  },
  {
    label: schedulesFieldNamings.thu.description,
    accessor: schedulesFieldNamings.thu.accessor,
    inputType: "multiline",
    maxLines: 3,
    tooltipContent: ScheduleDayTooltip,
    customValidation: (value, values) => validateDay(value),
    getDefaultValue: createDefaultTrip,
    multiLineFields: [
      {
        label: tripsFieldNamings.trip_start_time.description,
        accessor: tripsFieldNamings.trip_start_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
      },
      {
        label: tripsFieldNamings.trip_end_time.description,
        accessor: tripsFieldNamings.trip_end_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
        customValidation: (value, values) => validateTripEndTime(values),
      },
      {
        label: tripsFieldNamings.trip_distance_miles.description,
        accessor: tripsFieldNamings.trip_distance_miles.accessor,
        inputType: "number",
        dataType: "number",
        maxCharacters: 3,
        range: { min: 1, max: 999 },
        required: true,
      },
    ],
  },
  {
    label: schedulesFieldNamings.fri.description,
    accessor: schedulesFieldNamings.fri.accessor,
    inputType: "multiline",
    maxLines: 3,
    tooltipContent: ScheduleDayTooltip,
    customValidation: (value, values) => validateDay(value),
    getDefaultValue: createDefaultTrip,
    multiLineFields: [
      {
        label: tripsFieldNamings.trip_start_time.description,
        accessor: tripsFieldNamings.trip_start_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
      },
      {
        label: tripsFieldNamings.trip_end_time.description,
        accessor: tripsFieldNamings.trip_end_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
        customValidation: (value, values) => validateTripEndTime(values),
      },
      {
        label: tripsFieldNamings.trip_distance_miles.description,
        accessor: tripsFieldNamings.trip_distance_miles.accessor,
        inputType: "number",
        dataType: "number",
        maxCharacters: 3,
        range: { min: 1, max: 999 },
        required: true,
      },
    ],
  },
  {
    label: schedulesFieldNamings.sat.description,
    accessor: schedulesFieldNamings.sat.accessor,
    inputType: "multiline",
    maxLines: 3,
    tooltipContent: ScheduleDayTooltip,
    getDefaultValue: createDefaultTrip,
    customValidation: (value, values) => validateDay(value),
    multiLineFields: [
      {
        label: tripsFieldNamings.trip_start_time.description,
        accessor: tripsFieldNamings.trip_start_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
      },
      {
        label: tripsFieldNamings.trip_end_time.description,
        accessor: tripsFieldNamings.trip_end_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
        customValidation: (value, values) => validateTripEndTime(values),
      },
      {
        label: tripsFieldNamings.trip_distance_miles.description,
        accessor: tripsFieldNamings.trip_distance_miles.accessor,
        inputType: "number",
        dataType: "number",
        maxCharacters: 3,
        range: { min: 1, max: 999 },
        required: true,
      },
    ],
  },
  {
    label: schedulesFieldNamings.sun.description,
    accessor: schedulesFieldNamings.sun.accessor,
    inputType: "multiline",
    maxLines: 3,
    tooltipContent: ScheduleDayTooltip,
    getDefaultValue: createDefaultTrip,
    customValidation: (value, values) => validateDay(value),
    multiLineFields: [
      {
        label: tripsFieldNamings.trip_start_time.description,
        accessor: tripsFieldNamings.trip_start_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
      },
      {
        label: tripsFieldNamings.trip_end_time.description,
        accessor: tripsFieldNamings.trip_end_time.accessor,
        dataType: "date",
        inputType: "timePicker",
        required: true,
        customValidation: (value, values) => validateTripEndTime(values),
      },
      {
        label: tripsFieldNamings.trip_distance_miles.description,
        accessor: tripsFieldNamings.trip_distance_miles.accessor,
        inputType: "number",
        dataType: "number",
        maxCharacters: 3,
        range: { min: 1, max: 999 },
        required: true,
      },
    ],
  },
];
