import { Site, Tariff, TariffSchedule } from "../../../../store/sites/type";
import { isTariffScheduleEmpty } from "./formattings";

export const mapSiteToApiSite = (site: Site): FormData => {
  var bodyFormData = new FormData();
  bodyFormData.append("data", JSON.stringify(mapSiteToApiData(site)));

  if (site.electricity_use_kwh_csv_file instanceof File) {
    bodyFormData.append(
      "electricity_use_kwh_csv_file",
      site.electricity_use_kwh_csv_file
    );
  }

  return bodyFormData;
};

const mapSiteToApiData = (site: Site) => ({
  id: site.id ?? 0,
  name: site.name,
  location: site.location,
  asc_kva: site.asc_kva,
  electricity_tariff_pence_per_kwh: site.electricity_tariff_pence_per_kwh,
  electricity_tariff_pence_per_kwh_variable: mapTariffScheduleToApiTariffSchedule(
    site.electricity_tariff_pence_per_kwh_variable
  ),
  capex_cost_gbp: site.capex_cost_gbp,
  opex_cost_gbp_per_month: site.opex_cost_gbp_per_month,
});

const mapTariffScheduleToApiTariffSchedule = (
  tariffSchedule: TariffSchedule | null
): TariffSchedule | null => {
  if (tariffSchedule && !isTariffScheduleEmpty(tariffSchedule)) {
    return {
      mon: tariffSchedule.mon ? mapTariffsToApiTariffs(tariffSchedule.mon) : [],
      tue: tariffSchedule.tue ? mapTariffsToApiTariffs(tariffSchedule.tue) : [],
      wed: tariffSchedule.wed ? mapTariffsToApiTariffs(tariffSchedule.wed) : [],
      thu: tariffSchedule.thu ? mapTariffsToApiTariffs(tariffSchedule.thu) : [],
      fri: tariffSchedule.fri ? mapTariffsToApiTariffs(tariffSchedule.fri) : [],
      sat: tariffSchedule.sat ? mapTariffsToApiTariffs(tariffSchedule.sat) : [],
      sun: tariffSchedule.sun ? mapTariffsToApiTariffs(tariffSchedule.sun) : [],
    };
  }
  return null;
};

export const mapTariffsToApiTariffs = (tariffs: Tariff[]): Tariff[] =>
  tariffs.map((tariff) => ({
    electricity_tariff_start_daily_ptu: Number(
      tariff.electricity_tariff_start_daily_ptu
    ),
    electricity_tariff_end_daily_ptu: Number(
      tariff.electricity_tariff_end_daily_ptu
    ),
    electricity_tariff_pence_per_kwh: tariff.electricity_tariff_pence_per_kwh,
  }));

export const mapApiSiteToReducer = (site: Site): Site => ({
  id: site.id,
  name: site.name,
  location: site.location,
  asc_kva: site.asc_kva,
  electricity_use_kwh_csv_file: site.electricity_use_kwh_csv_file,
  electricity_use_kwh_csv_file_name: site.electricity_use_kwh_csv_file_name,
  electricity_tariff_pence_per_kwh: site.electricity_tariff_pence_per_kwh,
  electricity_tariff_pence_per_kwh_variable: mapTariffScheduleToApiTariffSchedule(
    site.electricity_tariff_pence_per_kwh_variable
  ),
  capex_cost_gbp: site.capex_cost_gbp,
  opex_cost_gbp_per_month: site.opex_cost_gbp_per_month,
});
