import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { Form } from "../../../components/forms/Form";
//Redux
import {
  getChargePointTypes,
  insertChargePointType,
  unsetChargePointTypeError,
} from "../../../store/chargePointTypes";
//Hooks
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
//Others
import { MainComponentProps } from "../../Layout/types/types";
import { detailFields } from "./assets/fields";
import { FormValues } from "../../../components/forms/assets/types";
import { ChargePointType } from "../../../store/chargePointTypes/type";
import { CHARGE_POINT_TYPE_TITLE } from "./assets/namings";
import { ChargePointTypeDescription } from "./assets/Tooltips";

export const ChargePointTypesAdd: React.FC<MainComponentProps> = () => {
  const dispatch = useDispatch();
  useErrorModal(getChargePointTypes, unsetChargePointTypeError);
  const { navigateToTable } = useNavigation();

  const fields = useMemo(() => detailFields.filter(({ id }) => !id), []);
  const [isInserting, setIsInserting] = useState(false);
  const { isRequesting, error } = useSelector(getChargePointTypes);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const chargePointType = values as ChargePointType;
      dispatch(insertChargePointType(chargePointType));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isRequesting) {
      setIsInserting(true);
    } else if (isInserting) {
      if (error) {
        setIsInserting(false);
      } else {
        navigateToTable();
      }
    }
  }, [isRequesting, error, isInserting, setIsInserting, navigateToTable]);

  return (
    <WidgetContainer>
      <Form
        isLoading={isRequesting}
        title={`Add ${CHARGE_POINT_TYPE_TITLE}`}
        description={<ChargePointTypeDescription />}
        fields={fields}
        formErrors={error?.formErrors}
        onSubmit={handleSubmit}
        onCancel={navigateToTable}
      />
    </WidgetContainer>
  );
};
