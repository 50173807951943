import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { HvGrid, HvTable, HvTypography } from "@hitachivantara/uikit-react-core";
import { Loading } from "../../../components/loading/Loading";
import ErrorBoundary from "../../../components/errorBoundary/ErrorBoundary";
//Assets
import { MainComponentProps } from "../../Layout/types/types";
import { chargingCostsTableColumns } from "./assets/fields";
import { getChargingCostsData } from "./assets/data";
import { AssetDetails } from "../../../store/results/type";
import { getCurrencyString } from "../../../utils/currency";
//Redux
import { getResults, unsetResultsError } from "../../../store/results";
//Hooks
import { useErrorModal } from "../../../hooks/useErrorModal";

export const ScenariosResultsChargingCosts: React.FC<MainComponentProps> = () => {
  const { isRequesting, detail } = useSelector(getResults);
  useErrorModal(getResults, unsetResultsError);

  const [data, setData] = useState<AssetDetails[]>([]);

  useEffect(() => {
    if (detail) {
      const data = getChargingCostsData(detail);
      setData(data);
    }
  }, [detail]);

  return (
    <WidgetContainer>
      <HvGrid container>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            Based on the costs that you have entered, we have calculated the
            potential cost of purchasing and running your fleet.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            Note that these costs do not include any potential costs for
            connection upgrades. If an upgrade is required a quotation must be
            requested from your Distribution Network Operator. For indicative costing, please see the UK
            Power Networks site{" "}
            <a
              href=" https://www.ukpowernetworks.co.uk/-/media/files/electric-vehicle-charging/a_guide_for_electric_fleets.ashx"
              target="__blank"
            >
              here
            </a>
            .
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="xsTitle">CAPEX and OPEX</HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <Loading isLoading={isRequesting}>
            <ErrorBoundary>
              <HvTable
                columns={chargingCostsTableColumns}
                data={data}
                sortable={false}
                showPagination={false}
              />
            </ErrorBoundary>
          </Loading>
        </HvGrid>

        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            All of the above are in £ to the nearest £1
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="xsTitle">Charging Cost</HvTypography>
        </HvGrid>

        {detail &&
        (detail.weekly_energy_cost_unmanaged_gbp ||
          detail.weekly_energy_cost_smart_gbp) ? (
          <>
            <HvGrid item xs={12}>
              <HvTypography variant="normalText">
                We’ve used the tariff and vehicle efficiency information you
                have provided to calculate the approximate cost of charging your
                EVs.
              </HvTypography>
            </HvGrid>
            {detail.weekly_energy_cost_unmanaged_gbp && (
              <HvGrid item xs={12}>
                <HvTypography variant="normalText">
                  In the Unmanaged scenario your cost of charging is{" "}
                  <strong>
                    {getCurrencyString(
                      detail.weekly_energy_cost_unmanaged_gbp.vehicle
                    )}
                  </strong>{" "}
                  per week, giving a total energy cost for your site of{" "}
                  <strong>
                    {getCurrencyString(
                      detail.weekly_energy_cost_unmanaged_gbp.total
                    )}
                  </strong>{" "}
                  per week.
                </HvTypography>
              </HvGrid>
            )}
            {detail.weekly_energy_cost_smart_gbp && (
              <HvGrid item xs={12}>
                <HvTypography variant="normalText">
                  In the Smart scenario your cost of charging is{" "}
                  <strong>
                    {getCurrencyString(
                      detail.weekly_energy_cost_smart_gbp.vehicle
                    )}
                  </strong>{" "}
                  per week, giving a total energy cost for your site of{" "}
                  <strong>
                    {getCurrencyString(
                      detail.weekly_energy_cost_smart_gbp.total
                    )}
                  </strong>{" "}
                  per week.
                </HvTypography>
              </HvGrid>
            )}
            <HvGrid item xs={12}>
              <HvTypography variant="normalText">
                Note that you must specify a ‘Time of Use’ tariff to see
                variations in charging cost.
              </HvTypography>
            </HvGrid>
          </>
        ) : (
          <HvGrid item xs={12}>
            <HvTypography variant="normalText">
              Charging costs cannot be shown as it was not possible to calculate
              a feasible load profile for your site
            </HvTypography>
          </HvGrid>
        )}
      </HvGrid>
    </WidgetContainer>
  );
};
