import * as React from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import { WidgetContainer } from "../../../components/layout/WidgetContainer";

interface Props {}

export const CatalogueInfo: React.FC<Props> = () => {
  return (
    <WidgetContainer>
      <HvGrid container>
        <HvGrid item xs={12}>
          <HvTypography variant="xsTitle">
           Step 1 – Specify your assets
          </HvTypography>
        </HvGrid>
        <HvGrid item>
          <HvTypography variant="normalText">
            To use the Site Planning Tool you must provide some details on the
            sites that you plan to electrify, the <a href="catalogue/electric-vehicle-types" target="__blank">Electric Vehicles</a> and <a href="catalogue/charge-point-types" target="__blank">Charge Points</a> you plan
            to use and the <a href="catalogue/schedules" target="__blank">Schedules</a> that your Electric Vehicles will follow.
          </HvTypography>
        </HvGrid>
        <HvGrid item>
          <HvTypography variant="normalText">
            Click on the asset types above to view or create your asset types. You can 
            edit or delete existing assets using the menu to the right of each catalogue 
            item. To estimate load you need to provide as much detail as possible about 
            the power consumption of your site and vehicles. Fields related to cost are 
            generally optional and, if filled in, will provide a summary of the main costs of electrification.
          </HvTypography>
        </HvGrid>
        <HvGrid item>
          <HvTypography variant="normalText">
            To create a scenario you must have specified a site and at least one Site, 
            Charge Point Type, Electric Vehicle Type and Schedule. You can specify multiple 
            of each type when you make a scenario.
          </HvTypography>
        </HvGrid>
        <HvGrid item>
          <HvTypography variant="normalText">
           For guidance on asset creation, visit our <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-guide" target="__blank">help pages</a>.
          </HvTypography>
        </HvGrid>
        <HvGrid item>
          <HvTypography variant="normalText">
           When you’ve created your assets, move on to <a href="scenarios" target="__blank">Step 2 – Creating Scenarios</a>.
          </HvTypography>
        </HvGrid>
      </HvGrid>
    </WidgetContainer>
  );
};
