import { TableColumn } from "@hitachivantara/uikit-react-core";
import { FormField } from "../../../../components/forms/assets/types";
import { EnergyDistributionModelFormComponent } from "../CustomFormComponents";
import { chargePointTypesFieldNamings } from "./namings";
import { getEnergyDistributionModel } from "./formattings";
import { evseMaxEventsListValues } from "./dropdowns";
import {
  ChargePointTypeNameTooltip,
  ChargePointTypeEvseMaxEventsTooltip,
  ChargePointTypeChargeSpeedTooltip,
  ChargePointElectricityDistributionModelTooltip,
  ChargePointTypeCapexCostTooltip,
  ChargePointTypeOpexCostTooltip,
} from "./Tooltips";
import { getCurrencyString } from "../../../../utils/currency";

export const tableColumns: TableColumn[] = [
  {
    headerText: chargePointTypesFieldNamings.name.label,
    accessor: chargePointTypesFieldNamings.name.accessor,
    cellType: "alpha-numeric",
  },
  {
    headerText: chargePointTypesFieldNamings.chargeSpeedKw.description,
    accessor: chargePointTypesFieldNamings.chargeSpeedKw.accessor,
    cellType: "numeric",
  },
  {
    headerText: chargePointTypesFieldNamings.evseMaxEvents.label,
    accessor: chargePointTypesFieldNamings.evseMaxEvents.accessor,
    cellType: "numeric",
  },
  {
    headerText:
      chargePointTypesFieldNamings.energyDistributionModel.description,
    accessor: chargePointTypesFieldNamings.energyDistributionModel.accessor,
    cellType: "alpha-numeric",
    width: 475,
    format: (value) =>
      getEnergyDistributionModel(
        value.original[
          chargePointTypesFieldNamings.energyDistributionModel.accessor
        ]
      ),
  },
  {
    headerText: chargePointTypesFieldNamings.capexCostGbp.description,
    accessor: chargePointTypesFieldNamings.capexCostGbp.accessor,
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
  {
    headerText: chargePointTypesFieldNamings.opexCostGbpPerMonth.description,
    accessor: chargePointTypesFieldNamings.opexCostGbpPerMonth.accessor,
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
];

export const detailFields: FormField[] = [
  {
    label: chargePointTypesFieldNamings.name.description,
    accessor: chargePointTypesFieldNamings.name.accessor,
    required: true,
    maxCharacters: 20,
    tooltipContent: ChargePointTypeNameTooltip,
  },
  {
    label: chargePointTypesFieldNamings.chargeSpeedKw.description,
    accessor: chargePointTypesFieldNamings.chargeSpeedKw.accessor,
    required: true,
    inputType: "number",
    dataType: "number",
    maxCharacters: 5,
    regex: new RegExp("^\\d{0,5}(?:\\.\\d)?$"),
    range: { min: 1, max: 999 },
    tooltipContent: ChargePointTypeChargeSpeedTooltip,
  },
  {
    label: chargePointTypesFieldNamings.evseMaxEvents.description,
    accessor: chargePointTypesFieldNamings.evseMaxEvents.accessor,
    required: true,
    inputType: "list",
    listValues: evseMaxEventsListValues,
    tooltipContent: ChargePointTypeEvseMaxEventsTooltip,
  },
  {
    label: chargePointTypesFieldNamings.energyDistributionModel.description,
    accessor: chargePointTypesFieldNamings.energyDistributionModel.accessor,
    inputType: "customComponent",
    CustomComponent: EnergyDistributionModelFormComponent,
    tooltipContent: ChargePointElectricityDistributionModelTooltip,
  },
  {
    label: chargePointTypesFieldNamings.capexCostGbp.description,
    accessor: chargePointTypesFieldNamings.capexCostGbp.accessor,
    inputType: "number",
    dataType: "number",
    maxCharacters: 10,
    regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
    tooltipContent: ChargePointTypeCapexCostTooltip,
  },
  {
    label: chargePointTypesFieldNamings.opexCostGbpPerMonth.description,
    accessor: chargePointTypesFieldNamings.opexCostGbpPerMonth.accessor,
    inputType: "number",
    dataType: "number",
    maxCharacters: 10,
    regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
    tooltipContent: ChargePointTypeOpexCostTooltip,
  },
];
