import { ActionsUnion, DispatchAction } from "../type";
import { createAction } from "../action";
import { Schedule, Trip } from "./type";
import { deleteById, fetch, insert, update } from "../../api/apiClient";
import { NOTIFICATIONS_MESSAGES, setSuccess } from "../notifications";
import { ApiError } from "../../api/types";

export const SCHEDULE_URL = "/assets/schedules";

export enum ActionTypes {
  CLEAR_DETAIL_SCHEDULE = "CLEAR_DETAIL_SCHEDULE",
  CLONE_SCHEDULES = "CLONE_SCHEDULES",
  FETCH_SCHEDULES_REQUEST = "FETCH_SCHEDULES_REQUEST",
  FETCH_SCHEDULES_SUCCESS = "FETCH_SCHEDULES_SUCCESS",
  FETCH_SCHEDULES_FAILURE = "FETCH_SCHEDULES_FAILURE",
  FETCH_SCHEDULE_REQUEST = "FETCH_SCHEDULE_REQUEST",
  FETCH_SCHEDULE_SUCCESS = "FETCH_SCHEDULE_SUCCESS",
  FETCH_SCHEDULE_FAILURE = "FETCH_SCHEDULE_FAILURE",
  DELETE_SCHEDULE_REQUEST = "DELETE_SCHEDULE_REQUEST",
  DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS",
  DELETE_SCHEDULE_FAILURE = "DELETE_SCHEDULE_FAILURE",
  UPDATE_SCHEDULE_REQUEST = "UPDATE_SCHEDULE_REQUEST",
  UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS",
  UPDATE_SCHEDULE_FAILURE = "UPDATE_SCHEDULE_FAILURE",
  INSERT_SCHEDULE_REQUEST = "INSERT_SCHEDULE_REQUEST",
  INSERT_SCHEDULE_SUCCESS = "INSERT_SCHEDULE_SUCCESS",
  INSERT_SCHEDULE_FAILURE = "INSERT_SCHEDULE_FAILURE",
  TRY_TO_DELETE_SCHEDULE = "TRY_TO_DELETE_SCHEDULE",
  UNSET_SCHEDULE_ERROR = "UNSET_SCHEDULE_ERROR",
}

export const Actions = {
  clearDetailSchedule: () =>
    createAction(ActionTypes.CLEAR_DETAIL_SCHEDULE),
  cloneSchedule: (schedule: Schedule) => 
    createAction(ActionTypes.CLONE_SCHEDULES, schedule),
  fetchSchedulesRequest: () =>
    createAction(ActionTypes.FETCH_SCHEDULES_REQUEST),
  fetchSchedulesSuccess: (data: Schedule[]) =>
    createAction(ActionTypes.FETCH_SCHEDULES_SUCCESS, data),
  fetchSchedulesError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_SCHEDULES_FAILURE, error),
  fetchScheduleRequest: () => createAction(ActionTypes.FETCH_SCHEDULE_REQUEST),
  fetchScheduleSuccess: (schedule: Schedule) =>
    createAction(ActionTypes.FETCH_SCHEDULE_SUCCESS, schedule),
  fetchScheduleError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_SCHEDULE_FAILURE, error),
  deleteScheduleRequest: () =>
    createAction(ActionTypes.DELETE_SCHEDULE_REQUEST),
  deleteScheduleSuccess: (id: number) =>
    createAction(ActionTypes.DELETE_SCHEDULE_SUCCESS, id),
  deleteScheduleError: (error: ApiError) =>
    createAction(ActionTypes.DELETE_SCHEDULE_FAILURE, error),
  updateScheduleRequest: () =>
    createAction(ActionTypes.UPDATE_SCHEDULE_REQUEST),
  updateScheduleSuccess: (schedule: Schedule) =>
    createAction(ActionTypes.UPDATE_SCHEDULE_SUCCESS, schedule),
  updateScheduleError: (error: ApiError) =>
    createAction(ActionTypes.UPDATE_SCHEDULE_FAILURE, error),
  insertScheduleRequest: () =>
    createAction(ActionTypes.INSERT_SCHEDULE_REQUEST),
  insertScheduleSuccess: () =>
    createAction(ActionTypes.INSERT_SCHEDULE_SUCCESS),
  insertScheduleError: (error: ApiError) =>
    createAction(ActionTypes.INSERT_SCHEDULE_FAILURE, error),
  tryToDelete: (id: number | null) =>
    createAction(ActionTypes.TRY_TO_DELETE_SCHEDULE, id),
  unsetScheduleError: () => createAction(ActionTypes.UNSET_SCHEDULE_ERROR),
};

export type Actions = ActionsUnion<typeof Actions>;

export function clearDetailSchedule(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.clearDetailSchedule())
  };
}

export function cloneSchedule(schedule: Schedule): DispatchAction {
  return async (dispatch) => {
     dispatch(Actions.cloneSchedule(schedule))
  };
}

export function fetchSchedules(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchSchedulesRequest());

    fetch<Schedule[]>(`${SCHEDULE_URL}/`)
      .then((data) => dispatch(Actions.fetchSchedulesSuccess(data ?? [])))
      .catch((error) => dispatch(Actions.fetchSchedulesError(error)));
  };
}

export function fetchSchedule(id: string): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchScheduleRequest());

    fetch<Schedule>(`${SCHEDULE_URL}/${id}/`)
      .then((data) => dispatch(Actions.fetchScheduleSuccess(data)))
      .catch((error: ApiError) => dispatch(Actions.fetchScheduleError(error)));
  };
}

export function deleteSchedule(id: number): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.deleteScheduleRequest());

    deleteById(`${SCHEDULE_URL}/${id}`)
      .then(() => {
        dispatch(Actions.deleteScheduleSuccess(id));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.delete));
      })
      .catch((error: ApiError) => dispatch(Actions.deleteScheduleError(error)));
  };
}

export function updateSchedule(schedule: Schedule): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.updateScheduleRequest());

    update(`${SCHEDULE_URL}/${schedule.id}/`, schedule)
      .then(() => {
        dispatch(Actions.updateScheduleSuccess(schedule));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.update));
      })
      .catch((error: ApiError) => dispatch(Actions.updateScheduleError(error)));
  };
}

export function insertSchedule(schedule: Schedule): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.insertScheduleRequest());

    insert(`${SCHEDULE_URL}/`, schedule)
      .then(() => {
        dispatch(Actions.insertScheduleSuccess());
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.insert));
      })
      .catch((error: ApiError) => dispatch(Actions.insertScheduleError(error)));
  };
}

export function tryToDelete(id: number | null): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.tryToDelete(id));
  };
}

export function unsetScheduleError(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.unsetScheduleError());
  };
}
