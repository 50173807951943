import * as React from "react";
import { useState } from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
//Styles
import { tabsStyles } from "./styles";
//Others
import { Tab } from "./types/types";

interface Props {
  tabs: Tab[];
  selectedTab?: string;
  onChange?: (selectedTab: Tab) => void;
}

export const Tabs: React.FC<Props> = ({ tabs, selectedTab, onChange }) => {
  const classes = tabsStyles();

  const [tabSelected, setTabSelected] = useState(selectedTab || tabs[0]?.id);

  return (
    <HvGrid className={classes.tabs} item xs={12}>
      {tabs &&
        tabs.map((tab) => (
          <HvTypography
            key={tab.id}
            className={classes.tabItem}
            variant={tabSelected === tab.id ? "highlightText" : "normalText"}
            onClick={() => {
              setTabSelected(tab.id);
              onChange && onChange(tab);
            }}
          >
            {tab.label}
          </HvTypography>
        ))}
    </HvGrid>
  );
};
