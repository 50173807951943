import { Actions, CRUDState } from "../type";

import { ActionTypes } from "./action";
import { Scenario } from "./type";

export type ScenarioState = CRUDState<Scenario>;

const initialState: Readonly<ScenarioState> = {
  data: [],
  isRequesting: false,
  detail: null,
  deleteId: null,
  error: null,
};

export function reducer(
  state: ScenarioState = initialState,
  action: Actions
): ScenarioState {
  switch (action.type) {
    case ActionTypes.FETCH_SCENARIOS_REQUEST:
    case ActionTypes.FETCH_SCENARIO_REQUEST:
    case ActionTypes.DELETE_SCENARIO_REQUEST:
    case ActionTypes.UPDATE_SCENARIO_REQUEST:
    case ActionTypes.INSERT_SCENARIO_REQUEST:
    case ActionTypes.CALCULATE_SCENARIO_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        error: null,
        deleteId: null,
      };
    }
    case ActionTypes.FETCH_SCENARIOS_FAILURE:
    case ActionTypes.FETCH_SCENARIO_FAILURE:
    case ActionTypes.DELETE_SCENARIO_FAILURE:
    case ActionTypes.UPDATE_SCENARIO_FAILURE:
    case ActionTypes.INSERT_SCENARIO_FAILURE:
    case ActionTypes.CALCULATE_SCENARIO_FAILURE: {
      const error = action.payload;
      return {
        ...state,
        isRequesting: false,
        error,
      };
    }
    case ActionTypes.FETCH_SCENARIOS_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        isRequesting: false,
        data: data.sort((a: Scenario, b: Scenario) => a.id - b.id),
      };
    }
    case ActionTypes.FETCH_SCENARIO_SUCCESS:
    case ActionTypes.UPDATE_SCENARIO_SUCCESS: {
      const detail = action.payload;
      return {
        ...state,
        isRequesting: false,
        detail,
      };
    }
    case ActionTypes.DELETE_SCENARIO_SUCCESS: {
      const deletedId = action.payload;
      const newData = state.data.filter(({ id }) => id !== deletedId);
      return {
        ...state,
        isRequesting: false,
        data: newData,
        deleteId: null,
      };
    }
    case ActionTypes.INSERT_SCENARIO_SUCCESS:
    case ActionTypes.CALCULATE_SCENARIO_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
      };
    }
    case ActionTypes.TRY_TO_DELETE_SCENARIO: {
      const deleteId = action.payload;
      return {
        ...state,
        deleteId,
      };
    }
    case ActionTypes.UNSET_SCENARIO_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
}
