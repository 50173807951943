import { useCallback } from "react";
import { useHistory } from "react-router";
import { EntityWithID } from "../store/type";

export const useNavigation = () => {
  const history = useHistory();

  const navigateToTable = useCallback(() => {
    const urlParts = history.location.pathname.split("/");
    const destinationUrl = [urlParts[1], urlParts[2]].join("/");

    history.push(`/${destinationUrl}`);
  }, [history]);

  const navigateToAdd = useCallback(() => {
    const currentUrl = history.location.pathname;
    history.push(`${currentUrl}/add`);
  }, [history]);

  const navigateToDetail = useCallback(
    (entity: EntityWithID) => {
      const { id } = entity;
      const currentUrl = history.location.pathname;
      history.push(`${currentUrl}/${id}`);
    },
    [history]
  );

  const navigateToDuplicate = useCallback(
    (entity: EntityWithID) => {
      const { id } = entity;
      const currentUrl = history.location.pathname;
      history.push(`${currentUrl}/duplicate/${id}`);
    },
    [history]
  );

  return {
    navigateToTable,
    navigateToAdd,
    navigateToDetail,
    navigateToDuplicate,
  };
};
