import React, { ReactNode } from "react";
//Components
import {
  HvButton,
  HvGrid,
  TableColumn,
  SecondaryAction,
} from "@hitachivantara/uikit-react-core";
import { StyledDataGrid } from "../../components/styled/tables";
import { Loading } from "../../components/loading/Loading";
import { NoData } from "./NoData";
import ErrorBoundary from "../errorBoundary/ErrorBoundary";

interface Props {
  columns: TableColumn[];
  noDataLabel: string;
  secondaryActions?: SecondaryAction[];
  isLoading?: boolean;
  data?: Array<any>;
  sortable?: boolean;
  labels?: ReactNode;
  onAdd?: () => void;
}

export const DataGrid: React.FC<Props> = ({
  columns,
  data = [],
  noDataLabel,
  secondaryActions,
  isLoading = true,
  sortable = false,
  labels,
  onAdd,
}) => {
  return (
    <HvGrid container>
      <HvGrid item xs={12}>
        <Loading isLoading={isLoading}>
          {!data.length && isLoading && <NoData label={""} />}
          {!data.length && !isLoading && <NoData label={noDataLabel} />}
          {data.length > 0 && (
            <ErrorBoundary>
              <StyledDataGrid
                data={data}
                columns={columns}
                showPagination={false}
                resizable={false}
                sortable={sortable}
                secondaryActions={secondaryActions}
              />
            </ErrorBoundary>
          )}
        </Loading>
      </HvGrid>
      {labels && (
        <HvGrid item xs={12}>
          {labels}
        </HvGrid>
      )}
      {onAdd && (
        <HvGrid item xs={12}>
          <HvButton
            type="button"
            category="primary"
            onClick={onAdd}
            disabled={isLoading}
          >
            Add
          </HvButton>
        </HvGrid>
      )}
    </HvGrid>
  );
};
