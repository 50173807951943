import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { Form } from "../../../components/forms/Form";
//Redux
import {
  cloneSchedule,
  fetchSchedule,
  getSchedules,
  unsetScheduleError,
  updateSchedule,
} from "../../../store/schedules";
//Others
import { mapScheduleToApiSchedule, mapTripsToApiTrips } from "./assets/mappings";
import { formatTripsToDetail } from "./assets/formattings";
import { MainComponentProps } from "../../Layout/types/types";
import { detailFields } from "./assets/fields";
import { FormValues, FormValuesLine } from "../../../components/forms/assets/types";
import { Schedule } from "../../../store/schedules/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { SCHEDULE_TITLE } from "./assets/namings";
import { SchedulesDescription } from "./assets/Tooltips";

interface UrlParameters {
  id: string;
}

export const SchedulesDetail: React.FC<MainComponentProps> = () => {
  const { id } = useParams<UrlParameters>();
  const dispatch = useDispatch();
  const { navigateToTable } = useNavigation();
  useErrorModal(getSchedules, unsetScheduleError);

  const [isUpdating, setIsUpdating] = useState(false);

  const { isRequesting, detail, error } = useSelector(getSchedules);

  const formatDetail = useMemo(
    () =>
      detail
        ? {
            ...detail,
            mon: formatTripsToDetail(detail.mon),
            tue: formatTripsToDetail(detail.tue),
            wed: formatTripsToDetail(detail.wed),
            thu: formatTripsToDetail(detail.thu),
            fri: formatTripsToDetail(detail.fri),
            sat: formatTripsToDetail(detail.sat),
            sun: formatTripsToDetail(detail.sun),
          }
        : null,
    [detail]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const schedule = values as Schedule;
      dispatch(updateSchedule(mapScheduleToApiSchedule(schedule)));
      setIsUpdating(true);
    },
    [dispatch]
  );

  const handleOnClone = useCallback((formValues: FormValues) => {
    const detail = formValues["cloneDetail"] as Array<FormValuesLine>;
    const scheduleTrip = mapTripsToApiTrips(detail.map((formLine) => ({
      trip_start_time: formLine.trip_start_time,
      trip_end_time: formLine.trip_end_time,
      trip_distance_miles: formLine.trip_distance_miles
    })));

    let scheduleName = "";
    if (formValues?.name)
      scheduleName = formValues.name;

    const cloneDetail = {
      id: 0,
      name: scheduleName,
      mon: scheduleTrip,
      tue: scheduleTrip,
      wed: scheduleTrip,
      thu: scheduleTrip,
      fri: scheduleTrip,
      sat: scheduleTrip,
      sun: scheduleTrip,
    };
    dispatch(cloneSchedule(cloneDetail));
  }, []);

  useEffect(() => {
    dispatch(fetchSchedule(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (isRequesting) {
      return;
    }

    if ((isUpdating && !error) || error?.code === 404) {
      navigateToTable();
    } else if (isUpdating && error) {
      setIsUpdating(false);
    }
  }, [isRequesting, isUpdating, error, setIsUpdating, navigateToTable]);

  return (
    <WidgetContainer>
      <Form
        isLoading={isRequesting}
        title={`${SCHEDULE_TITLE}`}
        description={<SchedulesDescription />}
        fields={detailFields}
        formErrors={error?.formErrors}
        initialValues={formatDetail}
        onSubmit={handleSubmit}
        onCancel={navigateToTable}
        onClone={handleOnClone}
      />
    </WidgetContainer>
  );
};
