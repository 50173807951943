import React, { useCallback, useState } from "react";
//Components
import { StyledDropdownInput } from "../styled/inputs";
//Utils
import clsx from "clsx";
import { selectValueFromList } from "../../utils/dropdowns";
import { ListValueProp } from "@hitachivantara/uikit-react-core";

interface Props {
  onChange?: (value: string) => void;
  initialValue?: string;
  invalid?: boolean;
  values: ListValueProp[];
  name?: string;
  readOnly?: boolean;
  className?: string;
}

export const Dropdown: React.FC<Props> = ({
  onChange,
  initialValue,
  invalid,
  values,
  name,
  readOnly,
  className = "",
}) => {
  const [listValues] = useState(selectValueFromList([...values], initialValue));

  const handleChange = useCallback(
    (selected: ListValueProp | ListValueProp[] | undefined) => {
      if (!onChange) {
        return;
      }

      if (selected && !Array.isArray(selected)) {
        onChange(selected.id ?? "0");
        return;
      }

      onChange("0");
    },
    [onChange]
  );

  return (
    <StyledDropdownInput
      id={name}
      className={clsx(invalid ? "invalid" : "", className)}
      values={listValues}
      hasTooltips
      onChange={handleChange}
      disabled={readOnly}
    />
  );
};
