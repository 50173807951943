import * as React from "react";
//Components
import { SideMenu } from "./SideMenu";
import { HvGrid } from "@hitachivantara/uikit-react-core";
import { UserActions } from "../../components/layout/UserActions";
import { ErrorModal } from "./ErrorModal";
import { SuccessNotification } from "./SuccessNotification";
//Styles
import { layoutStyles } from "./styles";
//Others
import { Route } from "../../routing/types";
import { Route as RouterRoute, Switch } from "react-router";
interface Props {
  routes: Route[];
}

const style = {
  '@media screen': {
    marginTop: "300px",
  },
  '@media print': {
    marginTop: "0px!",
  },
};

export const Layout: React.FC<Props> = ({ routes }) => {
  const classes = layoutStyles();
  return (
    <>
      <SideMenu routes={routes} />
      <div className={classes.layout}>
        <HvGrid item xs={12}>
          <Switch>
            {routes.map(({ path, label, Component, children }, index) => (
              <RouterRoute key={index} exact={index === 0} path={path}>
                <Component title={label} routes={children} />
              </RouterRoute>
            ))}
          </Switch>
        </HvGrid>
      </div>
      <SuccessNotification />
      <ErrorModal />
    </>
  );
};
