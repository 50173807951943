import { TableColumn } from "@hitachivantara/uikit-react-core";
import { getCurrencyString } from "../../../../utils/currency";
import { ChargingCostsCell } from "../../CustomTableComponents/ChargingCostsCell";

export const chargingCostsTableColumns: TableColumn[] = [
  {
    headerText: "",
    accessor: "asset",
    cellType: "alpha-numeric",
    Cell: ChargingCostsCell,
  },
  {
    headerText: "CAPEX",
    accessor: "capex_cost_gbp",
    cellType: "numeric",
    Cell: ChargingCostsCell,
  },
  {
    headerText: "OPEX per Month",
    accessor: "opex_cost_gbp_per_month",
    cellType: "numeric",
    Cell: ChargingCostsCell,
  },
];

export const electricVehiclesTableColumns: TableColumn[] = [
  {
    headerText: "Count",
    accessor: "quantity",
    cellType: "alpha-numeric",
  },
  {
    headerText: "EV Name",
    accessor: "name",
    cellType: "alpha-numeric",
  },
  {
    headerText: "Schedule",
    accessor: "schedule",
    format: (value) => value.original.schedule.name,
    cellType: "alpha-numeric",
  },
  {
    headerText: "Battery Capacity (kWh)",
    accessor: "battery_capacity_kwh",
    cellType: "numeric",
  },
  {
    headerText: "Range (Miles)",
    accessor: "range_miles",
    cellType: "numeric",
  },
  {
    headerText: "Charge Speed (kW)",
    accessor: "maximum_charge_speed_kw",
    cellType: "numeric",
  },
  {
    headerText: "CAPEX (£/vehicle)",
    accessor: "capex_cost_gbp",
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
  {
    headerText: "OPEX (£/vehicle/month)",
    accessor: "opex_cost_gbp_per_month",
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
];

export const chargepointsTableColumns: TableColumn[] = [
  {
    headerText: "Count",
    accessor: "quantity",
    cellType: "alpha-numeric",
  },
  {
    headerText: "CP Name",
    accessor: "name",
    cellType: "alpha-numeric",
  },
  {
    headerText: "Connectors",
    accessor: "evse_max_events",
    cellType: "numeric",
  },
  {
    headerText: "Capacity per connector (kW)",
    accessor: "charge_speed_per_connector_kw",
    cellType: "numeric",
  },
  {
    headerText: "CAPEX (£/device)",
    accessor: "capex_cost_gbp",
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
  {
    headerText: "OPEX (£/device/month)",
    accessor: "opex_cost_gbp_per_month",
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
];
