import React, { useCallback, useMemo, useState } from "react";
//Components
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StyledTimepicker } from "../styled/inputs";
//Other
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { pickerStyles } from "./assets/styles";
import { isDate } from "../../utils/dates";

interface Props {
  onBlur?: (value: Date) => void;
  initialValue?: Date | string;
}

export const TimePicker: React.FC<Props> = ({ onBlur, initialValue }) => {
  const classes = pickerStyles();

  const newInitialValue = useMemo(() => {
    if (!initialValue) {
      return handleDateFormat("00:00");
    }
    return handleDateFormat(initialValue);
  }, [initialValue]);

  const [date, setDate] = useState<Date | string>(newInitialValue);

  function handleDateFormat(date: string | Date) {
    if (isDate(date as Date)) {
      return date;
    }

    const hourParts = String(date).split(":");
    if (hourParts.length > 0) {
      const newDate = new Date();
      newDate.setHours(Number(hourParts[0]));
      newDate.setMinutes(Number(hourParts[1]));
      if (isDate(newDate)) {
        return newDate;
      }
    }

    return new Date();
  }

  const handleDateChange = useCallback(
    (newDate: MaterialUiPickersDate, value?: string | null | undefined) => {
      setDate(newDate as Date);
    },
    []
  );
  const handleBlur = useCallback(() => {
    onBlur && onBlur(date as Date);
  }, [onBlur, date]);
  const handleAccept = useCallback(
    (newDate: MaterialUiPickersDate) => {
      onBlur && onBlur(newDate as Date);
    },
    [onBlur]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledTimepicker
        ampm={false}
        format="HH:mm"
        variant="inline"
        value={handleDateFormat(date)}
        onChange={handleDateChange}
        onBlur={handleBlur}
        onAccept={handleAccept}
        autoOk={true}
        PopoverProps={{ PaperProps: { className: classes.picker } }}
        invalidDateMessage={null}
      />
    </MuiPickersUtilsProvider>
  );
};
