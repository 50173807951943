import { ActionsUnion, DispatchAction } from "../type";
import { createAction } from "../action";
import { Site } from "./type";
import {
  deleteById,
  fetch,
  insertWithFile,
  updateWithFile,
} from "../../api/apiClient";
import {
  mapSiteToApiSite,
  mapApiSiteToReducer,
} from "../../pages/Catalogue/Sites/assets/mappings";
import { NOTIFICATIONS_MESSAGES, setSuccess } from "../notifications";
import { ApiError } from "../../api/types";

export const SITE_URL = "/assets/sites";

export enum ActionTypes {
  CLEAR_DETAIL_SITE = "CLEAR_DETAIL_SITE",
  CLONE_SITE_PERIODS = "CLONE_SITE_PERIODS",
  FETCH_SITES_REQUEST = "FETCH_SITES_REQUEST",
  FETCH_SITES_SUCCESS = "FETCH_SITES_SUCCESS",
  FETCH_SITES_FAILURE = "FETCH_SITES_FAILURE",
  FETCH_SITE_REQUEST = "FETCH_SITE_REQUEST",
  FETCH_SITE_SUCCESS = "FETCH_SITE_SUCCESS",
  FETCH_SITE_FAILURE = "FETCH_SITE_FAILURE",
  DELETE_SITE_REQUEST = "DELETE_SITE_REQUEST",
  DELETE_SITE_SUCCESS = "DELETE_SITE_SUCCESS",
  DELETE_SITE_FAILURE = "DELETE_SITE_FAILURE",
  UPDATE_SITE_REQUEST = "UPDATE_SITE_REQUEST",
  UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS",
  UPDATE_SITE_FAILURE = "UPDATE_SITE_FAILURE",
  INSERT_SITE_REQUEST = "INSERT_SITE_REQUEST",
  INSERT_SITE_SUCCESS = "INSERT_SITE_SUCCESS",
  INSERT_SITE_FAILURE = "INSERT_SITE_FAILURE",
  TRY_TO_DELETE_SITE = "TRY_TO_DELETE_SITE",
  UNSET_SITE_ERROR = "UNSET_SITE_ERROR",
}

export const Actions = {
  clearDetailSite: () =>
    createAction(ActionTypes.CLEAR_DETAIL_SITE),
  cloneSitePeriods: (site: Site) => 
    createAction(ActionTypes.CLONE_SITE_PERIODS, site),
  fetchSitesRequest: () => createAction(ActionTypes.FETCH_SITES_REQUEST),
  fetchSitesSuccess: (data: Site[]) =>
    createAction(ActionTypes.FETCH_SITES_SUCCESS, data),
  fetchSitesError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_SITES_FAILURE, error),
  fetchSiteRequest: () => createAction(ActionTypes.FETCH_SITE_REQUEST),
  fetchSiteSuccess: (site: Site) =>
    createAction(ActionTypes.FETCH_SITE_SUCCESS, site),
  fetchSiteError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_SITE_FAILURE, error),
  deleteSiteRequest: () => createAction(ActionTypes.DELETE_SITE_REQUEST),
  deleteSiteSuccess: (id: number) =>
    createAction(ActionTypes.DELETE_SITE_SUCCESS, id),
  deleteSiteError: (error: ApiError) =>
    createAction(ActionTypes.DELETE_SITE_FAILURE, error),
  updateSiteRequest: () => createAction(ActionTypes.UPDATE_SITE_REQUEST),
  updateSiteSuccess: (site: Site) =>
    createAction(ActionTypes.UPDATE_SITE_SUCCESS, site),
  updateSiteError: (error: ApiError) =>
    createAction(ActionTypes.UPDATE_SITE_FAILURE, error),
  insertSiteRequest: () => createAction(ActionTypes.INSERT_SITE_REQUEST),
  insertSiteSuccess: () => createAction(ActionTypes.INSERT_SITE_SUCCESS),
  insertSiteError: (error: ApiError) =>
    createAction(ActionTypes.INSERT_SITE_FAILURE, error),
  tryToDelete: (id: number | null) =>
    createAction(ActionTypes.TRY_TO_DELETE_SITE, id),
  unsetSiteError: () => createAction(ActionTypes.UNSET_SITE_ERROR),
};

export type Actions = ActionsUnion<typeof Actions>;

export function clearDetailSite(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.clearDetailSite())
  };
}

export function cloneSitePeriods(site: Site): DispatchAction {
  return async (dispatch) => {
     dispatch(Actions.cloneSitePeriods(site))
  };
}

export function fetchSites(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchSitesRequest());

    fetch<Site[]>(`${SITE_URL}/`)
      .then((data) => dispatch(Actions.fetchSitesSuccess(data ?? [])))
      .catch((error) => dispatch(Actions.fetchSitesError(error)));
  };
}

export function fetchSite(id: string): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchSiteRequest());

    fetch<Site>(`${SITE_URL}/${id}/`)
      .then((data) => dispatch(Actions.fetchSiteSuccess(data)))
      .catch((error: ApiError) => dispatch(Actions.fetchSiteError(error)));
  };
}

export function deleteSite(id: number): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.deleteSiteRequest());

    deleteById(`${SITE_URL}/${id}`)
      .then(() => {
        dispatch(Actions.deleteSiteSuccess(id));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.delete));
      })
      .catch((error: ApiError) => dispatch(Actions.deleteSiteError(error)));
  };
}

export function updateSite(site: Site, id: number): DispatchAction {
  return async (dispatch) => {
    const formData = mapSiteToApiSite(site);
    dispatch(Actions.updateSiteRequest());
    updateWithFile(`${SITE_URL}/${id}/`, formData)
      .then(() => {
        dispatch(Actions.updateSiteSuccess(mapApiSiteToReducer(site)));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.update));
      })
      .catch((error: ApiError) => dispatch(Actions.updateSiteError(error)));
  };
}

export function insertSite(site: Site): DispatchAction {
  return async (dispatch) => {
    const formData = mapSiteToApiSite(site);
    dispatch(Actions.insertSiteRequest());
    insertWithFile(`${SITE_URL}/`, formData)
      .then(() => {
        dispatch(Actions.insertSiteSuccess());
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.insert));
      })
      .catch((error: ApiError) => dispatch(Actions.insertSiteError(error)));
  };
}

export function tryToDelete(id: number | null): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.tryToDelete(id));
  };
}

export function unsetSiteError(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.unsetSiteError());
  };
}
