import React from "react";

interface Props {}

export const SchedulesDescription: React.FC<Props> = () => (
  <>
	  Use this page to specify when your vehicles will be away from the site (and not able to charge) 
	  and how far they will travel.
	  <br/><br/>
	  For the best results, enter the longest trips the vehicles are likely to make, together with the
  	earliest likely departure time and latest likely return time. For more help, see our <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-example"
      target="__blank">example</a>.
  	If the vehicle will not plug in as soon as they enter the depot, the trip end time should reflect 
  	when the vehicle is expected to plug in.
  	<br/><br/>
  	Most schedules will only have one duty each day, however if the vehicles will return to the depot 
  	and be plugged in during the day you can schedule up to three trips if needed - please enter them 
  	in chronological order. Up to five schedules can be created and assigned to vehicles in the scenario 
  	builder. Your schedule must contain at least one trip.
	<br/><br/>
  	Fields marked in <strong>bold</strong> are required.
	<br/><br/>
	To copy the same schedule to all days, complete the times and distance for Monday and then press the copy (❐) button.
  </>
);

export const ScheduleNameTooltip: string =
  "Choose a unique name to identify this schedule.";

export const ScheduleDayTooltip: string =
  "Press the '+' button against the relevant days to enter the schedule of when your vehicles will be away from the depot. Press '-' to delete a trip. If your trip ends at or after midnight, select 23:59 and continue on the next day.";

 export const ScheduleCloneTooltip: string =
  "Duplicate to all days";
