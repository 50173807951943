import { useState, useEffect, useCallback } from "react";
import { FormErrors, FormValues } from "./types";

const useForm = (
  initialValues: FormValues,
  initialErrors: FormErrors,
  onSubmit: (values: FormValues) => void,
  validate: (values: FormValues) => FormErrors
) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<FormErrors>(initialErrors);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  useEffect(() => {
    if ((!errors || Object.keys(errors).length === 0) && isSubmitting) {
      onSubmit(values);
    }

    setIsSubmitting(false);
  }, [errors, isSubmitting, onSubmit, values]);

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
    setTriedToSubmit(true);
  };

  const handleChange = (name: string, value: any) => {
    let newValues: FormValues = {};
    newValues = {
      ...values,
      [name]:
        typeof value === "string" || value instanceof String
          ? value.trim().length === 0
            ? null
            : value.trim()
          : value,
    };

    setValues(newValues);
    setIsPristine(JSON.stringify(initialValues) === JSON.stringify(newValues));

    if (triedToSubmit) {
      setErrors(validate(newValues));
    }
  };

  const handleReset = useCallback(() => {
    setIsPristine(true);
    setTriedToSubmit(false);
  }, []);

  return {
    handleChange,
    handleSubmit,
    handleReset,
    setValues,
    setErrors,
    values,
    errors,
    isPristine,
  };
};

export default useForm;
