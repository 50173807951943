import * as React from "react";
//Components
import {
  StyledModal,
  StyledModalActions,
  StyledModalContent,
  StyledModalTitle,
} from "../styled/modals";
import { StyledButton } from "../styled/inputs";

interface Props {
  open: boolean;
  title: string;
  content: string | null;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmModal: React.FC<Props> = ({
  open,
  title,
  content,
  confirmLabel = "Yes",
  cancelLabel = "No",
  onConfirm,
  onClose,
}) => {
  return (
    <StyledModal disableBackdropClick open={open} onClose={onClose}>
      <StyledModalTitle variant="warning">{title}</StyledModalTitle>
      <StyledModalContent children={content} />
      <StyledModalActions>
        <StyledButton type="button" category="primary" onClick={onConfirm}>
          {confirmLabel}
        </StyledButton>
        <StyledButton type="button" category="secondary" onClick={onClose}>
          {cancelLabel}
        </StyledButton>
      </StyledModalActions>
    </StyledModal>
  );
};
