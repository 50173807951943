import { createContext } from "react";
import { MsalContextInterface } from "./types/types";

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <MsalProvider>.");
};

const initialContext = {
  editProfile: stub,
  getAuthToken: stub,
  isLoading: true,
  isLoggedIn: false,
  logout: stub,
  login: stub,
  name: null,
};

const MsalContext = createContext<MsalContextInterface>(initialContext);

export default MsalContext;
