import React from "react";
//Styles
import { buttonStyles } from "./assets/styles";

interface Props {
  accessor: string;
  content: React.FC | string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const FormTooltipButton: React.FC<Props> = ({ accessor, content, onClick }) => {
  const classes = buttonStyles();

  const showTooltip = (id: string) => {
    const tooltip = document.querySelector(id);
    tooltip && tooltip.setAttribute("data-show", "");
  };

  const hideTooltip = (id: string) => {
    const tooltip = document.querySelector(id);
    tooltip && tooltip.removeAttribute("data-show");
  };

  const TooltipContent = () => {
    const Content = content;
    return <Content />;
  };

  return (
    <div className={classes.tooltipWrapper}>
      <button
        aria-describedby={`${accessor}-tooltip`}
        type="button"
        onMouseEnter={() => showTooltip(`#${accessor}-tooltip`)}
        onFocus={() => showTooltip(`#${accessor}-tooltip`)}
        onMouseLeave={() => hideTooltip(`#${accessor}-tooltip`)}
        onBlur={() => hideTooltip(`#${accessor}-tooltip`)}
        className={classes.tooltipButton}
        onClick={ onClick }
      >
        <span className={classes.spanIcon}>❐</span>
      </button>
      <div
        className={classes.tooltip}
        id={`${accessor}-tooltip`}
        role="tooltip"
      >
        {typeof content === "string" || content instanceof String ? (
          content
        ) : (
          <TooltipContent />
        )}
      </div>
    </div>
  );
};
