import { Navigation } from "@hitachivantara/uikit-react-core/dist/VerticalNavigation";
import { withStyles } from "@material-ui/styles";

export const StyledNavigationLeft = withStyles({
  root: {
    "& .HvVerticalNavigationVerticalContainer-root": {
      width: "100%",
    },
    "& .HvVerticalNavigationTreeView-root": {
      backgroundColor: "#ffffff",
      display: "flex",
      paddingTop: "15px",
    },
    "&:only-child": {
      padding: 0,
    },
    "& ul li":{
      listStyle: "none",
      minHeight: "32px",
      marginLeft: "20px",
    },
    "& .HvTypography-normalText": {
      color: "#30354a",
      fontWeight: "bold",
    },
    "& path": {
      fill: "#30354a",
    },
    "& .HvVerticalNavigationTreeViewItem-content": {
      "&:hover": {
        backgroundColor: "#ffffff !important",
        textDecoration: "underline",
        "& div": {
          backgroundColor: "#ffffff !important",
        },
        "& svg": {
          backgroundColor: "#ffffff !important",
        },
      },
      "&:focus, &:focus *": {
        backgroundColor: "#ffffff !important",
      },
    },
    "& .HvVerticalNavigationTreeViewItem-selected": {
      "& .HvVerticalNavigationTreeViewItem-content": {
        backgroundColor: "#ffffff !important",
        borderLeft: "none",
        color: "#f76100 !important",
        "& *": {
          backgroundColor: "#ffffff !important",
          fill: "#f76100 !important",
        },
      },
    },
  },
})(Navigation);

export const StyledNavigationRight = withStyles({
  HvVerticalNavigationVerticalContainerRelative: {
    position: "relative",
    float: "right",
    alignItems: "none",
  },
  root: {
    "& .HvVerticalNavigationVerticalContainer-static": {

    },
    "& .HvVerticalNavigationVerticalContainer-root": {
      width: "100%",
      position: "relative",
      float: "right",
      alignItems: "none",
    },
    "& .HvVerticalNavigationTreeView-root": {
      backgroundColor: "#ffffff",
      display: "flex",
      paddingTop: "15px",
    },
    "&:only-child": {
      padding: 0,
    },
    "& ul li":{
      listStyle: "none",
      minHeight: "32px",
      marginLeft: "20px",
    },
    "& .HvTypography-normalText": {
      color: "#30354a",
      fontWeight: "bold",
    },
    "& path": {
      fill: "#30354a",
    },
    "& .HvVerticalNavigationTreeViewItem-content": {
      "&:hover": {
        backgroundColor: "#ffffff !important",
        textDecoration: "underline",
        "& div": {
          backgroundColor: "#ffffff !important",
        },
        "& svg": {
          backgroundColor: "#ffffff !important",
        },
      },
      "&:focus, &:focus *": {
        backgroundColor: "#ffffff !important",
      },
    },
    "& .HvVerticalNavigationTreeViewItem-selected": {
      "& .HvVerticalNavigationTreeViewItem-content": {
        backgroundColor: "#ffffff !important",
        borderLeft: "none",
        color: "#f76100 !important",
        "& *": {
          backgroundColor: "#ffffff !important",
          fill: "#f76100 !important",
        },
      },
    },
  },
})(Navigation);
