import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
//Components
import { Login } from "../../pages/Auth";
import { HvContainer, HvLoading, HvProvider } from "@hitachivantara/uikit-react-core";
//Other
import { routes } from "../../routing/routes";
import customTheme from "../../theme/customTheme";
import { Layout } from "../../pages/Layout/Layout";
//Styles
import { authenticationStyles } from "./assets/styles";
//Hooks
import useMsal from "../msal/assets/useMsal";

export const AuthenticationGate: React.FC = () => {
  const classes = authenticationStyles();
  const { isLoading, isLoggedIn } = useMsal();

  return (
    <>
      {isLoading ? (
        <HvProvider theme={customTheme("login")}>
          <HvContainer>
            <HvLoading className={classes.loading} color="rgb(247 97 0)" />
          </HvContainer>
        </HvProvider>
      ) : isLoggedIn ? (
        <HvProvider theme={customTheme("app")}>
          <HvContainer>
            <Router>
              <Layout routes={routes} />
            </Router>
          </HvContainer>
        </HvProvider>
      ) : (
        <HvProvider theme={customTheme("login")}>
          <Login />
        </HvProvider>
      )}
    </>
  );
};
