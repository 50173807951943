import React, { useCallback, useEffect, useState } from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import {
  FormErrors,
  FormField,
  FormValues,
  FormValuesLine,
} from "./assets/types";
import { FormTooltipButton } from "./FormTooltipButton";
import { FormMultilineLine } from "./FormMultilineLine";
//Styles
import { formStyles } from "./assets/styles";
import { StyledButton } from "../styled/inputs";

interface Props {
  name: string;
  fields: FormField[];
  maxLines?: number;
  initialValues?: Array<FormValuesLine>;
  values?: FormValues;
  errors?: FormErrors[];
  cloneOption?: Boolean;
  getDefaultValue?: () => FormValues;
  onChange: (name: string, value: any, affectedFields?: Array<string>) => void;
  onClone?: (lines: Array<FormValuesLine>) => any | null;
  tooltipCloneContent?: string;
}

export const FormMultiline: React.FC<Props> = ({
  name,
  fields,
  maxLines,
  initialValues = [],
  values = [],
  errors = [],
  cloneOption,
  tooltipCloneContent,
  onChange,
  onClone,
  getDefaultValue,
}) => {
  const classes = formStyles();
  const [lines, setLines] = useState<Array<FormValuesLine>>([]);

  const handleAddNewLine = useCallback(() => {
    let newLines: Array<FormValuesLine> = [];
    setLines((l) => {
      const newInitialValues = getDefaultValue
        ? { initialValues: getDefaultValue() }
        : {};
      newLines = [
        ...l,
        {
          lineId: l[l.length - 1] ? l[l.length - 1].lineId + 1 : 1,
          ...newInitialValues.initialValues,
          initialValues: newInitialValues.initialValues,
          id: 0,
        },
      ];
      return newLines;
    });
  }, [getDefaultValue]);

  const handleCloneLines = useCallback(() => {
    let newLines: Array<FormValuesLine> = [];
    setLines((l) => {
      newLines = l;
      return newLines;
    });
    
    if (onClone) {
      onClone(newLines);

      return "";
    }
  }, [getDefaultValue]);

  const handleRemoveLine = useCallback((lineId: number) => {
    let newLines: Array<FormValuesLine> = [];
    setLines((l) => {
      newLines = l.filter((line) => {
        return line.lineId !== lineId;
      });
      return newLines;
    });
  }, []);

  const handleChangeLine = useCallback(
    (lineId: number, fieldName: string, value: any) => {
      let newLines: Array<FormValuesLine> = [];
      setLines((l) => {
        newLines = l.map((line) =>
          line.lineId === lineId
            ? {
                ...line,
                [fieldName]: value,
              }
            : line
        );
        return newLines;
      });
    },
    []
  );

  const handleLineErrors = useCallback(
    (lineId: number) => {
      if (errors && Array.isArray(errors)) {
        let lineError = {};
        errors.forEach((error) => {
          if (error && String(error.lineId) === String(lineId)) {
            lineError = error;
          }
        });
        return lineError;
      } else if (typeof errors === "object") {
        return errors[lineId - 1];
      }
    },
    [errors]
  );

  useEffect(() => {
    onChange(name, lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines]);

  useEffect(() => {
    if (initialValues && initialValues.length > 0) {
      setLines(
        initialValues.map((line, index) => ({
          ...line,
          lineId: index + 1,
          initialValues: { ...line },
        }))
      );
    }
  }, [initialValues]);

  return (
    <HvGrid container className={classes.multilineHeader}>
      <HvGrid item xs={12}>
        <HvGrid container>
          {fields.map(({ label }, index) => (
            <HvGrid item lg={2} md={3} xs={3} key={`${index}${label}`}>
              <HvTypography className={classes.columnLabel}>
                {label}
              </HvTypography>
            </HvGrid>
          ))}
        </HvGrid>
      </HvGrid>
      <HvGrid item xs={12}>
        {lines.map((line) => (
          <FormMultilineLine
            key={`${line.lineId}`}
            id={line.lineId}
            initialValues={line.initialValues}
            values={values}
            fields={fields}
            onRemove={handleRemoveLine}
            onChange={handleChangeLine}
            errors={handleLineErrors(line.lineId)}
          />
        ))}
      </HvGrid>
      <HvGrid xs={12} item className={classes.addButton}>
        <StyledButton
          type="button"
          category="primary"
          className={classes.circularButton}
          onClick={
            maxLines
              ? maxLines > lines.length
                ? handleAddNewLine
                : () => {}
              : handleAddNewLine
          }
        >
          +
        </StyledButton>

        {cloneOption && (
          <FormTooltipButton
            accessor="cloneOption"
            content={tooltipCloneContent ? tooltipCloneContent : ""}
            onClick={
              handleCloneLines
            }
          />
        )}
      </HvGrid>
      {errors && typeof errors === "string" && errors !== "" && (
        <HvGrid item xs={12}>
          <HvTypography className={classes.fieldError}>{errors}</HvTypography>
        </HvGrid>
      )}
    </HvGrid>
  );
};
