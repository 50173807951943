import { FormValues } from "../../../../components/forms/assets/types";
import { Tariff } from "../../../../store/sites/type";
import { siteFieldNamings, tariffFieldNamings } from "./namings";

export const validateDay = (value: Tariff[]) => {
  let message: string = "";

  value.forEach((tariff: Tariff, index: number) => {
    const tariffStartTime = tariff.electricity_tariff_start_daily_ptu;
    for (let i = 0; i < index; i++) {
      if (
        Number(tariffStartTime) <
        Number(value[i].electricity_tariff_end_daily_ptu)
      ) {
        message = "Tariffs are overlapping or in wrong order.";
      }
    }
  });

  return message;
};

export const validateFile = (file: File) => {
  if (file.size > 1e6) {
    return `${siteFieldNamings.electricity_use_kwh_csv_file.description} file size should be less than 1Mb.`;
  }
  if (getFileExtension(file.name) !== "csv") {
    return `${siteFieldNamings.electricity_use_kwh_csv_file.description} file extension should be .csv`;
  }
  if (file.name.length > 50) {
    return `${siteFieldNamings.electricity_use_kwh_csv_file.description} file name cannot exceed 50 characters`;
  }

  return "";
};

export const validateTariffPencePerKwh = (value: number) => {
  if (value < 0.1) {
    return `${tariffFieldNamings.electricity_tariff_pence_per_kwh.description} should be higher than 0.`;
  }
  return "";
};

export const validateTariffEndTime = (values: FormValues) => {
  const startTimeAccessor =
    tariffFieldNamings.electricity_tariff_start_daily_ptu.accessor;
  const endTimeAccessor =
    tariffFieldNamings.electricity_tariff_end_daily_ptu.accessor;

  if (
    values[startTimeAccessor] &&
    values[endTimeAccessor] &&
    Number(values[startTimeAccessor]) >= Number(values[endTimeAccessor])
  ) {
    return `${tariffFieldNamings.electricity_tariff_end_daily_ptu.description} should be later than the ${tariffFieldNamings.electricity_tariff_start_daily_ptu.description}.`;
  }
  return "";
};

const getFileExtension = (filename: string) => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};
