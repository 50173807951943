import { Actions } from "../type";
import { ActionTypes } from "./action";

export type AccountState = {
  isRequesting: boolean;
  isAccountDeleted: boolean;
};

const initialState: Readonly<AccountState> = {
  isRequesting: false,
  isAccountDeleted: false,
};

export function reducer(
  state: AccountState = initialState,
  action: Actions
): AccountState {
  switch (action.type) {
    case ActionTypes.DELETE_ACCOUNT_REQUEST: {
      return {
        ...state,
        isRequesting: true,
      };
    }
    case ActionTypes.DELETE_ACCOUNT_FAILURE: {
      return {
        ...state,
        isRequesting: false,
      };
    }
    case ActionTypes.DELETE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        isAccountDeleted: true,
      };
    }
    default:
      return state;
  }
}
