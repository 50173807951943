import { Site, Tariff, TariffSchedule } from "../../../../store/sites/type";
import { FieldNamings } from "../../../types";

export const SITE_TITLE: string = "Site";

export const siteFieldNamings: FieldNamings<Site> = {
  id: {
    accessor: "id",
    description: "ID",
  },
  name: {
    accessor: "name",
    description: "Site name",
    label: "Name",
  },
  location: {
    accessor: "location",
    description: "Location",
  },
  asc_kva: {
    accessor: "asc_kva",
    description: "Available Supply Capacity of the Site (kVA)",
  },
  electricity_use_kwh_csv_file: {
    accessor: "electricity_use_kwh_csv_file",
    description: "Electricity use in half-hour increments",
  },
  electricity_use_kwh_csv_file_name: {
    accessor: "electricity_use_kwh_csv_file_name",
    description: "Electricity use in half-hour increments",
  },
  electricity_tariff_pence_per_kwh: {
    accessor: "electricity_tariff_pence_per_kwh",
    description: "Base/Fixed electricity Tariff (p/kWh)",
  },
  electricity_tariff_pence_per_kwh_variable: {
    accessor: "electricity_tariff_pence_per_kwh_variable",
    description: "ToU Electricity Tariff (p/kWh)",
    subtitle: "To copy the same time-of-use tariff to all days, complete the times and tariff for Monday and then press the copy (❐) button",
  },
  capex_cost_gbp: {
    accessor: "capex_cost_gbp",
    description: "CAPEX (£/site)",
  },
  opex_cost_gbp_per_month: {
    accessor: "opex_cost_gbp_per_month",
    description: "OPEX (£/site/month)",
  },
};

export const tariffScheduleFieldNamings: FieldNamings<TariffSchedule> = {
  mon: {
    accessor: "mon",
    description: "Monday",
  },
  tue: {
    accessor: "tue",
    description: "Tuesday",
  },
  wed: {
    accessor: "wed",
    description: "Wednesday",
  },
  thu: {
    accessor: "thu",
    description: "Thursday",
  },
  fri: {
    accessor: "fri",
    description: "Friday",
  },
  sat: {
    accessor: "sat",
    description: "Saturday",
  },
  sun: {
    accessor: "sun",
    description: "Sunday",
  },
};

export const tariffFieldNamings: FieldNamings<Tariff> = {
  electricity_tariff_start_daily_ptu: {
    accessor: "electricity_tariff_start_daily_ptu",
    description: "Tariff Start Time",
  },
  electricity_tariff_end_daily_ptu: {
    accessor: "electricity_tariff_end_daily_ptu",
    description: "Tariff End Time",
  },
  electricity_tariff_pence_per_kwh: {
    accessor: "electricity_tariff_pence_per_kwh",
    description: "Tariff (pence/kWh)",
  },
};
