import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { StyledMultiSelect, StyledMenuItem } from "../styled/inputs";
import { Checkbox, Input, ListItemText } from "@material-ui/core";
//Types
import { StoreValues } from "../forms/assets/types";
import clsx from "clsx";
import { ListValueProp } from "@hitachivantara/uikit-react-core";
import { dropdownMultiMenuProps, dropdownMultiStyles } from "./assets/styles";

interface Props {
  name: string;
  onChange: (value: Array<string>) => void;
  initialValue?: Array<string>;
  invalid?: boolean;
  loadValues: StoreValues;
  label?: string;
  readOnly?: boolean;
  affectedFields?: Array<string>;
}

export const DropdownMultiAsync: React.FC<Props> = ({
  onChange,
  initialValue,
  invalid,
  loadValues,
  readOnly,
}) => {
  const classes = dropdownMultiStyles();

  const { selector, action, format } = loadValues;
  const dispatch = useDispatch();
  const [listValues, setListValues] = useState<ListValueProp[]>([]);
  const [selectedValues, setSelectedValues] = useState<Array<string>>([]);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

  const { data } = useSelector(selector);

  useEffect(() => {
    if ((!data || data.length === 0) && !isDataFetched) {
      dispatch(action());
      setIsDataFetched(true);
    }
  }, [data, isDataFetched, dispatch, action]);

  useEffect(() => {
    if (data && data.length > 0) {
      setListValues(format(data));
    }
  }, [data, format]);

  const handleSelectedValues = useCallback(
    (newSelectedValues: Array<string>) => {
      const newSelectedValuesWithoutAll = newSelectedValues.filter(
        (value) => value !== "all"
      );

      if (newSelectedValuesWithoutAll.length === listValues.length) {
        return ["all", ...listValues.map((listValue) => listValue.id ?? "")];
      }

      return newSelectedValuesWithoutAll;
    },
    [listValues]
  );

  useEffect(() => {
    if (initialValue && listValues && listValues.length > 0) {
      setSelectedValues(
        handleSelectedValues(initialValue).map((val) => String(val))
      );
    }
  }, [listValues, initialValue, handleSelectedValues]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>, child: any) => {
      const newSelectedValues = event.target.value as Array<string>;
      const selectedValue = child?.props?.value;
      let finalSelectedValues: Array<string> = [];

      if (selectedValue !== "all") {
        finalSelectedValues = handleSelectedValues(newSelectedValues);
      } else if (newSelectedValues.find((value) => value === "all")) {
        finalSelectedValues = [
          "all",
          ...listValues.map((listValue) => listValue.id ?? ""),
        ];
      }

      setSelectedValues(finalSelectedValues);
      onChange(finalSelectedValues.filter((value) => value !== "all"));
    },
    [listValues, onChange, handleSelectedValues]
  );

  return (
    <>
      <StyledMultiSelect
        multiple
        className={clsx(classes.multiSelect, invalid ? "invalid" : "")}
        value={selectedValues}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => {
          const selectedArray = selected as string[];

          if (selectedArray.find((value) => value === "all")) {
            return "All";
          }

          return selectedArray
            .map(
              (selectedValue) =>
                listValues.find((listValue) => selectedValue === listValue.id)
                  ?.label
            )
            .join(", ");
        }}
        MenuProps={{
          ...dropdownMultiMenuProps,
          PaperProps: {
            ...dropdownMultiMenuProps.PaperProps,
            className: classes.multiSelectMenu,
          },
        }}
        disabled={!listValues || listValues.length === 0 || readOnly}
      >
        <StyledMenuItem value={"all"}>
          <Checkbox checked={selectedValues.indexOf("all") > -1} />
          <ListItemText primary={"All"} />
        </StyledMenuItem>
        {listValues.map(({ id, label: description }) => (
          <StyledMenuItem key={id} value={id}>
            <Checkbox checked={selectedValues.indexOf(id ?? "") > -1} />
            <ListItemText primary={description} />
          </StyledMenuItem>
        ))}
      </StyledMultiSelect>
    </>
  );
};
