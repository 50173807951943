import { NavigationItemProp } from "@hitachivantara/uikit-react-core";
import { Route } from "../../../routing/types";
import { Tab } from "../../../components/layout/types/types";

export const routesToNavigationData = (routes: Route[]): NavigationItemProp[] =>
  routes.map(({ hidden, id, label, icon }) => ({
    hidden,
    id,
    label,
    icon,
  }));

export const routesToTabs = (routes: Route[]): Tab[] =>
  routes.map(({ id, label }) => ({
    id,
    label,
  }));
