import { makeStyles } from "@material-ui/core";

export const resultsStyle = makeStyles(() => ({
  backToScenarios: {
    '@media print': {
      display: 'none',
    }, 
    width: "auto",
  },
  backToScenariosInfo: {
    '@media print': {
      display: 'none',
    }, 
    width: "auto",
    color: "#e13000",
    "&:hover": {
      color: "#f76100",
      backgroundColor: "transparent", 
    },
  },
  chartContainer: {
    backgroundColor: "#FFFFFF",
    '@media print': {
      marginRight: "20px",
    }, 
  },
  chartPrint: {
    '@media screen': {
      display: "none",
    }, 
  },
  chartScreen: {
    maxWidth: "1700px",
    '@media print': {
      display: "none",
    }, 
  },
  chart: {
    minHeight: "500px",
    '@media print': {
      marginRight: "20px",
    }, 
  },
  infoList: {
    "& li": {
      marginBottom: "15px",
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  warning: {
    display: "flex",
    '@media print': {
      display: 'none',
    }, 
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "30px"
  },
}));
