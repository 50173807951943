import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../store/notifications";
import { CRUDState, DispatchAction, ReduxSelector } from "../store/type";

export const useErrorModal = <T>(
  selector: ReduxSelector<CRUDState<T>>,
  unsetError?: () => DispatchAction
) => {
  const dispatch = useDispatch();
  const { error } = useSelector(selector);

  useEffect(() => {
    if (error) {
      dispatch(setError(error.message));
      unsetError && dispatch(unsetError());
    }
  }, [error, dispatch, unsetError]);
};
