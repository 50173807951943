import React, { FC, useState, useEffect } from "react";
import MsalContext from "./MsalContext";
import * as msal from "@azure/msal-browser";
import { b2cPolicies, msalInstance } from "./assets/msalConfig";

import {
  MsalProps,
  MsalProviderPopupConfig,
  MsalProviderRedirectConfig,
  MsalMinimalSilentRequestConfig,
  IdTokenClaims,
} from "./types/types";

const MsalProvider: FC<MsalProps> = (props: MsalProps): JSX.Element => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [name, setName] = useState<string | null>();

  const usePopup = props.config.type === "popup";
  let currentHomeAccountId: string | null | undefined = null;

  const authTokenPopup = async (
    silentRequest: msal.SilentRequest,
    loginRequestConfig?: msal.AuthorizationUrlRequest
  ): Promise<string | undefined> => {
    var authResult: msal.AuthenticationResult;
    try {
      authResult = await msalInstance.acquireTokenSilent(silentRequest);
      return authResult.accessToken;
    } catch (err) {
      if (err instanceof msal.InteractionRequiredAuthError) {
        // should log in
        if (loginRequestConfig) {
          authResult = await msalInstance.acquireTokenPopup(loginRequestConfig);
          setIsLoggedIn(true);
          return authResult.accessToken;
        }
      }
      return undefined;
    }
  };

  const authTokenRedirect = async (
    silentRequest: msal.SilentRequest,
    redirectRequestConfig?: msal.RedirectRequest | undefined
  ): Promise<string | undefined> => {
    try {
      var authResult = await msalInstance.acquireTokenSilent(silentRequest);
      currentHomeAccountId = authResult.account?.homeAccountId;
      setIsLoggedIn(true);
      return authResult.accessToken;
    } catch (err) {
      if (err instanceof msal.InteractionRequiredAuthError) {
        // should log in
        setIsLoggedIn(false);
        if (redirectRequestConfig) {
          await msalInstance.acquireTokenRedirect(redirectRequestConfig);
        }
      }
      return undefined;
    }
  };

  const editProfile = async () => {
    msalInstance.loginRedirect(b2cPolicies.authorities.editProfile);
  };

  const getAccount = (
    providedHomeAccountId?: string | null | undefined
  ): msal.AccountInfo | undefined => {
    let usedHomeAccountId = providedHomeAccountId ?? currentHomeAccountId;
    if (!usedHomeAccountId) return undefined;
    return msalInstance.getAccountByHomeId(usedHomeAccountId) ?? undefined;
  };

  const getAuthToken = async (providedHomeAccountId?: string) => {
    var fullSilentRequestConfig = getFullSilentRequestConfig(
      props.config.silentRequestConfig,
      providedHomeAccountId
    );
    if (!fullSilentRequestConfig) {
      setIsLoggedIn(false);
      return;
    }
    if (usePopup) {
      var popupConfig = props.config as MsalProviderPopupConfig;
      return await authTokenPopup(
        fullSilentRequestConfig,
        popupConfig.loginRequestConfig
      );
    } else {
      var redirectConfig = props.config as MsalProviderRedirectConfig;
      return await authTokenRedirect(
        fullSilentRequestConfig,
        redirectConfig?.redirectRequestConfig
      );
    }
  };

  const getFullSilentRequestConfig = (
    silentRequestConfig: MsalMinimalSilentRequestConfig,
    providedHomeAccountId?: string
  ): msal.SilentRequest | undefined => {
    let account = getAccount(providedHomeAccountId) ?? ({} as msal.AccountInfo);
    if (typeof account === "undefined") return undefined;
    return {
      account,
      ...silentRequestConfig,
    } as msal.SilentRequest;
  };

  const login = async () => {
    if (usePopup) {
      let popupConfig = props.config as MsalProviderPopupConfig;
      await loginPopup(popupConfig.loginRequestConfig);
    } else {
      let redirectConfig = props.config as MsalProviderRedirectConfig;
      await loginRedirect(redirectConfig?.redirectRequestConfig);
    }
  };

  const loginPopup = async (
    loginRequestConfig?: msal.AuthorizationUrlRequest
  ) => {
    try {
      const loginResponse = await msalInstance.loginPopup(loginRequestConfig);
      currentHomeAccountId = loginResponse.account?.homeAccountId;
      selectAccount();
    } catch (err) {
      setIsLoggedIn(false);
    }
  };

  const loginRedirect = async (
    redirectRequestConfig?: msal.RedirectRequest | undefined
  ) => {
    try {
      await msalInstance.loginRedirect(redirectRequestConfig);
    } catch (err) {
      console.log(err);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    if (props.config.endSessionRequestConfig) {
      props.config.endSessionRequestConfig.account = getAccount();
    }
    await msalInstance.logoutRedirect(props.config.endSessionRequestConfig);
    setIsLoggedIn(false);
  };

  const selectAccount = () => {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const currentAccounts = msalInstance.getAllAccounts();
    let idTokenClaims: IdTokenClaims | null = null;

    if (!currentAccounts || currentAccounts.length < 1) {
      setIsLoading(false);
    } else if (currentAccounts.length > 1) {
      currentAccounts.forEach((account) => {
        idTokenClaims = account.idTokenClaims as IdTokenClaims;

        if (idTokenClaims.tfp === b2cPolicies.names.signUpSignIn) {
          currentHomeAccountId = account.homeAccountId;
          msalInstance.setActiveAccount(account);
          setIsLoggedIn(true);
        }

        if (idTokenClaims.tfp === b2cPolicies.names.editProfile) {
          setName(account.name);
        }
      });
      setIsLoading(false);
    } else if (currentAccounts.length === 1) {
      idTokenClaims = currentAccounts[0].idTokenClaims as IdTokenClaims;
      currentHomeAccountId = currentAccounts[0].homeAccountId;
      setName(currentAccounts[0].name);
      if (idTokenClaims.tfp === b2cPolicies.names.signUpSignIn) {
        msalInstance.setActiveAccount(currentAccounts[0]);
        setIsLoggedIn(true);
        setIsLoading(false);
      }
    }
  };

  const handleRedirectError = (error: object) => {
    let forgotPasswordError = "AADB2C90118";
    if (error.toString().indexOf(forgotPasswordError) > -1) {
      msalInstance.loginRedirect(b2cPolicies.authorities.forgotPassword);
      return;
    }
  };

  const handleRedirectResult = (
    authResult: msal.AuthenticationResult | null
  ) => {
    /**
     * We need to reject id tokens that were not issued with the default sign-in policy.
     * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
     * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
     */

    if (authResult !== null) {
      const idTokenClaims = authResult?.idTokenClaims as IdTokenClaims;

      if (idTokenClaims.tfp === b2cPolicies.names.editProfile) {
        console.log("Profile has been updated successfully.");
        if (msalInstance.getAllAccounts()) {
          setIsLoggedIn(true);
        }
      } else if (idTokenClaims.tfp === b2cPolicies.names.forgotPassword) {
        console.log(
          "Password has been reset successfully. \nPlease sign-in with your new password."
        );
        logout();
      } else {
        setIsLoggedIn(true);
      }
      return;
    }
    selectAccount();
  };

  useEffect(() => {
    msalInstance
      .handleRedirectPromise()
      .then(handleRedirectResult)
      .catch(handleRedirectError)
      .then(selectAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MsalContext.Provider
      value={{
        editProfile: () => editProfile(),
        getAuthToken: () => getAuthToken(),
        isLoggedIn: isLoggedIn,
        isLoading: isLoading,
        logout: () => logout(),
        login: () => login(),
        name: name,
      }}
    >
      {props.children}
    </MsalContext.Provider>
  );
};

export default MsalProvider;
