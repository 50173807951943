import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { Form } from "../../../components/forms/Form";
//Redux
import {
  cloneSitePeriods,
  fetchSite,
  getSites,
  unsetSiteError,
  updateSite,
} from "../../../store/sites";
//Others
import { MainComponentProps } from "../../Layout/types/types";
import { detailFields } from "./assets/fields";
import { FormValues, FormValuesLine } from "../../../components/forms/assets/types";
import { Site } from "../../../store/sites/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { SITE_TITLE } from "./assets/namings";
import { SiteDescription } from "./assets/Tooltips";

interface UrlParameters {
  id: string;
}

export const SitesDetail: React.FC<MainComponentProps> = () => {
  const { id } = useParams<UrlParameters>();
  const dispatch = useDispatch();
  const { navigateToTable } = useNavigation();
  useErrorModal(getSites, unsetSiteError);

  const [isUpdating, setIsUpdating] = useState(false);

  const { isRequesting, detail, error } = useSelector(getSites);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const site = values as Site;
      dispatch(updateSite(site, site.id));
      setIsUpdating(true);
    },
    [dispatch]
  );

  const handleOnClone = useCallback((formValues: FormValues) => {
    const detail = formValues["cloneDetail"] as Array<FormValuesLine>;
    const siteTariff = detail.map((formLine) => ({
      electricity_tariff_start_daily_ptu: formLine.electricity_tariff_start_daily_ptu,
      electricity_tariff_end_daily_ptu: formLine.electricity_tariff_end_daily_ptu,
      electricity_tariff_pence_per_kwh: formLine.electricity_tariff_pence_per_kwh
    }));

    const siteDetail = formValues as Site;
    if (siteDetail.electricity_tariff_pence_per_kwh_variable) {
      siteDetail.electricity_tariff_pence_per_kwh_variable.mon = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.tue = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.wed = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.thu = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.fri = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.sat = siteTariff;
      siteDetail.electricity_tariff_pence_per_kwh_variable.sun = siteTariff;
    };

    dispatch(cloneSitePeriods(siteDetail));
  }, []);

  useEffect(() => {
    dispatch(fetchSite(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (isRequesting) {
      return;
    }

    if ((isUpdating && !error) || error?.code === 404) {
      navigateToTable();
    } else if (isUpdating && error) {
      setIsUpdating(false);
    }
  }, [isRequesting, isUpdating, error, setIsUpdating, navigateToTable]);
  return (
    <WidgetContainer>
      <Form
        isLoading={isRequesting}
        title={SITE_TITLE}
        description={<SiteDescription />}
        fields={detailFields}
        initialValues={detail}
        formErrors={error?.formErrors}
        onSubmit={handleSubmit}
        onCancel={navigateToTable}
        onClone={handleOnClone}
      />
    </WidgetContainer>
  );
};
