import { Actions, CRUDState } from "../type";

import { ActionTypes } from "./action";
import { ChargePointType } from "./type";

export type ChargePointTypeState = CRUDState<ChargePointType>;

const initialState: Readonly<ChargePointTypeState> = {
    data: [],
    isRequesting: false,
    detail: null,
    deleteId: null,
    error: null
};

export function reducer(
    state: ChargePointTypeState = initialState,
    action: Actions
): ChargePointTypeState {
    switch (action.type) {
        case ActionTypes.FETCH_CHARGE_POINT_TYPES_REQUEST:
        case ActionTypes.FETCH_CHARGE_POINT_TYPE_REQUEST:
        case ActionTypes.DELETE_CHARGE_POINT_TYPE_REQUEST:
        case ActionTypes.UPDATE_CHARGE_POINT_TYPE_REQUEST:
        case ActionTypes.INSERT_CHARGE_POINT_TYPE_REQUEST:
            {
                return {
                    ...state,
                    isRequesting: true,
                    error: null,
                    deleteId: null
                };
            }
        case ActionTypes.FETCH_CHARGE_POINT_TYPES_FAILURE:
        case ActionTypes.FETCH_CHARGE_POINT_TYPE_FAILURE:
        case ActionTypes.DELETE_CHARGE_POINT_TYPE_FAILURE:
        case ActionTypes.UPDATE_CHARGE_POINT_TYPE_FAILURE:
        case ActionTypes.INSERT_CHARGE_POINT_TYPE_FAILURE:
            {
                const error = action.payload;
                return {
                    ...state,
                    isRequesting: false,
                    error
                };
            }
        case ActionTypes.FETCH_CHARGE_POINT_TYPES_SUCCESS: {
            const data = action.payload;
            return {
                ...state,
                isRequesting: false,
                data: data.sort((a:ChargePointType, b: ChargePointType) => a.id - b.id)
            };
        }
        case ActionTypes.FETCH_CHARGE_POINT_TYPE_SUCCESS:
        case ActionTypes.UPDATE_CHARGE_POINT_TYPE_SUCCESS:
            {
                const detail = action.payload;
                return {
                    ...state,
                    isRequesting: false,
                    detail
                };
            }
        case ActionTypes.DELETE_CHARGE_POINT_TYPE_SUCCESS: {
            const deletedId = action.payload;
            const newData = state.data.filter(({ id }) => id !== deletedId);
            return {
                ...state,
                isRequesting: false,
                data: newData,
                deleteId: null
            };
        }
        case ActionTypes.INSERT_CHARGE_POINT_TYPE_SUCCESS: {
            return {
                ...state,
                isRequesting: false
            };
        }
        case ActionTypes.TRY_TO_DELETE_CHARGE_POINT_TYPE: {
            const deleteId = action.payload;
            return {
                ...state,
                deleteId
            };
        }
        case ActionTypes.UNSET_CHARGE_POINT_TYPE_ERROR: {
            return {
                ...state,
                error: null
            };
        }
        default:
            return state;
    }
}