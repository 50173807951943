import { Actions, CRUDState } from "../type";
import { ActionTypes } from "./action";

export type ResultsState = CRUDState<any>;

const initialState: Readonly<ResultsState> = {
  data: [],
  isRequesting: false,
  detail: null,
  deleteId: null,
  error: null,
};

export function reducer(
  state: ResultsState = initialState,
  action: Actions
): ResultsState {
  switch (action.type) {
    case ActionTypes.FETCH_RESULTS_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        error: null,
        deleteId: null,
      };
    }
    case ActionTypes.FETCH_RESULTS_FAILURE: {
      const error = action.payload;
      return {
        ...state,
        isRequesting: false,
        error,
      };
    }
    case ActionTypes.FETCH_RESULTS_SUCCESS: {
      const detail = action.payload;
      return {
        ...state,
        isRequesting: false,
        detail,
      };
    }
    case ActionTypes.UNSET_RESULTS_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
}
