import { Schedule, Trip } from "../../../../store/schedules/type";
import { convertHoursStringToDate } from "../../../../utils/dates";

export const formatScheduleDescription = (schedule: Schedule) =>
  `${schedule.name}`;

export const formatTripsToTable = (trips: Trip[]) => {
  const tripsDistanceMiles = trips.map((trip) =>
    Number(trip.trip_distance_miles)
  );
  const miles = tripsDistanceMiles.reduce((a, b) => a + b, 0);

  if (trips.length > 0)
    return `${trips.length} Trip${trips.length > 1 ? `s` : ``}, ${miles} miles`;
  return "—";
};

export const formatTripsToDetail = (trips: Trip[]) =>
  trips.map((trip) => ({
    trip_start_time: convertHoursStringToDate(trip.trip_start_time),
    trip_end_time: convertHoursStringToDate(trip.trip_end_time),
    trip_distance_miles: trip.trip_distance_miles,
  }));
