import React from "react";

interface ChargingCostsCellProps {
  original: any;
  value: any;
}

export const ChargingCostsCell: React.FC<ChargingCostsCellProps> = ({
  original,
  value,
}) => {
  return (
    <>
      {original.asset === "Total Cost" ? (
        <strong>{value}</strong>
      ) : (
        <>{value}</>
      )}
    </>
  );
};
