import { FormErrors } from "../components/forms/assets/types";

export class ApiError extends Error {
  __proto__ = Error;

  public code?: number;
  public formErrors?: FormErrors;

  constructor(code?: number, message?: string, formErrors?: FormErrors) {
    super(message);
    this.code = code;
    this.formErrors = formErrors;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
