import React, { useCallback, useEffect, useState } from "react";
//Components
import { ListValueProp } from "@hitachivantara/uikit-react-core";
import { StyledDropdownInput } from "../../../../components/styled/inputs";
//Other
import {
  energyDistributionModelListValues,
  energyDistributionModelOneConnectorListValues,
} from "../assets/dropdowns";

import { FormComponent } from "../../../../components/forms/assets/types";
import { selectValueFromList } from "../../../../utils/dropdowns";

export const EnergyDistributionModelFormComponent: React.FC<FormComponent> = ({
  name,
  onChange,
  initialValue,
  invalid,
  label,
  readOnly,
  formValues,
}) => {
  const [dropdownState, setDropdownState] = useState<{
    key: string | undefined;
    listValues: ListValueProp[];
    disabled?: boolean;
  }>({ key: undefined, listValues: [], disabled: false });

  const evseMaxEvents = formValues["evse_max_events"];

  useEffect(() => {
    if (String(evseMaxEvents) !== "1") {
      setDropdownState({
        key: "full-list",
        listValues: selectValueFromList(
          [...energyDistributionModelListValues],
          initialValue
        ),
      });
      onChange(name, initialValue);
    } else {
      setDropdownState({
        key: "one-connector-list",
        listValues: selectValueFromList(
          [...energyDistributionModelOneConnectorListValues],
          "2"
        ),
        disabled: true,
      });
      onChange(name, "2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evseMaxEvents, initialValue, name]);

  const handleChange = useCallback(
    (selected: ListValueProp | ListValueProp[] | undefined) => {
      if (selected && !Array.isArray(selected)) {
        onChange(name, selected.id ?? "0");
      } else {
        onChange(name, "0");
      }
    },
    [name, onChange]
  );

  return (
    <>
      {dropdownState.listValues && dropdownState.listValues.length > 0 && (
        <StyledDropdownInput
          key={dropdownState.key}
          id={label}
          className={invalid ? "invalid" : ""}
          values={dropdownState.listValues}
          hasTooltips
          onChange={handleChange}
          disabled={readOnly || dropdownState.disabled}
        />
      )}
      {(!dropdownState.listValues || dropdownState.listValues.length === 0) && (
        <StyledDropdownInput id={label} disabled={true} />
      )}
    </>
  );
};
