import * as React from "react";
import { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router";
//Components
import { StyledNavigationLeft, StyledNavigationRight } from "../../components/styled/layout";
import HvVerticalNavigation, {
  NavigationData,
} from "@hitachivantara/uikit-react-core/dist/VerticalNavigation";
//Images
import Logo from "../../assets/images/logo.png";
//Styles
import { sideMenuStyles } from "./styles";
//Other
import { routesToNavigationData } from "./types/mapping";
import { Route } from "../../routing/types";
import { UserActions } from "../../components/layout/UserActions";

interface Props {
  routes: Route[];
}

export const SideMenu: React.FC<Props> = ({ routes }) => {
  const classes = sideMenuStyles();
  const history = useHistory();

  const [value, setValue] = useState(
    () =>
      routes.find(
        (x) => x.path.split("/")[1] === history.location.pathname.split("/")[1]
      )?.id || "0"
  );

  const navigationDataLeft = useMemo(
    () => routesToNavigationData(routes.filter((x) => !x.hidden && x.align == "left")),
    [routes]
  );
  const navigationDataRight = useMemo(
    () => routesToNavigationData(routes.filter((x) => !x.hidden && x.align == "right")),
    [routes]
  );
  const handleClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    selectedItem: NavigationData
  ) => {
    const path = routes.find((x) => x.id === selectedItem.id)?.path;

    if (!path) {
      return;
    }

    history.push(path);
    setValue(selectedItem.id);
  };

  useEffect(() => {
    const value =
      routes.find(
        (x) => x.path.split("/")[1] === history.location.pathname.split("/")[1]
      )?.id || "0";

    setValue(value);
  }, [routes, history.location.pathname]);

  return (
    <div className={classes.fixedSideBar}>
      <div className={classes.alignMenuBar}>
        <img className={classes.logo} src={Logo} alt="Optimise Prime" />
        <UserActions />
        <StyledNavigationRight style={{float: "right", position:"relative", marginRight: "7px", marginTop: "2px"}}
            selected={value}
            onClick={handleClick}
            data={navigationDataRight}
          />
        <HvVerticalNavigation isCollapsable={false} className={classes.menuItens}>
          <StyledNavigationLeft
            selected={value}
            onClick={handleClick}
            data={navigationDataLeft}
          />
        </HvVerticalNavigation>
      </div>
    </div>
  );
};
