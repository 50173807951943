import { CostsGbp } from "../../../../store/results/type";
import { FieldNamings } from "../../../types";

export const SCENARIO_RESULTS_TITLE: string = "Scenario Results";

export const costsGbpNamings: FieldNamings<CostsGbp> = {
  site: {
    accessor: "site",
    description: "Site Costs",
  },
  total: {
    accessor: "total",
    description: "Total Cost",
  },
  chargepoints: {
    accessor: "chargepoints",
    description: "Charge Points Costs",
  },
  electric_vehicles: {
    accessor: "electric_vehicles",
    description: "EV Costs",
  },
};
