import React from "react";

interface Props {}

export const ScenarioDescription: React.FC<Props> = () => (
  <>
The scenario allows you to combine your site, EV, Charge point and schedule assets in order to model the power requirements of a site. 
Complete each of the fields below and press confirm to create your scenario, you can then calculate your result from the scenario list. 
Multiple scenarios can be created to compare different asset combinations. 
    
    Fields marked in{" "} <strong>bold</strong> are required.
  </>
);

export const ScenarioNameTooltip: string =
  "Choose a unique name to identify this scenario.";

export const ScenarioSiteTooltip: string =
  "Select the site you wish to model from the drop-down list.";

export const ScenarioEnergyCostModelTooltip: string =
  "If you have entered a time of use tariff for your site, you can select whether to model based on a fixed or time of use tariff.";

export const ScenarioChargePointTypesTooltip: string =
  "Press the '+' button to add each type of charge point you plan to install at this site, enter the number of each charge point alongside.";

export const ScenarioElectricVehiclesTypesTooltip: string =
  "Press the '+' button to add each type of EV you plan to operate from this site and associate it with a schedule. Each EV model can be associated with multiple schedules. Alongside each EV/schedule combination enter the number of EVs.";
