import { Schedule, Trip } from "../../../../store/schedules/type";
import { FieldNamings } from "../../../types";

export const SCHEDULE_TITLE: string = "Schedule";

export const schedulesFieldNamings: FieldNamings<Schedule> = {
  id: {
    accessor: "id",
    description: "ID",
  },
  name: {
    accessor: "name",
    description: "Name",
  },
  mon: {
    accessor: "mon",
    description: "Monday",
  },
  tue: {
    accessor: "tue",
    description: "Tuesday",
  },
  wed: {
    accessor: "wed",
    description: "Wednesday",
  },
  thu: {
    accessor: "thu",
    description: "Thursday",
  },
  fri: {
    accessor: "fri",
    description: "Friday",
  },
  sat: {
    accessor: "sat",
    description: "Saturday",
  },
  sun: {
    accessor: "sun",
    description: "Sunday",
  },
};

export const tripsFieldNamings: FieldNamings<Trip> = {
  trip_start_time: {
    accessor: "trip_start_time",
    description: "Trip Start Time",
  },
  trip_end_time: {
    accessor: "trip_end_time",
    description: "Trip End Time",
  },
  trip_distance_miles: {
    accessor: "trip_distance_miles",
    description: "Trip Distance Miles",
  },
};
