import React, { ReactNode, useCallback, useEffect, useState } from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import {
  FormChip,
  FormDropdownAsync,
  FormDropdownMultiAsync,
  FormDropdown,
  FormFileUpload,
  FormNumeric,
  FormText,
  FormTextCustomInput,
  FormRadio,
} from "./fields";
import { FormErrors, FormField, FormValues, FormValuesLine } from "./assets/types";
import { FormMultiline } from "./FormMultiline";
import { FormTooltip } from "./FormTooltip";
//Styles
import { formStyles } from "./assets/styles";

interface Props {
  fields: FormField[];
  initialValues?: FormValues;
  values?: FormValues;
  errors?: FormErrors;
  onChange: (
    name: string,
    value: string | Array<string> | File,
    affectedFields?: Array<string>
  ) => void;
  onClone?: (lines: Array<FormValuesLine>) => any | null; 
}

export const FormFields: React.FC<Props> = ({
  fields,
  initialValues = {},
  values = {},
  errors = {},
  onChange,
  onClone,
}) => {
  const classes = formStyles();

  return (
    <HvGrid container className={classes.fieldsContainer}>
      {fields &&
        fields.map(
          (
            {
              id,
              label,
              accessor,
              readAccessor,
              readOnly,
              required,
              maxCharacters,
              maxLines,
              inputType,
              dataType,
              listValues,
              loadValues,
              placeholder,
              affectedFields,
              customInput,
              CustomComponent,
              multiLineFields,
              multiFields,
              tooltipContent,
              getDefaultValue,
              boldLabel,
              cloneOption,
              tooltipCloneContent,
              subtitle,
            },
            index
          ) => (
            <React.Fragment key={`${index}${initialValues[accessor] ?? ""}`}>
              {inputType === "multiline" && multiLineFields ? (
                <>
                  <HvGrid item xs={3} className={classes.fieldLabelTop}>
                    <HvTypography
                      variant={required || boldLabel ? "highlightText" : "normalText"}
                    >
                      {label}
                    </HvTypography>
                    {tooltipContent && (
                      <FormTooltip
                        accessor={accessor}
                        content={tooltipContent}
                      />
                    )}
                    {subtitle && (
                      {subtitle}
                    )}
                  </HvGrid>
                  <HvGrid item xs={9}>
                    <FormMultiline
                      fields={multiLineFields}
                      name={accessor}
                      onChange={onChange}
                      errors={errors[accessor] as FormErrors[]}
                      getDefaultValue={getDefaultValue}
                      initialValues={initialValues[accessor]}
                      values={values}
                      maxLines={maxLines}
                      cloneOption={cloneOption}
                      onClone={onClone}
                      tooltipCloneContent={tooltipCloneContent}
                    />
                  </HvGrid>
                </>
              ) : inputType === "customComponent" &&
                multiFields &&
                CustomComponent ? (
                <>
                  <HvGrid item xs={12} className={classes.fieldLabelTop}>
                    <HvTypography
                      variant={required || boldLabel ? "highlightText" : "normalText"}
                    >
                      {label}
                    </HvTypography>
                    {tooltipContent && (
                      <FormTooltip
                        accessor={accessor}
                        content={tooltipContent}
                      />
                    )}
                    </HvGrid>
                    {subtitle && (
                      <HvGrid  item xs={12}>
                      <HvTypography
                        variant={required || boldLabel ? "highlightText" : "normalText"}
                      >
                      {subtitle}
                      </HvTypography>
                      </HvGrid>
                    )}
                  <HvGrid item xs={12}>
                    <CustomComponent
                      name={accessor}
                      readOnly={readOnly}
                      initialValue={initialValues[accessor]}
                      onChange={onChange}
                      onClone={onClone}
                      invalid={!!errors[accessor]}
                      label={label}
                      formValues={values}
                      loadValues={loadValues}
                      fields={multiFields}
                      errors={errors[accessor] as FormErrors[]}
                    />
                  </HvGrid>
                </>
              ) : (
                <>
                  <HvGrid item xs={3} className={classes.fieldLabel}>
                    <HvTypography
                      variant={required || boldLabel ? "highlightText" : "normalText"}
                    >
                      {label}
                    </HvTypography>
                    {tooltipContent && (
                      <FormTooltip
                        accessor={accessor}
                        content={tooltipContent}
                      />
                    )}
                  </HvGrid>
                  <HvGrid item xs={9} className={classes.fieldValue}>
                    {id ? (
                      <HvTypography
                        variant={"normalText"}
                        className={classes.readOnly}
                      >
                        {initialValues[accessor] ?? ""}
                      </HvTypography>
                    ) : inputType === "list" && loadValues ? (
                      <FormDropdownAsync
                        name={accessor}
                        readOnly={readOnly}
                        initialValue={initialValues[accessor]}
                        onChange={onChange}
                        invalid={!!errors[accessor]}
                        loadValues={loadValues}
                        affectedFields={affectedFields}
                      />
                    ) : inputType === "list" ? (
                      <FormDropdown
                        name={accessor}
                        readOnly={readOnly}
                        values={listValues ?? []}
                        initialValue={initialValues[accessor]}
                        onChange={onChange}
                        invalid={!!errors[accessor]}
                      />
                    ) : inputType === "multilist" && loadValues ? (
                      <FormDropdownMultiAsync
                        name={accessor}
                        readOnly={readOnly}
                        initialValue={initialValues[accessor]}
                        onChange={onChange}
                        invalid={!!errors[accessor]}
                        loadValues={loadValues}
                        label={label}
                        affectedFields={affectedFields}
                      />
                    ) : inputType === "radio" ? (
                      <FormRadio
                        name={accessor}
                        values={listValues ?? []}
                        initialValue={initialValues[accessor]}
                        onChange={onChange}
                        invalid={!!errors[accessor]}
                        readOnly={readOnly}
                      />
                    ) : inputType === "number" ? (
                      <FormNumeric
                        name={accessor}
                        readOnly={readOnly}
                        initialValue={String(initialValues[accessor] ?? "")}
                        onBlur={readOnly ? undefined : onChange}
                        invalid={!!errors[accessor]}
                        integer={dataType === "integer"}
                        inputProps={{ id: label, maxLength: maxCharacters }}
                      />
                    ) : inputType === "customInput" ? (
                      <FormTextCustomInput
                        name={accessor}
                        readOnly={readOnly}
                        initialValue={String(initialValues[accessor] ?? "")}
                        onBlur={readOnly ? undefined : onChange}
                        invalid={!!errors[accessor]}
                        inputProps={{ id: label, maxLength: maxCharacters }}
                        customInput={customInput}
                        placeholder={placeholder}
                      />
                    ) : inputType === "chip" ? (
                      <FormChip
                        name={accessor}
                        readOnly={readOnly}
                        initialValue={initialValues[accessor]}
                        onChange={onChange}
                        invalid={!!errors[accessor]}
                        label={label}
                      />
                    ) : inputType === "customComponent" ? (
                      CustomComponent && (
                        <CustomComponent
                          name={accessor}
                          readOnly={readOnly}
                          initialValue={initialValues[accessor]}
                          onChange={onChange}
                          invalid={!!errors[accessor]}
                          label={label}
                          formValues={values}
                          fields={multiLineFields}
                        />
                      )
                    ) : inputType === "file" ? (
                      <FormFileUpload
                        name={accessor}
                        readOnly={readOnly}
                        initialValue={
                          readAccessor
                            ? initialValues[readAccessor]
                            : initialValues[accessor]
                        }
                        onChange={onChange}
                        invalid={!!errors[accessor]}
                      />
                    ) : (
                      <FormText
                        name={accessor}
                        readOnly={readOnly}
                        initialValue={String(initialValues[accessor] ?? "")}
                        onBlur={readOnly ? undefined : onChange}
                        invalid={!!errors[accessor]}
                        inputProps={{ id: label, maxLength: maxCharacters }}
                      />
                    )}
                  </HvGrid>
                  {!!errors[accessor] && (
                    <>
                      <HvGrid item xs={3} />
                      <HvGrid item xs={9}>
                        <HvTypography className={classes.fieldError}>
                          {errors[accessor]}
                        </HvTypography>
                      </HvGrid>
                    </>
                  )}
                </>
              )}
            </React.Fragment>
          )
        )}
    </HvGrid>
  );
};
