import { FormValues } from "../../../../components/forms/assets/types";
import { Trip } from "../../../../store/schedules/type";
import { tripsFieldNamings } from "./namings";

export const validateDay = (value: Trip[]) => {
  let message: string = "";
  value.forEach((trip: Trip, index: number) => {
    const tripStartTime = trip.trip_start_time;
    for (let i = 0; i < index; i++) {
      if (tripStartTime < value[i].trip_end_time) {
        message = "Trips are overlapping or in wrong order.";
      }
    }
  });
  return message;
};

export const validateTripEndTime = (values: FormValues) => {
  const startTimeAccessor = tripsFieldNamings.trip_start_time.accessor;
  const endTimeAccessor = tripsFieldNamings.trip_end_time.accessor;

  var date = values[endTimeAccessor];
  const hours = date.getHours();
  const minutes = date.getMinutes();
  if (hours === 0 && minutes === 0) {
    return `It is not possible to have a ${tripsFieldNamings.trip_end_time.description} of midnight.`;
  }

  if (
    values[startTimeAccessor] &&
    values[endTimeAccessor] &&
    values[startTimeAccessor] >= values[endTimeAccessor]
  ) {
    return `${tripsFieldNamings.trip_end_time.description} should be later than the ${tripsFieldNamings.trip_start_time.description}.`;
  }
  return "";
};
