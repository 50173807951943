import { HvTable } from "@hitachivantara/uikit-react-core";
import { withStyles } from "@material-ui/styles";

export const StyledDataGrid = withStyles({
    thead: {
        backgroundColor: "transparent !important",
        borderBottom: "1px solid #C4C4C4 !important",
        borderTop: "0px !important",
    },
    theadTh: {
        backgroundColor: "transparent !important",
        "&.secondaryAction": {
            borderLeft: "none !important"
        },
        "& .color0": {
            fill: "#b7b7b7"
        },
        "&.-sort-desc .color0": {
            fill: "#4D4D4D"
        },
        "&.-sort-desc .color1": {
            fill: "#b7b7b7"
        }
    },
    tbody: {
        backgroundColor: "transparent !important",
        borderBottom: "1px solid #C4C4C4"
    },
    trGroups: {
        borderBottom: "none !important",
    },
    td: {
        borderTop: "0px",
        backgroundColor: "transparent !important",
        "&.secondaryAction": {
            borderLeft: "none !important"
        }
    }
})(HvTable);