import React from "react";

interface Props {}

export const SiteDescription: React.FC<Props> = () => (
  <>
    Use this page to specify the details of a site where you will charge electric vehicles. 
    You can create multiple sites if you are managing several locations. 
    <br/>For help with this section, see our <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-example" target="__blank">example</a>.
    <br/><br/>
    To model a site with solar generation, download the <a href="https://www.ukpowernetworks.co.uk/optimise-prime/modelling-low-carbon-technologies" target="__blank">Solar Power Estimator</a> - for guidance see the <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-guide" target="__blank">help pages</a>.<br/>
    <br/>Fields marked in <strong>bold</strong> are required, the time-of use tariff and CAPEX/OPEX costs are optional.
    <br/><br/>
	  To copy the same time-of use tariff to all days, complete the times and tariff for Monday and then press the copy (❐) button.
  </>
);

export const SiteNameTooltip: string =
  "Choose a name to help you identify this site. Each site must have a different name.";

export const SiteAscKvaTooltip: React.FC<Props> = () => {
  return (
    <>
      The Available Supply Capacity (ASC) is the amount of electricity that you
      can use at any time. This can be found on your connection agreement, or 
      by contacting your Distribution Network Operator. The tool will try to 
      manage charging so that this limit is not exceeded.
      <br />
      <br />
      Some smaller businesses will not have an ASC, in this case enter 23kVA for
      a single phase connection or 69kVA for a 3-phase connection.
    </>
  );
};

export const SiteElectricityUseCsvFileTooltip: React.FC<Props> = () => {
  return (
    <>
      The existing load of your site is required to determine how much
      additional capacity is available for EV charging.
      <br />
      <br />
      Download the Example CSV file and update it with meter data for your site
      in kWh, split by 48 half-hourly periods (numbered PTU1-48 from 0000-0030
      to 2330-2400). Do not change the layout of the file. A minimum of a full
      week of data must be provided, however we recommend that you provide a
      year or more of data. If you provide less than a year of data try an
      ensure that your sample period represents your highest power demand. The
      more data you provide the more accurate the result will be. If you are on
      a half-hourly metered connection, meter data should be available from your
      energy retailer.
      <br />
      <br />
      If you have multiple metered connections at your site these can be added
      to the same file - use the 'MPAN' field to differentiate between the
      meters, entered MPAN numbers must begin with a letter.
    </>
  );
};

export const SiteElectricityTariffTooltip: string =
  "If you are on a fixed price electricity tariff, enter it here. If you have a  'Time of Use' tariff, enter the most frequent price band here and use the next section to detail times when other prices apply. This information is used to estimate the cost of charging your fleet.";

export const SiteTariffScheduleTooltip: string =
  "If you have a time-of-use tariff, use the form below to specify periods when your tariff differs from the base tariff, these should be in half hour periods. If a period crosses midnight, select 24:00 as the end time and continue on the next day.";

export const SiteCapexCostTooltip: string =
  "If you have an estimate of the cost of installing infrastructure at your site to support electrification of your fleet you can enter it here.";

export const SiteOpexCostTooltip: string =
  "If you have an estimate of any monthly costs associated with electrifying this site (such as contracts for charger management) enter it here.";

export const SiteCloneTooltip: string =
  "Duplicate to all days";
