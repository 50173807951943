import { ChargePointType } from "../../../../store/chargePointTypes/type";
import { FieldNamings } from "../../../types";

export const CHARGE_POINT_TYPE_TITLE: string = "Charge Point Type";

export const chargePointTypesFieldNamings: FieldNamings<ChargePointType> = {
  id: {
    accessor: "id",
    description: "ID",
  },
  name: {
    accessor: "name",
    description: "Charge point model name",
    label: "Name",
  },
  evseMaxEvents: {
    accessor: "evse_max_events",
    description: "Number of connectors per charge point",
    label: "Number of connectors",
  },
  chargeSpeedKw: {
    accessor: "charge_speed_kw",
    description: "Maximum charging capacity (kW)",
  },
  energyDistributionModel: {
    accessor: "energy_distribution_model",
    description: "Charging capacity per connector",
  },
  capexCostGbp: {
    accessor: "capex_cost_gbp",
    description: "CAPEX (£/device)",
  },
  opexCostGbpPerMonth: {
    accessor: "opex_cost_gbp_per_month",
    description: "OPEX (£/device/month)",
  },
};
