import React from "react";

interface Props {}

export const ElectricVehicleTypeDescription: React.FC<Props> = () => (
  <>
    Use this page to specify the electric vehicles that you plan to operate from
    your sites. If you're unsure about which electric vehicles you will use, or
    don't have full details, you can find specifications of common models at {" "}
    <a href="https://ev-database.uk/" target="__blank">
    EV Database
    </a> or see an <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-example"
      target="__blank">example</a> of a popular model. 
      Up to five electric vehicle types can be specified, allowing you to compare the impact of different models or fleet combinations. 
    <br/>
    Fields marked in <strong>bold</strong> are required.
  </>
);

export const ElectricVehicleTypeNameTooltip: string =
  "Choose a unique name to identify this electric vehicle.";

export const ElectricVehicleTypeBatteryCapacityTooltip: string =
  "Enter the battery capacity in kWh - details should be found in the vehicle specifications.";

export const ElectricVehicleTypeMaximumChargeTooltip: string =
  "Enter the maximum charging speed in kW, this is also known as the 'charge rate' or 'charge power'. Make sure you are using the 'AC' charge speed, unless you are planning to install rapid DC chargers.";

export const ElectricVehicleTypeRangeMilesTooltip: string =
  "Enter the vehicle range (the distance the EV can travel on a full charge). https://ev-database.uk/ gives several range estimates - use the lowest figure (the cold weather figure) for your expected driving environment to create a worst case scenario that covers use of heating and air conditioning.";

export const ElectricVehicleTypeCapexCostTooltip: string =
  "If you know how much each EV will cost to purchase, enter the details here.";

export const ElectricVehicleTypeOpexCostTooltip: string =
  "If you know how much recurring costs (such as vehicle lease, maintenance, etc.) will be per vehicle per month enter it here.";
