import React, { useEffect } from "react";
import { useMemo, useCallback } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import { Tabs } from "../../../components/layout/Tabs";
import { Route, Switch, useHistory } from "react-router";
import { StyledButton } from "../../../components/styled/inputs";
import { NoData } from "../../../components/datagrid/NoData";
import { Loading } from "../../../components/loading/Loading";
//Others
import { routesToTabs } from "../../Layout/types/mapping";
import { Tab } from "../../../components/layout/types/types";
import { MainComponentProps } from "../../Layout/types/types";
import { resultsStyle } from "./assets/styles";
import { Caution } from "@hitachivantara/uikit-react-icons";
import { ResultStatus } from "../../../store/scenarios/type";
//Redux
import { fetchResults, getResults } from "../../../store/results";

interface UrlParameters {
  id: string;
}

export const ScenariosResultsPage: React.FC<MainComponentProps> = ({
  title,
  routes,
}) => {
  const history = useHistory();
  const { id } = useParams<UrlParameters>();
  const classes = resultsStyle();
  const { detail, isRequesting } = useSelector(getResults);
  const dispatch = useDispatch();

  const getScenarioName = () => {
    if (
      detail &&
      detail.scenario_summary &&
      detail.scenario_summary.scenario_name &&
      detail.id === id
    )
      return ` - ${detail.scenario_summary.scenario_name}`;

    return "";
  };

  const tabs = useMemo(() => routesToTabs(routes || []), [routes]);
  const selectedTab = useMemo(
    () =>
      (routes &&
        routes.find(
          (x) =>
            x.path.split("/")[3] === history.location.pathname.split("/")[3]
        )?.id) ||
      tabs[0]?.id,
    [routes, history, tabs]
  );

  const handleTabChange = useCallback(
    (tab: Tab) => {
      let path = routes && routes.find((x) => x.id === tab.id)?.path;

      if (!path) {
        return;
      }

      if (path.includes(":id")) {
        path = path.replace(":id", id);
      }

      history.push(path);
    },
    [history, routes, id]
  );

  const handleRedirect = () => {
    history.push(`/scenarios/manage`);
  };

  useEffect(() => {
    dispatch(fetchResults(id));
  }, [dispatch, id]);

  return (
    <HvGrid container>
      <HvGrid item xs={12} className={classes.titleContainer}>
        <HvTypography variant={"xlTitle"}>
          {title}
          {getScenarioName()}
        </HvTypography>
        <HvGrid 
          style={{
            justifyContent:"right",
            display: "flex",
            alignItems: "center",}}>
          <HvTypography variant="highlightText" style={{marginRight:"5px"}} className={classes.backToScenarios}>
              <button onClick={() => {window.print()}} style={{height:"30px"}}>
                ⎙ Print page
              </button>
          </HvTypography>
          <StyledButton
            type="button"
            category="secondary"
            className={classes.backToScenarios}
            onClick={handleRedirect}
          >
            Back to Scenarios
          </StyledButton>
        </HvGrid>
      </HvGrid>
      <HvGrid item xs={12}>
        <Loading isLoading={isRequesting}>
          {detail
            ? (() => {
                switch (detail.status as ResultStatus) {
                  case ResultStatus.Failed:
                    return (
                      <HvGrid item xs={12}>
                        <NoData
                          label={`The scenario calculation failed. To retry, return to the scenario list.`}
                        />
                      </HvGrid>
                    );
                  case ResultStatus.Waiting:
                  case ResultStatus.Processing:
                  case ResultStatus.Started:
                    return (
                      <HvGrid item xs={12}>
                        <NoData
                          label={`The scenario result is being processed. This may take a few moments, refresh the page to update the results.`}
                        />
                      </HvGrid>
                    );
                  case ResultStatus.Completed:
                    return (
                      <>
                        <Tabs
                          tabs={tabs}
                          selectedTab={selectedTab}
                          onChange={handleTabChange}
                        />
                        <HvGrid item xs={12} className={classes.warning}>
                          <Caution />
                          <HvTypography variant="highlightText">
                            If you have changed the specifications of your
                            assets or scenarios since running this calculation
                            these details may no longer be correct –{" "}
                          </HvTypography>
                          <StyledButton
                            type="button"
                            category="ghost"
                            className={classes.backToScenariosInfo}
                            onClick={handleRedirect}
                          >
                            return to the scenarios list
                          </StyledButton>{" "}
                          <HvTypography variant="highlightText">
                            to re-calculate reflecting any changes.
                          </HvTypography>
                        </HvGrid>
                        <Switch>
                          {routes &&
                            routes.map(
                              ({ id, path, Component, children }, index) => (
                                <Route key={id} exact={index === 0} path={path}>
                                  <Component routes={children} />
                                </Route>
                              )
                            )}
                        </Switch>
                      </>
                    );
                }
              })()
            : !isRequesting && (
                <HvGrid item xs={12}>
                  <NoData label={"We couldn't find this scenario"} />
                </HvGrid>
              )}
        </Loading>
      </HvGrid>
    </HvGrid>
  );
};
