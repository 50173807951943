import React, { useCallback, useState } from "react";
//Components
import { StyledFormInput } from "../../styled/inputs";
import { InputBaseComponentProps } from "@material-ui/core";

interface Props {
  name: string;
  onBlur?: (name: string, value: string) => void;
  initialValue?: string;
  invalid?: boolean;
  readOnly?: boolean;
  inputProps?: InputBaseComponentProps;
  placeholder?: string;
  customInput?: (value: string) => string;
}

export const FormTextCustomInput: React.FC<Props> = ({
  name,
  onBlur,
  initialValue,
  invalid,
  readOnly,
  inputProps,
  placeholder,
  customInput,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, val: string) => {
      const newValue = customInput ? customInput(val) : val;
      setValue(newValue);
      return newValue;
    },
    [setValue, customInput]
  );

  return (
    <StyledFormInput
      disabled={readOnly}
      onBlur={(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => onBlur && onBlur(name, event?.target?.value)}
      invalid={invalid}
      inputProps={inputProps}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};
