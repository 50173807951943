import { Actions, CRUDState } from "../type";

import { ActionTypes } from "./action";
import { Schedule } from "./type";

export type ScheduleState = CRUDState<Schedule>;

const initialState: Readonly<ScheduleState> = {
  data: [],
  isRequesting: false,
  detail: null,
  deleteId: null,
  error: null,
};

export function reducer(
  state: ScheduleState = initialState,
  action: Actions
): ScheduleState {
  switch (action.type) {
    case ActionTypes.CLEAR_DETAIL_SCHEDULE: {
      return {
        ...state,
        detail: null,
      }
    }
    case ActionTypes.CLONE_SCHEDULES: {
      const detail = action.payload;
      if (state.detail) {
        detail.id = state.detail.id;
      };
      return {
        ...state,
        isRequesting: false,
        detail,
      };
    }
    case ActionTypes.FETCH_SCHEDULES_REQUEST:
    case ActionTypes.FETCH_SCHEDULE_REQUEST:
    case ActionTypes.DELETE_SCHEDULE_REQUEST:
    case ActionTypes.UPDATE_SCHEDULE_REQUEST:
    case ActionTypes.INSERT_SCHEDULE_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        error: null,
        deleteId: null,
      };
    }
    case ActionTypes.FETCH_SCHEDULES_FAILURE:
    case ActionTypes.FETCH_SCHEDULE_FAILURE:
    case ActionTypes.DELETE_SCHEDULE_FAILURE:
    case ActionTypes.UPDATE_SCHEDULE_FAILURE:
    case ActionTypes.INSERT_SCHEDULE_FAILURE: {
      const error = action.payload;
      return {
        ...state,
        isRequesting: false,
        error,
      };
    }
    case ActionTypes.FETCH_SCHEDULES_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        isRequesting: false,
        data: data.sort((a: Schedule, b: Schedule) => a.id - b.id),
      };
    }
    case ActionTypes.FETCH_SCHEDULE_SUCCESS:
    case ActionTypes.UPDATE_SCHEDULE_SUCCESS: {
      const detail = action.payload;
      return {
        ...state,
        isRequesting: false,
        detail,
      };
    }
    case ActionTypes.DELETE_SCHEDULE_SUCCESS: {
      const deletedId = action.payload;
      const newData = state.data.filter(({ id }) => id !== deletedId);
      return {
        ...state,
        isRequesting: false,
        data: newData,
        deleteId: null,
      };
    }
    case ActionTypes.INSERT_SCHEDULE_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
      };
    }
    case ActionTypes.TRY_TO_DELETE_SCHEDULE: {
      const deleteId = action.payload;
      return {
        ...state,
        deleteId,
      };
    }
    case ActionTypes.UNSET_SCHEDULE_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
}
