import * as React from "react";
//Components
import {
  HvButton,
  HvContainer,
  HvGrid,
  HvTypography,
} from "@hitachivantara/uikit-react-core";
import { Paper } from "@material-ui/core";
//Images
import Logo from "../../assets/images/logo_full_colour.png";
import LogoUKPN from "../../assets/images/ukpn-logo-color-transparent.png";
//Styles
import { loginStyles } from "./assets/styles";
//Hooks
import useMsal from "../../components/msal/assets/useMsal";

interface Props {}

export const Login: React.FC<Props> = () => {
  const classes = loginStyles();
  const { login } = useMsal();

  return (
    <HvContainer className={classes.container}>
      <Paper className={classes.paper} variant="outlined">
        <HvGrid className={classes.grid}>
          <img className={classes.logo} src={Logo} alt="Optimise Prime" />
        </HvGrid>
        <HvGrid className={classes.grid} item>
          <HvTypography variant="xlTitle">
            Welcome to the Optimise Prime Site Planning Tool
          </HvTypography>
        </HvGrid>
        <HvGrid className={classes.grid} item>
          <HvTypography variant="normalText">
            This tool has been developed to help depot operators calculate and
            understand the electricity connection requirements of transitioning
            their fleets to electric.
          </HvTypography>
          <HvTypography variant="normalText">
            The tool can demonstrate how smart charging can be used to reduce
            the need for connection upgrades.
          </HvTypography>
        </HvGrid>
        <HvGrid className={classes.signIn} item>
          <HvTypography variant="highlightText">
            To sign up or log in:
          </HvTypography>
          <HvButton
            type="submit"
            category="primary"
            className={classes.signInButton}
            onClick={() => login()}
          >
            Log In
          </HvButton>
        </HvGrid>
        <HvGrid className={classes.gridfoot} item >
          <img className={classes.logoUKPN} src={LogoUKPN} alt="UKPN" />
          <HvTypography variant="normalText" style={{marginLeft: "40px"}}>
            Optimise Prime is a UK Power Networks innovation project with the aim of accelerating the transition to electric vehicles.<br/>
            The project is funded by the Network Innovation Competition.<br/>
            To learn more about the Optimise Prime project visit{" "}
            <a href="https://innovation.ukpowernetworks.co.uk/projects/optimise-prime/" target="__blank">
              UK Power Networks Innovation - Optimise Prime
            </a>
            .
          </HvTypography>
        </HvGrid>
      </Paper>
    </HvContainer>
  );
};
