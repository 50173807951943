import { ActionsUnion, DispatchAction } from "../type";
import { createAction } from "../action";
import { ApiError } from "../../api/types";
import { deleteById } from "../../api/apiClient";
import { NOTIFICATIONS_MESSAGES, setSuccess } from "../notifications";

export const ACCOUNT_URL = "/users";

export enum ActionTypes {
  DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE",
}

export const Actions = {
  deleteAccountRequest: () => createAction(ActionTypes.DELETE_ACCOUNT_REQUEST),
  deleteAccountSuccess: () => createAction(ActionTypes.DELETE_ACCOUNT_SUCCESS),
  deleteAccountError: (error: ApiError) =>
    createAction(ActionTypes.DELETE_ACCOUNT_FAILURE, error),
};

export type Actions = ActionsUnion<typeof Actions>;

export function deleteAccount(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.deleteAccountRequest());

    deleteById(`${ACCOUNT_URL}`)
      .then(() => {
        dispatch(Actions.deleteAccountSuccess());
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.delete));
      })
      .catch((error: ApiError) => dispatch(Actions.deleteAccountError(error)));
  };
}
