import axios, { AxiosError } from "axios";
import { ApiError } from "./types";
import {
  b2cPolicies,
  msalConfig,
  msalInstance,
} from "../components/msal/assets/msalConfig";
import { AccountInfo } from "@azure/msal-common";
import { IdTokenClaims } from "../components/msal/types/types";

const apiUrl = process.env.REACT_APP_SPT_API_URL;

const axiosInstance = axios.create({
  baseURL: apiUrl,
});

export const fetch = async <T>(url: string) => {
  const headers = await getAuthorizationHeader();
  return axiosInstance
    .get<T>(url, { headers })
    .then(({ data }) => data)
    .catch((error: AxiosError) => handleApiError(error));
};
export const deleteById = async (url: string) => {
  const headers = await getAuthorizationHeader();
  return axiosInstance
    .delete(url, { headers })
    .then((response) => response)
    .catch((error) => handleApiError(error));
};
export const update = async <T>(url: string, data: T) => {
  const headers = await getAuthorizationHeader();
  return axiosInstance
    .put(url, data, { headers })
    .then((response) => response)
    .catch((error) => handleApiError(error));
};

export const updateWithFile = async <T>(url: string, data: T) => {
  let headers = await getAuthorizationHeader();
  headers["Content-Type"] = "multipart/form-data";
  return axiosInstance
    .put(url, data, { headers })
    .then((response) => response)
    .catch((error: AxiosError) => handleApiError(error));
};

export const insert = async <T>(url: string, data: T) => {
  const headers = await getAuthorizationHeader();
  return axiosInstance
    .post(url, data, { headers })
    .then((response) => response)
    .catch((error: AxiosError) => handleApiError(error));
};

export const insertWithFile = async <T>(url: string, data: T) => {
  let headers = await getAuthorizationHeader();
  headers["Content-Type"] = "multipart/form-data";
  return axiosInstance
    .post(url, data, { headers })
    .then((response) => response)
    .catch((error: AxiosError) => handleApiError(error));
};

const getAccessToken = async (account: AccountInfo | null) => {
  let idToken = sessionStorage.getItem('jwtToken');
  if(!idToken || idToken === '') {
    const token =  account &&
      (await msalInstance.acquireTokenSilent(getSilentRequestConfig(account)));
    sessionStorage.setItem('jwtToken', token?.idToken as string);
    idToken = token?.idToken as string;
  }
  return idToken;
};

const getAuthorizationHeader = async () => {
  const account = await msalInstance.getActiveAccount();
  let headers;
  try {
    const accessToken = await getAccessToken(account);
    headers = {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    };
  } catch (e) {
    sessionStorage.removeItem('jwtToken');
    msalInstance.logoutRedirect();
    headers = { "Content-Type": "application/json" };
  }
  return headers;
};

const getSilentRequestConfig = (account: AccountInfo) => {
  let idTokenClaims: IdTokenClaims | null = account.idTokenClaims as IdTokenClaims;
  return {
    sid: account.homeAccountId,
    authority:
      idTokenClaims.tfp === b2cPolicies.names.editProfile
        ? b2cPolicies.authorities.editProfile.authority
        : b2cPolicies.authorities.signUpSignIn.authority,
    ...msalConfig.silentRequestConfig,
    forceRquest: false,
  };
};

const handleApiError = (error: AxiosError) => {
  const status = error.response?.status;
  const errors = error.response?.data?.errors;
  let message = error.response?.data?.errors?.CustomFailure;
  sessionStorage.removeItem('jwtToken');

  if (message) {
    throw new ApiError(status, message);
  } else if (errors) {
    message = error.response?.data?.title;
    throw new ApiError(status, message, errors);
  }
  throw new ApiError(status);
};
