import { ListValues } from "../../../../components/forms/assets/types";

export const dailyPtusListValues: ListValues[] = [
  {
    id: "1",
    label: "00:00",
  },
  {
    id: "2",
    label: "00:30",
  },
  {
    id: "3",
    label: "01:00",
  },
  {
    id: "4",
    label: "01:30",
  },
  {
    id: "5",
    label: "02:00",
  },
  {
    id: "6",
    label: "02:30",
  },
  {
    id: "7",
    label: "03:00",
  },
  {
    id: "8",
    label: "03:30",
  },
  {
    id: "9",
    label: "04:00",
  },
  {
    id: "10",
    label: "04:30",
  },
  {
    id: "11",
    label: "05:00",
  },
  {
    id: "12",
    label: "05:30",
  },
  {
    id: "13",
    label: "06:00",
  },
  {
    id: "14",
    label: "06:30",
  },
  {
    id: "15",
    label: "07:00",
  },
  {
    id: "16",
    label: "07:30",
  },
  {
    id: "17",
    label: "08:00",
  },
  {
    id: "18",
    label: "08:30",
  },
  {
    id: "19",
    label: "09:00",
  },
  {
    id: "20",
    label: "09:30",
  },
  {
    id: "21",
    label: "10:00",
  },
  {
    id: "22",
    label: "10:30",
  },
  {
    id: "23",
    label: "11:00",
  },
  {
    id: "24",
    label: "11:30",
  },
  {
    id: "25",
    label: "12:00",
  },
  {
    id: "26",
    label: "12:30",
  },
  {
    id: "27",
    label: "13:00",
  },
  {
    id: "28",
    label: "13:30",
  },
  {
    id: "29",
    label: "14:00",
  },
  {
    id: "30",
    label: "14:30",
  },
  {
    id: "31",
    label: "15:00",
  },
  {
    id: "32",
    label: "15:30",
  },
  {
    id: "33",
    label: "16:00",
  },
  {
    id: "34",
    label: "16:30",
  },
  {
    id: "35",
    label: "17:00",
  },
  {
    id: "36",
    label: "17:30",
  },
  {
    id: "37",
    label: "18:00",
  },
  {
    id: "38",
    label: "18:30",
  },
  {
    id: "39",
    label: "19:00",
  },
  {
    id: "40",
    label: "19:30",
  },
  {
    id: "41",
    label: "20:00",
  },
  {
    id: "42",
    label: "20:30",
  },
  {
    id: "43",
    label: "21:00",
  },
  {
    id: "44",
    label: "21:30",
  },
  {
    id: "45",
    label: "22:00",
  },
  {
    id: "46",
    label: "22:30",
  },
  {
    id: "47",
    label: "23:00",
  },
  {
    id: "48",
    label: "23:30",
  },
  {
    id: "49",
    label: "24:00",
  },
];
