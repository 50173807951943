import React, { useCallback } from "react";
//Components
import { DropdownMultiAsync } from "../../inputs";
//Types
import { StoreValues } from "../assets/types";

interface Props {
  name: string;
  onChange: (
    name: string,
    value: Array<string>,
    affectedFields?: Array<string>
  ) => void;
  initialValue?: Array<string>;
  invalid?: boolean;
  label: string;
  loadValues: StoreValues;
  readOnly?: boolean;
  affectedFields?: Array<string>;
  className?: string;
}

export const FormDropdownMultiAsync: React.FC<Props> = ({
  name,
  onChange,
  initialValue,
  invalid,
  label,
  loadValues,
  readOnly,
  affectedFields,
}) => {
  const handleChange = useCallback(
    (value: string[]) => onChange(name, value, affectedFields),
    [name, onChange, affectedFields]
  );

  return (
    <DropdownMultiAsync
      name={name}
      initialValue={initialValue}
      invalid={invalid}
      label={label}
      loadValues={loadValues}
      onChange={handleChange}
      readOnly={readOnly}
    />
  );
};
