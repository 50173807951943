import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { Form } from "../../../components/forms/Form";
//Redux
import {
  fetchScenario,
  getScenarios,
  unsetScenarioError,
  updateScenario,
} from "../../../store/scenarios";
//Others
import { mapScenarioToApiScenario } from "./assets/mappings";
import { MainComponentProps } from "../../Layout/types/types";
import { detailFields } from "./assets/fields";
import { FormValues } from "../../../components/forms/assets/types";
import { Scenario } from "../../../store/scenarios/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { SCENARIO_TITLE } from "./assets/namings";
import { ScenarioDescription } from "./assets/Tooltips";

interface UrlParameters {
  id: string;
}

export const ScenariosDetail: React.FC<MainComponentProps> = () => {
  const { id } = useParams<UrlParameters>();
  const dispatch = useDispatch();
  const { navigateToTable } = useNavigation();
  useErrorModal(getScenarios, unsetScenarioError);

  const [isUpdating, setIsUpdating] = useState(false);

  const { isRequesting, detail, error } = useSelector(getScenarios);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const scenario = values as Scenario;
      dispatch(updateScenario(mapScenarioToApiScenario(scenario)));
      setIsUpdating(true);
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchScenario(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (isRequesting) {
      return;
    }

    if ((isUpdating && !error) || error?.code === 404) {
      navigateToTable();
    } else if (isUpdating && error) {
      setIsUpdating(false);
    }
  }, [isRequesting, isUpdating, error, setIsUpdating, navigateToTable]);
  return (
    <WidgetContainer>
      <Form
        isLoading={isRequesting}
        title={SCENARIO_TITLE}
        description={<ScenarioDescription />}
        fields={detailFields}
        initialValues={detail}
        formErrors={error?.formErrors}
        onSubmit={handleSubmit}
        onCancel={navigateToTable}
      />
    </WidgetContainer>
  );
};
