import { makeStyles, MenuProps } from "@material-ui/core";

export const pickerStyles = makeStyles(() => ({
    picker: {
        "& .MuiPickersBasePicker-container": {
            backgroundColor: "#FFFFFF"
        }
    }
}));

export const dropdownMultiStyles = makeStyles(() => ({
    multiSelect: {
        width: "100%"
    },
    multiSelectMenu: {
        backgroundColor: "#FFFFFF !important"
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const dropdownMultiMenuProps: Partial<MenuProps> = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    variant: "menu",
    getContentAnchorEl: null
}