import React from "react";
import { Route, Switch } from "react-router";
//Other
import { MainComponentProps } from "./types/types";

export const SubPageCRUD: React.FC<MainComponentProps> = ({ routes }) => {
  return (
    <Switch>
      {routes &&
        routes.map(({ id, path, label, Component, children }, index) => (
          <Route key={id} exact={true} path={path}>
            <Component title={label} routes={children} />
          </Route>
        ))}
    </Switch>
  );
};
