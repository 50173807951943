import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { SimpleModal } from "../../components/modals/SimpleModal";
//Redux
import { getNotification } from "../../store/notifications/selectors";
import { unsetNotification } from "../../store/notifications";

interface Props {}

export const ErrorModal: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const { type, message } = useSelector(getNotification);

  const handleClose = useCallback(() => dispatch(unsetNotification()), [
    dispatch,
  ]);

  return (
    <SimpleModal
      open={type === "error"}
      title={"Something went wrong"}
      onClose={handleClose}
    >
      {message?.split("\n").map((messagePart, index) => (
        <span key={index}>{messagePart}</span>
      ))}
    </SimpleModal>
  );
};
