import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { Form } from "../../../components/forms/Form";
//Redux
import {
  getScenarios,
  insertScenario,
  unsetScenarioError,
} from "../../../store/scenarios";
//Others
import { mapScenarioToApiScenario } from "./assets/mappings";
import { MainComponentProps } from "../../Layout/types/types";
import { detailFields } from "./assets/fields";
import { FormValues } from "../../../components/forms/assets/types";
import { Scenario } from "../../../store/scenarios/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { SCENARIO_TITLE } from "./assets/namings";
import { ScenarioDescription } from "./assets/Tooltips";

export const ScenariosAdd: React.FC<MainComponentProps> = () => {
  const dispatch = useDispatch();
  const { navigateToTable } = useNavigation();
  useErrorModal(getScenarios, unsetScenarioError);

  const fields = useMemo(() => detailFields.filter(({ id }) => !id), []);
  const initialValues = useMemo(() => {
    const newInitialValues: FormValues = {};
    detailFields.forEach(({ accessor, defaultValue }) => {
      if (!defaultValue) {
        return;
      }

      newInitialValues[accessor] = defaultValue;
    });

    return newInitialValues;
  }, []);

  const [isInserting, setIsInserting] = useState(false);

  const { isRequesting, error } = useSelector(getScenarios);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const scenario = values as Scenario;
      dispatch(insertScenario(mapScenarioToApiScenario(scenario)));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isRequesting) {
      setIsInserting(true);
    } else if (isInserting) {
      if (error) {
        setIsInserting(false);
      } else {
        navigateToTable();
      }
    }
  }, [isRequesting, error, isInserting, setIsInserting, navigateToTable]);

  return (
    <WidgetContainer>
      <Form
        isLoading={isRequesting}
        title={`Add ${SCENARIO_TITLE}`}
        description={<ScenarioDescription />}
        fields={fields}
        initialValues={initialValues}
        formErrors={error?.formErrors}
        onSubmit={handleSubmit}
        onCancel={navigateToTable}
      />
    </WidgetContainer>
  );
};
