import { makeStyles } from "@material-ui/core";

export const userActionsStyles = makeStyles(() => ({
  userActions: {
    display: "flex",
    '@media print': {
      display: 'none',
    }, 
    float: "right",
    paddingTop: "15px !important",
    paddingRight: "15px !important",
    "& > *": {
      cursor: "pointer",
    },
  },
}));

export const tabsStyles = makeStyles(() => ({
  tabs: {
    paddingTop: "20px !important",
    display: "flex",
    height: "50px",
    borderBottom: "1px solid #C4C4C4",
  },
  tabItem: {
    cursor: "pointer",
    marginRight: "40px !important",
    "&.HvTypography-highlightText": {
      color: "#f76100 !important",
    },
  },
}));

export const widgetContainerStyles = makeStyles(() => ({
  root: {
    marginTop: "20px",
  },
  paper: {
    backgroundColor: "#FFF",
    padding: "10px",
    border: ".0625rem solid #e0e0e0",
  },
}));
