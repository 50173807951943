import React from "react";
//Components
import { StyledFormInput } from "../../styled/inputs";
import { InputBaseComponentProps } from "@material-ui/core";

interface Props {
  name: string;
  onBlur?: (name: string, value: string) => void;
  initialValue?: string;
  invalid?: boolean;
  readOnly?: boolean;
  inputProps?: InputBaseComponentProps;
}

export const FormText: React.FC<Props> = ({
  name,
  onBlur,
  initialValue,
  invalid,
  readOnly,
  inputProps,
}) => {
  return (
    <StyledFormInput
      disabled={readOnly}
      onBlur={(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => onBlur && onBlur(name, event?.target?.value)}
      invalid={invalid}
      inputProps={inputProps}
      defaultValue={initialValue}
      onChange={() => {}}
    />
  );
};
