import { HvBaseInput, HvButton, HvDropdown } from "@hitachivantara/uikit-react-core";
import ChipInput from "material-ui-chip-input";
import { withStyles } from "@material-ui/styles";
import { MenuItem, Select, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";

export const StyledFormInput = withStyles({
  root: {
    width: "90%",
    border: "1px solid #C4C4C4",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #4D4D4A",
    },
    "& .HvBaseInput-inputBorderContainer": {
      backgroundColor: "#FFFFFF !important",
    },
    "@media (min-width: 1270px)": {
      width: "50%",
    },
  },
  inputRoot: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  inputRootFocused: {
    backgroundColor: "#FFFFFF",
  },
  invalid: {
    borderColor: "red",
  },
  inputRootDisabled: {
    backgroundColor: "#D3D3D3 !important",
    "&:hover": {
      backgroundColor: "#D3D3D3",
    },
  },
  input: {
    backgroundColor: "#FFFFFF",
    height: "22px",
  },
  disabled: {
    backgroundColor: "#D3D3D3 !important",
    "& .HvBaseInput-inputBorderContainer": {
      backgroundColor: "#D3D3D3 !important",
    },
  },
})(HvBaseInput);

export const StyledDropdownInput = withStyles({
  root: {
    width: "50%",
    maxWidth: "none",
    border: "1px solid #C4C4C4",
    backgroundColor: "transparent",
    "&:hover": {
      background: "#FFFFFF",
      border: "1px solid #4D4D4A",
    },
    "&.multiline": {
      width: "100%",
    },
    "&.invalid": {
      borderColor: "red",
    },
    "& .HvBaseDropdown-header": {
      backgroundColor: "#FFFFFF !important",
      border: "1px solid transparent",
      "&.HvBaseDropdown-headerDisabled": {
        backgroundColor: "#D3D3D3 !important",
      },
    },
    "& .HvBaseDropdown-rootDisabled": {
      backgroundColor: "#D3D3D3 !important",
    },
  },
  rootList: {
    width: "100%",
    backgroundColor: "#FFFFFF !important",
    maxHeight: "300px",
    overflow: "auto",
  },
  dropdown: {
    width: "100%",
  },
})(HvDropdown);

export const StyledButton = withStyles({
  root: {
    width: "90px",
    height: "30px",
  },
  secondary: {
    color: "#FFFFFF",
    backgroundColor: "#30354a",
    "&:hover": {
      backgroundColor: "#30354a",
      borderColor: "#30354a",
    },
    "&:focus": {
      backgroundColor: "#30354a",
      borderColor: "#30354a",
    },
  },
})(HvButton);

export const StyledChipInput = withStyles({
  root: {
    width: "50%",
    border: "1px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    "& div:after": {
      border: "1px solid transparent",
    },
    "& div:before": {
      border: "1px solid transparent !important",
    },
    "&:hover": {
      background: "#FFFFFF",
      borderLeft: "1px solid #4D4D4A",
      borderRight: "1px solid #4D4D4A",
      borderTop: "1px solid #4D4D4A",
    },
    "&.invalid": {
      borderLeft: "1px solid red",
      borderRight: "1px solid red",
      borderTop: "1px solid red",
      "& > div:first-of-type": {
        borderBottom: "1px solid red",
      },
    },
  },
  inputRoot: {
    backgroundColor: "#FFFFFF",
  },
  input: {
    backgroundColor: "#FFFFFF",
    height: "22px",
    border: "1px solid #4D4D4D",
  },
  chipContainer: {
    paddingTop: "5px",
    border: "1px solid #C4C4C4",
    "&:after": {
      border: "1px solid transparent",
    },
    "&:hover": {
      borderBottom: "1px solid #4D4D4A",
    },
  },
})(ChipInput);

export const StyledMultiSelect = withStyles({
  root: {
    border: "1px solid #C4C4C4",
    borderRadius: "2px",
    height: "19px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    "&:focus": {
      backgroundColor: "#FFFFFF",
    },
    "&:hover": {
      border: "1px solid #4D4D4D",
    },
  },
})(Select);

export const StyledMenuItem = withStyles({
  selected: {
    backgroundColor: "#FEFEFE!important",
  },
})(MenuItem);

export const StyledDatepicker = withStyles({
  root: {
    border: "1px solid #C4C4C4",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "34px",
    "&:hover": {
      border: "1px solid #4D4D4D",
    },
  },
})(KeyboardDatePicker);

export const StyledTimepicker = withStyles({
  root: {
    border: "1px solid #C4C4C4",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
    height: "34px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #4D4D4D",
    },
  },
})(KeyboardTimePicker);

export const StyledFormMultiline = makeStyles(() => ({
  title: {
    borderBottom: "2px solid #C4C4C4",
  },
  actions: {
    borderTop: "2px solid #C4C4C4",
    "& button": {
      marginRight: "5px",
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    "&.schedule": {
      marginBottom: "10px",
    },
  },
  fullWidth: {
    width: "100%",
  },
  columnLabel: {
    fontSize: "13px",
    color: "gray",
  },
  circularButton: {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    fontSize: "20px",
  },
  fieldError: {
    color: "red !important",
    fontSize: "12px",
  },
}));
