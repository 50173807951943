import { HvDropDownMenu } from "@hitachivantara/uikit-react-core";
import { withStyles } from "@material-ui/styles";

export const StyledDropdownMenu = withStyles({
  menuList: {
    '@media print': {
      display: 'none',
    },
    "& .HvFocus-focused": {
      boxShadow: "none",
    },
    "& .HvListItem-focus": {
      backgroundColor: "transparent",
    },
  },
})(HvDropDownMenu);
