import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { Form } from "../../../components/forms/Form";
//Redux
import {
  fetchElectricVehicleType,
  getElectricVehicleTypes,
  unsetEletricVehicleTypeError,
  updateElectricVehicleType,
} from "../../../store/electricVehicleTypes";
//Others
import { MainComponentProps } from "../../Layout/types/types";
import { detailFields } from "./assets/fields";
import { FormValues } from "../../../components/forms/assets/types";
import { ElectricVehicleType } from "../../../store/electricVehicleTypes/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { ELECTRIC_VEHICLE_TYPE_TITLE } from "./assets/namings";
import { ElectricVehicleTypeDescription } from "./assets/Tooltips";

interface UrlParameters {
  id: string;
}

export const ElectricVehicleTypesDetail: React.FC<MainComponentProps> = () => {
  const { id } = useParams<UrlParameters>();
  const dispatch = useDispatch();
  const { navigateToTable } = useNavigation();
  useErrorModal(getElectricVehicleTypes, unsetEletricVehicleTypeError);

  const [isUpdating, setIsUpdating] = useState(false);

  const { isRequesting, detail, error } = useSelector(getElectricVehicleTypes);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const electricVehicleType = values as ElectricVehicleType;
      dispatch(updateElectricVehicleType(electricVehicleType));
      setIsUpdating(true);
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchElectricVehicleType(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (isRequesting) {
      return;
    }

    if ((isUpdating && !error) || error?.code === 404) {
      navigateToTable();
    } else if (isUpdating && error) {
      setIsUpdating(false);
    }
  }, [isRequesting, isUpdating, error, setIsUpdating, navigateToTable]);

  return (
    <WidgetContainer>
      <Form
        isLoading={isRequesting}
        title={`${ELECTRIC_VEHICLE_TYPE_TITLE}`}
        description={<ElectricVehicleTypeDescription />}
        fields={detailFields}
        formErrors={error?.formErrors}
        initialValues={detail}
        onSubmit={handleSubmit}
        onCancel={navigateToTable}
      />
    </WidgetContainer>
  );
};
