import React from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { resultsStyle } from "./assets/styles";

interface Props {}

export const ScenariosResultsInfo: React.FC<Props> = () => {
  const classes = resultsStyle();

  return (
    <WidgetContainer>
      <HvGrid container>
        <HvGrid item xs={12}>
          <HvTypography variant="xsTitle">
            Step 3 – Viewing your results
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            The results for your scenario have now been calculated.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            Click on{" "} <strong>'Demand Profile'</strong> above to view your modelled demand. 
            This is presented in three models.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            The <strong>'Base'</strong> model shows the maximum load your site could create, 
            based on the maximum observed historic baseload, plus the load from all chargers operating 
            simultaneously at maximum power. This model does not take into account the requirements of vehicles.
          </HvTypography>
          <HvTypography variant="normalText">
            The <strong>'Unmanaged'</strong> model simulates the load generated 
            if you plug in your vehicles to charge as soon as they return to the depot, 
            without controlling charge times. This model takes into account the vehicle 
            journey times and distances to ensure that each receives sufficient charge. 
            If the unmanaged model cannot be calculated you will need to revisit your choice 
            of charge points or the feasibility of your vehicle schedules.
          </HvTypography>
          <HvTypography variant="normalText">
            The <strong>'Smart'</strong> model alters charge times to reduce the peak demand from your site, 
            within the boundaries of your vehicle schedules.
          </HvTypography>
          <HvTypography variant="normalText">
            A summary of the model data can be downloaded.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            Click on <strong>'Charging Costs'</strong> above to view a summary
            of the cost of charging your vehicles based on the entered
            electricity tariff. If you've entered CAPEX or OPEX cost they will
            also be presented here. Note that as the Smart Model is focused on
            minimising demand peaks it may not always result in lower charging
            costs.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            Click on <strong>'Scenario Details'</strong> above to view a summary
            of the assets used in this scenario.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
            If your demand profile page does not show a result, this is likely because the vehicles 
            or charge points you have chosen are not sufficient to cover your routes.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12}>
          <HvTypography variant="normalText">
           For more help in interpreting the results, visit our <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-guide" target="__blank">help pages</a>.
           The results presented are an estimation, based on your inputs and a number of assumptions. 
          </HvTypography>
          <HvTypography variant="normalText">
           Click <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-benefits" target="__blank">here</a> to find out more about the limitations of the tool.
          </HvTypography>
        </HvGrid>
      </HvGrid>
    </WidgetContainer>
  );
};
