import { makeStyles } from "@material-ui/core";
import backgroundImage from "../../../assets/images/login_background.jpeg";

export const loginStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    maxWidth: "100vw",
    padding: "0",
    background: "url(" + backgroundImage + ") no-repeat center center fixed",
    backgroundSize: "cover",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  gridfoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "left",
  },
  logo: {
    marginTop: "40px",
    width: "100%",
    maxWidth: "500px",
  },
  logoUKPN: {
    width: "15%",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    opacity: "0.85",
    minHeight: "600px",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "700px",
      height: "100%",
      width: "100%",
    },
  },
  signIn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
  },
  signInButton: {
    marginLeft: "20px",
  },
}));
