import { ActionsUnion, DispatchAction } from "../type";
import { createAction } from "../action";
import { Scenario } from "./type";
import { deleteById, fetch, insert, update } from "../../api/apiClient";

import { NOTIFICATIONS_MESSAGES, setSuccess } from "../notifications";
import { ApiError } from "../../api/types";

export const SCENARIO_URL = "/scenarios";

export enum ActionTypes {
  FETCH_SCENARIOS_REQUEST = "FETCH_SCENARIOS_REQUEST",
  FETCH_SCENARIOS_SUCCESS = "FETCH_SCENARIOS_SUCCESS",
  FETCH_SCENARIOS_FAILURE = "FETCH_SCENARIOS_FAILURE",
  FETCH_SCENARIO_REQUEST = "FETCH_SCENARIO_REQUEST",
  FETCH_SCENARIO_SUCCESS = "FETCH_SCENARIO_SUCCESS",
  FETCH_SCENARIO_FAILURE = "FETCH_SCENARIO_FAILURE",
  DELETE_SCENARIO_REQUEST = "DELETE_SCENARIO_REQUEST",
  DELETE_SCENARIO_SUCCESS = "DELETE_SCENARIO_SUCCESS",
  DELETE_SCENARIO_FAILURE = "DELETE_SCENARIO_FAILURE",
  UPDATE_SCENARIO_REQUEST = "UPDATE_SCENARIO_REQUEST",
  UPDATE_SCENARIO_SUCCESS = "UPDATE_SCENARIO_SUCCESS",
  UPDATE_SCENARIO_FAILURE = "UPDATE_SCENARIO_FAILURE",
  INSERT_SCENARIO_REQUEST = "INSERT_SCENARIO_REQUEST",
  INSERT_SCENARIO_SUCCESS = "INSERT_SCENARIO_SUCCESS",
  INSERT_SCENARIO_FAILURE = "INSERT_SCENARIO_FAILURE",
  CALCULATE_SCENARIO_REQUEST = "CALCULATE_SCENARIO_REQUEST",
  CALCULATE_SCENARIO_SUCCESS = "CALCULATE_SCENARIO_SUCCESS",
  CALCULATE_SCENARIO_FAILURE = "CALCULATE_SCENARIO_FAILURE",
  FETCH_SCENARIO_RESULTS_REQUEST = "FETCH_SCENARIO_RESULTS_REQUEST",
  FETCH_SCENARIO_RESULTS_SUCCESS = "FETCH_SCENARIO_RESULTS_SUCCESS",
  FETCH_SCENARIO_RESULTS_FAILURE = "FETCH_SCENARIO_RESULTS_FAILURE",
  TRY_TO_DELETE_SCENARIO = "TRY_TO_DELETE_SCENARIO",
  UNSET_SCENARIO_ERROR = "UNSET_SCENARIO_ERROR",
}

export const Actions = {
  fetchScenariosRequest: () =>
    createAction(ActionTypes.FETCH_SCENARIOS_REQUEST),
  fetchScenariosSuccess: (data: Scenario[]) =>
    createAction(ActionTypes.FETCH_SCENARIOS_SUCCESS, data),
  fetchScenariosError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_SCENARIOS_FAILURE, error),
  fetchScenarioRequest: () => createAction(ActionTypes.FETCH_SCENARIO_REQUEST),
  fetchScenarioSuccess: (scenario: Scenario) =>
    createAction(ActionTypes.FETCH_SCENARIO_SUCCESS, scenario),
  fetchScenarioError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_SCENARIO_FAILURE, error),
  deleteScenarioRequest: () =>
    createAction(ActionTypes.DELETE_SCENARIO_REQUEST),
  deleteScenarioSuccess: (id: number) =>
    createAction(ActionTypes.DELETE_SCENARIO_SUCCESS, id),
  deleteScenarioError: (error: ApiError) =>
    createAction(ActionTypes.DELETE_SCENARIO_FAILURE, error),
  updateScenarioRequest: () =>
    createAction(ActionTypes.UPDATE_SCENARIO_REQUEST),
  updateScenarioSuccess: (scenario: Scenario) =>
    createAction(ActionTypes.UPDATE_SCENARIO_SUCCESS, scenario),
  updateScenarioError: (error: ApiError) =>
    createAction(ActionTypes.UPDATE_SCENARIO_FAILURE, error),
  insertScenarioRequest: () =>
    createAction(ActionTypes.INSERT_SCENARIO_REQUEST),
  insertScenarioSuccess: () =>
    createAction(ActionTypes.INSERT_SCENARIO_SUCCESS),
  insertScenarioError: (error: ApiError) =>
    createAction(ActionTypes.INSERT_SCENARIO_FAILURE, error),
  calculateScenarioRequest: () =>
    createAction(ActionTypes.CALCULATE_SCENARIO_REQUEST),
  calculateScenarioSuccess: () =>
    createAction(ActionTypes.CALCULATE_SCENARIO_SUCCESS),
  calculateScenarioError: (error: ApiError) =>
    createAction(ActionTypes.CALCULATE_SCENARIO_FAILURE, error),
  tryToDelete: (id: number | null) =>
    createAction(ActionTypes.TRY_TO_DELETE_SCENARIO, id),
  unsetScenarioError: () => createAction(ActionTypes.UNSET_SCENARIO_ERROR),
};

export type Actions = ActionsUnion<typeof Actions>;

export function fetchScenarios(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchScenariosRequest());

    fetch<Scenario[]>(`${SCENARIO_URL}/`)
      .then((data) => dispatch(Actions.fetchScenariosSuccess(data ?? [])))
      .catch((error) => dispatch(Actions.fetchScenariosError(error)));
  };
}

export function fetchScenario(id: string): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchScenarioRequest());

    fetch<Scenario>(`${SCENARIO_URL}/${id}/`)
      .then((data) => dispatch(Actions.fetchScenarioSuccess(data)))
      .catch((error: ApiError) => dispatch(Actions.fetchScenarioError(error)));
  };
}

export function deleteScenario(id: number): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.deleteScenarioRequest());

    deleteById(`${SCENARIO_URL}/${id}`)
      .then(() => {
        dispatch(Actions.deleteScenarioSuccess(id));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.delete));
      })
      .catch((error: ApiError) => dispatch(Actions.deleteScenarioError(error)));
  };
}

export function updateScenario(scenario: Scenario): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.updateScenarioRequest());

    update(`${SCENARIO_URL}/${scenario.id}/`, scenario)
      .then(() => {
        dispatch(Actions.updateScenarioSuccess(scenario));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.update));
      })
      .catch((error: ApiError) => dispatch(Actions.updateScenarioError(error)));
  };
}

export function insertScenario(scenario: Scenario): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.insertScenarioRequest());

    insert(`${SCENARIO_URL}/`, scenario)
      .then(() => {
        dispatch(Actions.insertScenarioSuccess());
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.insert));
      })
      .catch((error: ApiError) => dispatch(Actions.insertScenarioError(error)));
  };
}

export function calculateScenario(id: number): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.calculateScenarioRequest());

    insert(`${SCENARIO_URL}/${id}/result-calculation/`, null)
      .then(() => {
        dispatch(Actions.calculateScenarioSuccess());
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.update));
      })
      .then(() => {
        dispatch(fetchScenarios());
      })
      .catch((error: ApiError) =>
        dispatch(Actions.calculateScenarioError(error))
      );
  };
}

export function tryToDelete(id: number | null): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.tryToDelete(id));
  };
}

export function unsetScenarioError(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.unsetScenarioError());
  };
}
