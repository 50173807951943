import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { StyledDropdownInput } from "../styled/inputs";
//Types
import { StoreValues } from "../forms/assets/types";
import clsx from "clsx";
import { ListValueProp } from "@hitachivantara/uikit-react-core";
import { selectValueFromList } from "../../utils/dropdowns";

interface Props {
  onChange?: (value: string) => void;
  initialValue?: string;
  invalid?: boolean;
  loadValues: StoreValues;
  name?: string;
  readOnly?: boolean;
  className?: string;
}

export const DropdownAsync: React.FC<Props> = ({
  onChange,
  initialValue,
  invalid,
  loadValues,
  name,
  readOnly,
  className = "",
}) => {
  const { selector, action, format } = loadValues;
  const dispatch = useDispatch();
  const [listValues, setListValues] = useState<ListValueProp[]>([]);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

  const { data } = useSelector(selector);

  useEffect(() => {
    if ((!data || data.length === 0) && !isDataFetched) {
      dispatch(action());
      setIsDataFetched(true);
    }
  }, [data, isDataFetched, dispatch, action]);

  useEffect(() => {
    if (data && data.length > 0) {
      setListValues(selectValueFromList([...format(data)], initialValue));
    }
  }, [data, initialValue, format]);

  const handleChange = useCallback(
    (selected: ListValueProp | ListValueProp[] | undefined) => {
      if (!onChange) {
        return;
      }

      if (selected && !Array.isArray(selected)) {
        onChange(selected.id ?? "0");
        return;
      }

      onChange("0");
    },
    [onChange]
  );

  return (
    <StyledDropdownInput
      id={name}
      key={name + (listValues && listValues.length > 0 ? "loaded" : "empty")}
      className={clsx(invalid ? "invalid" : "", className)}
      values={listValues}
      hasTooltips
      onChange={handleChange}
      disabled={readOnly || listValues.length === 0}
    />
  );
};
