import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { Form } from "../../../components/forms/Form";
//Redux
import {
  getElectricVehicleTypes,
  insertElectricVehicleType,
  unsetEletricVehicleTypeError,
} from "../../../store/electricVehicleTypes";
//Hooks
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
//Others
import { MainComponentProps } from "../../Layout/types/types";
import { detailFields } from "./assets/fields";
import { FormValues } from "../../../components/forms/assets/types";
import { ElectricVehicleType } from "../../../store/electricVehicleTypes/type";
import { ELECTRIC_VEHICLE_TYPE_TITLE } from "./assets/namings";
import { ElectricVehicleTypeDescription } from "./assets/Tooltips";

export const ElectricVehicleTypesAdd: React.FC<MainComponentProps> = () => {
  const dispatch = useDispatch();
  useErrorModal(getElectricVehicleTypes, unsetEletricVehicleTypeError);
  const { navigateToTable } = useNavigation();

  const fields = useMemo(() => detailFields.filter(({ id }) => !id), []);
  const initialValues = useMemo(() => {
    const newInitialValues: FormValues = {};
    detailFields.forEach(({ accessor, defaultValue }) => {
      if (!defaultValue) {
        return;
      }

      newInitialValues[accessor] = defaultValue;
    });

    return newInitialValues;
  }, []);

  const [isInserting, setIsInserting] = useState(false);
  const { isRequesting, error } = useSelector(getElectricVehicleTypes);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const electricVehicleType = values as ElectricVehicleType;
      dispatch(insertElectricVehicleType(electricVehicleType));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isRequesting) {
      setIsInserting(true);
    } else if (isInserting) {
      if (error) {
        setIsInserting(false);
      } else {
        navigateToTable();
      }
    }
  }, [isRequesting, error, isInserting, setIsInserting, navigateToTable]);

  return (
    <WidgetContainer>
      <Form
        isLoading={isRequesting}
        title={`Add ${ELECTRIC_VEHICLE_TYPE_TITLE}`}
        description={<ElectricVehicleTypeDescription />}
        fields={fields}
        formErrors={error?.formErrors}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCancel={navigateToTable}
      />
    </WidgetContainer>
  );
};
