import { makeStyles } from "@material-ui/core";

export const loadingStyles = makeStyles((theme) => ({
    loading: {
      width: "100%",
      height: "100%",
    },
    overlay: {
      position: "absolute",
      transition: "background-Color .2s ease",
      zIndex: -1,
    },
    blur: {
      backgroundColor: "#575757cc",
      zIndex: theme.zIndex.drawer,
    },
  }));