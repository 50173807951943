import { ChargePointType } from "../../../../store/chargePointTypes/type";
import { ElectricVehicleType } from "../../../../store/electricVehicleTypes/type";
import { Schedule } from "../../../../store/schedules/type";
import { Site } from "../../../../store/sites/type";
import { energyCostModelListValues } from "./dropdowns";

export const formatChargePointTypeDescription = (
  chargePointType: ChargePointType
) => `${chargePointType.name}`;

export const formatElectricVehicleTypeDescription = (
  electricVehicleType: ElectricVehicleType
) => `${electricVehicleType.name}`;

export const formatScheduleDescription = (schedule: Schedule) =>
  `${schedule.name}`;

export const formatSiteDescription = (site: Site) => `${site.name}`;

export const getEnergyDistributionModel = (value: string) => {
  return energyCostModelListValues.find((ecm) => ecm.id === String(value))
    ?.label;
};
