import React, { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { DataGrid } from "../../../components/datagrid/DataGrid";
import { ConfirmModal } from "../../../components/modals/ConfirmModal";
//Redux
import { deleteElectricVehicleType, fetchElectricVehicleTypes, getElectricVehicleTypes, tryToDelete, unsetEletricVehicleTypeError } from "../../../store/electricVehicleTypes";
//Other
import { tableColumns } from "./assets/fields";
import { ElectricVehicleType } from "../../../store/electricVehicleTypes/type";
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
import { ELECTRIC_VEHICLE_TYPE_TITLE } from "./assets/namings";

interface Props { }

export const ElectricVehicleTypesTable: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const { navigateToAdd, navigateToDetail } = useNavigation();
    useErrorModal(getElectricVehicleTypes, unsetEletricVehicleTypeError);

    const { isRequesting, data, deleteId } = useSelector(getElectricVehicleTypes);

    const handleDetail = useCallback((event: React.FormEvent<HTMLDivElement>, row: object) => {
        navigateToDetail(row as ElectricVehicleType);
    }, [navigateToDetail]);

    const handleDelete = useCallback((event: React.FormEvent<HTMLDivElement>, row: object) => {
        const { id } = row as ElectricVehicleType;
        dispatch(tryToDelete(id));
    }, [dispatch]);

    const secondaryActions = useMemo(() => ([
        {
            label: "View/Edit",
            action: handleDetail
        },
        {
            label: "Delete",
            action: handleDelete
        },
    ]), [handleDetail, handleDelete]);

    const handleDeleteCancel = useCallback(() => dispatch(tryToDelete(null)), [dispatch]);

    const handleDeleteConfirm = useCallback(() => {
        if (!deleteId) {
            return;
        }

        dispatch(deleteElectricVehicleType(deleteId));
    }, [deleteId, dispatch]);

    useEffect(() => {
        dispatch(fetchElectricVehicleTypes());
    }, [dispatch]);

    return (
        <WidgetContainer>
            <DataGrid
                columns={tableColumns}
                data={data}
                noDataLabel={`There are no ${ELECTRIC_VEHICLE_TYPE_TITLE}s`}
                secondaryActions={secondaryActions}
                isLoading={isRequesting}
                onAdd={navigateToAdd}
            />
            <ConfirmModal
                open={!!deleteId}
                title={`Delete ${ELECTRIC_VEHICLE_TYPE_TITLE}`}
                content={`Are you sure you want to delete this ${ELECTRIC_VEHICLE_TYPE_TITLE}?`}
                onConfirm={handleDeleteConfirm}
                onClose={handleDeleteCancel}
            />
        </WidgetContainer>
    )
} 