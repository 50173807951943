import React, { useState } from "react";
//Components
import { HvRadio, ListValueProp } from "@hitachivantara/uikit-react-core/dist";

interface Props {
  values: ListValueProp[];
  name: string;
  onChange: (name: string, value: string) => void;
  initialValue?: string;
  invalid?: boolean;
  readOnly?: boolean;
}

export const FormRadio: React.FC<Props> = ({
  values,
  name,
  onChange,
  initialValue,
  readOnly,
}) => {
  const [selected, setSelected] = useState(initialValue ?? values[0].id);

  return (
    <>
      {values.map(({ id, label }) => (
        <HvRadio
          key={id}
          name={name}
          label={label}
          value={id}
          checked={String(selected) === String(id)}
          onChange={(event: Event, checked: boolean, value: any) => {
            onChange(name, String(value));
            setSelected(value);
          }}
          disabled={readOnly}
        />
      ))}
    </>
  );
};
