import { TableColumn } from "@hitachivantara/uikit-react-core";
import { FormField } from "../../../../components/forms/assets/types";
import { getCurrencyString } from "../../../../utils/currency";
import { electricVehicleTypesFieldNamings } from "./namings";
import {
  ElectricVehicleTypeNameTooltip,
  ElectricVehicleTypeBatteryCapacityTooltip,
  ElectricVehicleTypeMaximumChargeTooltip,
  ElectricVehicleTypeRangeMilesTooltip,
  ElectricVehicleTypeCapexCostTooltip,
  ElectricVehicleTypeOpexCostTooltip,
} from "./Tooltips";

export const tableColumns: TableColumn[] = [
  {
    headerText: electricVehicleTypesFieldNamings.name.label,
    accessor: electricVehicleTypesFieldNamings.name.accessor,
    cellType: "alpha-numeric",
  },
  {
    headerText: electricVehicleTypesFieldNamings.batteryCapacityKwh.description,
    accessor: electricVehicleTypesFieldNamings.batteryCapacityKwh.accessor,
    cellType: "numeric",
  },
  {
    headerText:
      electricVehicleTypesFieldNamings.maximumChargeSpeedKw.description,
    accessor: electricVehicleTypesFieldNamings.maximumChargeSpeedKw.accessor,
    cellType: "numeric",
  },
  {
    headerText: electricVehicleTypesFieldNamings.rangeMiles.description,
    accessor: electricVehicleTypesFieldNamings.rangeMiles.accessor,
    cellType: "numeric",
  },
  {
    headerText: electricVehicleTypesFieldNamings.capexCostGbp.description,
    accessor: electricVehicleTypesFieldNamings.capexCostGbp.accessor,
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
  {
    headerText:
      electricVehicleTypesFieldNamings.opexCostGbpPerMonth.description,
    accessor: electricVehicleTypesFieldNamings.opexCostGbpPerMonth.accessor,
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
];

export const detailFields: FormField[] = [
  {
    label: electricVehicleTypesFieldNamings.name.description,
    accessor: electricVehicleTypesFieldNamings.name.accessor,
    required: true,
    maxCharacters: 50,
    tooltipContent: ElectricVehicleTypeNameTooltip,
  },
  {
    label: electricVehicleTypesFieldNamings.batteryCapacityKwh.description,
    accessor: electricVehicleTypesFieldNamings.batteryCapacityKwh.accessor,
    required: true,
    inputType: "number",
    dataType: "number",
    maxCharacters: 5,
    regex: new RegExp("^\\d{0,5}(?:\\.\\d)?$"),
    range: { min: 1, max: 999 },
    tooltipContent: ElectricVehicleTypeBatteryCapacityTooltip,
  },
  {
    label: electricVehicleTypesFieldNamings.maximumChargeSpeedKw.description,
    accessor: electricVehicleTypesFieldNamings.maximumChargeSpeedKw.accessor,
    required: true,
    inputType: "number",
    dataType: "number",
    maxCharacters: 5,
    regex: new RegExp("^\\d{0,5}(?:\\.\\d)?$"),
    range: { min: 1, max: 999 },
    tooltipContent: ElectricVehicleTypeMaximumChargeTooltip,
  },
  {
    label: electricVehicleTypesFieldNamings.rangeMiles.description,
    accessor: electricVehicleTypesFieldNamings.rangeMiles.accessor,
    required: true,
    inputType: "number",
    dataType: "number",
    maxCharacters: 3,
    range: { min: 1, max: 999 },
    tooltipContent: ElectricVehicleTypeRangeMilesTooltip,
  },
  {
    label: electricVehicleTypesFieldNamings.capexCostGbp.description,
    accessor: electricVehicleTypesFieldNamings.capexCostGbp.accessor,
    inputType: "number",
    dataType: "number",
    maxCharacters: 10,
    regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
    tooltipContent: ElectricVehicleTypeCapexCostTooltip,
  },
  {
    label: electricVehicleTypesFieldNamings.opexCostGbpPerMonth.description,
    accessor: electricVehicleTypesFieldNamings.opexCostGbpPerMonth.accessor,
    inputType: "number",
    dataType: "number",
    maxCharacters: 10,
    regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
    tooltipContent: ElectricVehicleTypeOpexCostTooltip,
  },
];
