import { ActionsUnion, DispatchAction } from "../type";
import { createAction } from "../action";
import { Results } from "./type";
import { fetch } from "../../api/apiClient";
import { ApiError } from "../../api/types";

export const RESULTS_URL = "/results";

export enum ActionTypes {
  FETCH_RESULTS_REQUEST = "FETCH_RESULTS_REQUEST",
  FETCH_RESULTS_SUCCESS = "FETCH_RESULTS_SUCCESS",
  FETCH_RESULTS_FAILURE = "FETCH_RESULTS_FAILURE",
  UNSET_RESULTS_ERROR = "UNSET_RESULTS_ERROR",
}

export const Actions = {
  fetchResultsRequest: () => createAction(ActionTypes.FETCH_RESULTS_REQUEST),
  fetchResultsSuccess: (data: Results) =>
    createAction(ActionTypes.FETCH_RESULTS_SUCCESS, data),
  fetchResultsError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_RESULTS_FAILURE, error),

  unsetResultsError: () => createAction(ActionTypes.UNSET_RESULTS_ERROR),
};

export type Actions = ActionsUnion<typeof Actions>;

export function fetchResults(id: string): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchResultsRequest());

    fetch<any>(`${RESULTS_URL}/${id}/`)
      .then((data) => dispatch(Actions.fetchResultsSuccess(data)))
      .catch((error: ApiError) => dispatch(Actions.fetchResultsError(error)));
  };
}

export function unsetResultsError(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.unsetResultsError());
  };
}
