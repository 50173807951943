import React, { useCallback, useState } from "react";
//Components
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StyledDatepicker } from "../styled/inputs";
//Other
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { pickerStyles } from "./assets/styles";

interface Props {
    minDate?: string;
    maxDate?: string;
    onChange?: (value: Date) => void;
    onBlur?: (value: Date) => void;
    initialValue?: Date | "";
}

export const DatePicker: React.FC<Props> = ({
    minDate,
    maxDate,
    onChange,
    onBlur,
    initialValue = new Date()
}) => {
    const classes = pickerStyles();

    const [date, setDate] = useState<Date | string>(initialValue);

    const handleDateChange = useCallback((newDate: MaterialUiPickersDate, value?: string | null | undefined) => {
        setDate(newDate as Date);
        onChange && onChange(newDate as Date);
    }, [onChange]);

    const handleBlur = useCallback(() => 
        onBlur && onBlur(date as Date)
    , [onBlur, date]);

    const handleAccept = useCallback((newDate: MaterialUiPickersDate) => onBlur && onBlur(newDate as Date), [onBlur]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <StyledDatepicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                value={date}
                onChange={handleDateChange}
                onBlur={handleBlur}
                onAccept={handleAccept}
                minDate={minDate}
                maxDate={maxDate}
                invalidDateMessage={null}
                maxDateMessage={null}
                minDateMessage={null}
                PopoverProps={{PaperProps: {className: classes.picker}}}
                autoOk={true}
            />
        </MuiPickersUtilsProvider>
    )
} 