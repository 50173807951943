import * as React from "react";
import { useMemo, useCallback } from "react";
//Components
import { HvGrid, HvTypography } from "@hitachivantara/uikit-react-core";
import { Tabs } from "../../components/layout/Tabs";
import { Route, Switch, useHistory } from "react-router";
//Others
import { routesToTabs } from "./types/mapping";
import { Tab } from "../../components/layout/types/types";
import { MainComponentProps } from "./types/types";

export const Page: React.FC<MainComponentProps> = ({ title, routes }) => {
  const history = useHistory();

  const tabs = useMemo(() => routesToTabs(routes || []), [routes]);
  const selectedTab = useMemo(
    () =>
      (routes &&
        routes.find(
          (x) =>
            x.path.split("/")[2] === history.location.pathname.split("/")[2]
        )?.id) ||
      tabs[0]?.id,
    [routes, history, tabs]
  );

  const handleTabChange = useCallback(
    (tab: Tab) => {
      const path = routes && routes.find((x) => x.id === tab.id)?.path;

      if (!path) {
        return;
      }

      history.push(path);
    },
    [history, routes]
  );

  return (
    <HvGrid container>
      <HvGrid item xs={12}>
        <HvTypography variant={"xlTitle"}>{title}</HvTypography>
      </HvGrid>
      <Tabs tabs={tabs} selectedTab={selectedTab} onChange={handleTabChange} />
      <Switch>
        {routes &&
          routes.map(({ id, path, Component, children }, index) => (
            <Route key={id} exact={index === 0} path={path}>
              <Component routes={children} />
            </Route>
          ))}
      </Switch>
    </HvGrid>
  );
};
