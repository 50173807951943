import { makeStyles } from "@material-ui/core";

export const tableActionStyles = makeStyles(() => ({
  action: {
    border: "2px solid #E0E0E0",
    width: "auto",
  },
  label: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  refresh: {
    marginLeft: "10px",
    minWidth: "30px",
    width: "30px",
  },
}));
