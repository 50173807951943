import { ActionsUnion, DispatchAction } from "../type";
import { createAction } from "../action";
import { ChargePointType } from "./type";
import { deleteById, fetch, insert, update } from "../../api/apiClient";
import { NOTIFICATIONS_MESSAGES, setSuccess } from "../notifications";
import { ApiError } from "../../api/types";

export const CHARGE_POINT_TYPE_URL = "/assets/chargepoint";

export enum ActionTypes {
  FETCH_CHARGE_POINT_TYPES_REQUEST = "FETCH_CHARGE_POINT_TYPES_REQUEST",
  FETCH_CHARGE_POINT_TYPES_SUCCESS = "FETCH_CHARGE_POINT_TYPES_SUCCESS",
  FETCH_CHARGE_POINT_TYPES_FAILURE = "FETCH_CHARGE_POINT_TYPES_FAILURE",
  FETCH_CHARGE_POINT_TYPE_REQUEST = "FETCH_CHARGE_POINT_TYPE_REQUEST",
  FETCH_CHARGE_POINT_TYPE_SUCCESS = "FETCH_CHARGE_POINT_TYPE_SUCCESS",
  FETCH_CHARGE_POINT_TYPE_FAILURE = "FETCH_CHARGE_POINT_TYPE_FAILURE",
  DELETE_CHARGE_POINT_TYPE_REQUEST = "DELETE_CHARGE_POINT_TYPE_REQUEST",
  DELETE_CHARGE_POINT_TYPE_SUCCESS = "DELETE_CHARGE_POINT_TYPE_SUCCESS",
  DELETE_CHARGE_POINT_TYPE_FAILURE = "DELETE_CHARGE_POINT_TYPE_FAILURE",
  UPDATE_CHARGE_POINT_TYPE_REQUEST = "UPDATE_CHARGE_POINT_TYPE_REQUEST",
  UPDATE_CHARGE_POINT_TYPE_SUCCESS = "UPDATE_CHARGE_POINT_TYPE_SUCCESS",
  UPDATE_CHARGE_POINT_TYPE_FAILURE = "UPDATE_CHARGE_POINT_TYPE_FAILURE",
  INSERT_CHARGE_POINT_TYPE_REQUEST = "INSERT_CHARGE_POINT_TYPE_REQUEST",
  INSERT_CHARGE_POINT_TYPE_SUCCESS = "INSERT_CHARGE_POINT_TYPE_SUCCESS",
  INSERT_CHARGE_POINT_TYPE_FAILURE = "INSERT_CHARGE_POINT_TYPE_FAILURE",
  TRY_TO_DELETE_CHARGE_POINT_TYPE = "TRY_TO_DELETE_CHARGE_POINT_TYPE",
  UNSET_CHARGE_POINT_TYPE_ERROR = "UNSET_CHARGE_POINT__TYPE_ERROR",
}

export const Actions = {
  fetchChargePointTypesRequest: () =>
    createAction(ActionTypes.FETCH_CHARGE_POINT_TYPES_REQUEST),
  fetchChargePointTypesSuccess: (data: ChargePointType[]) =>
    createAction(ActionTypes.FETCH_CHARGE_POINT_TYPES_SUCCESS, data),
  fetchChargePointTypesError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_CHARGE_POINT_TYPES_FAILURE, error),
  fetchChargePointTypeRequest: () =>
    createAction(ActionTypes.FETCH_CHARGE_POINT_TYPE_REQUEST),
  fetchChargePointTypeSuccess: (chargePointType: ChargePointType) =>
    createAction(ActionTypes.FETCH_CHARGE_POINT_TYPE_SUCCESS, chargePointType),
  fetchChargePointTypeError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_CHARGE_POINT_TYPE_FAILURE, error),
  deleteChargePointTypeRequest: () =>
    createAction(ActionTypes.DELETE_CHARGE_POINT_TYPE_REQUEST),
  deleteChargePointTypeSuccess: (id: number) =>
    createAction(ActionTypes.DELETE_CHARGE_POINT_TYPE_SUCCESS, id),
  deleteChargePointTypeError: (error: ApiError) =>
    createAction(ActionTypes.DELETE_CHARGE_POINT_TYPE_FAILURE, error),
  updateChargePointTypeRequest: () =>
    createAction(ActionTypes.UPDATE_CHARGE_POINT_TYPE_REQUEST),
  updateChargePointTypeSuccess: (chargePointType: ChargePointType) =>
    createAction(ActionTypes.UPDATE_CHARGE_POINT_TYPE_SUCCESS, chargePointType),
  updateChargePointTypeError: (error: ApiError) =>
    createAction(ActionTypes.UPDATE_CHARGE_POINT_TYPE_FAILURE, error),
  insertChargePointTypeRequest: () =>
    createAction(ActionTypes.INSERT_CHARGE_POINT_TYPE_REQUEST),
  insertChargePointTypeSuccess: () =>
    createAction(ActionTypes.INSERT_CHARGE_POINT_TYPE_SUCCESS),
  insertChargePointTypeError: (error: ApiError) =>
    createAction(ActionTypes.INSERT_CHARGE_POINT_TYPE_FAILURE, error),
  tryToDelete: (id: number | null) =>
    createAction(ActionTypes.TRY_TO_DELETE_CHARGE_POINT_TYPE, id),
  unsetChargePointTypeError: () =>
    createAction(ActionTypes.UNSET_CHARGE_POINT_TYPE_ERROR),
};

export type Actions = ActionsUnion<typeof Actions>;

export function fetchChargePointTypes(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchChargePointTypesRequest());

    fetch<ChargePointType[]>(`${CHARGE_POINT_TYPE_URL}/`)
      .then((data) =>
        dispatch(Actions.fetchChargePointTypesSuccess(data ?? []))
      )
      .catch((error) => dispatch(Actions.fetchChargePointTypesError(error)));
  };
}

export function fetchChargePointType(id: string): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchChargePointTypeRequest());

    fetch<ChargePointType>(`${CHARGE_POINT_TYPE_URL}/${id}/`)
      .then((data) => dispatch(Actions.fetchChargePointTypeSuccess(data)))
      .catch((error: ApiError) =>
        dispatch(Actions.fetchChargePointTypeError(error))
      );
  };
}

export function deleteChargePointType(id: number): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.deleteChargePointTypeRequest());

    deleteById(`${CHARGE_POINT_TYPE_URL}/${id}`)
      .then(() => {
        dispatch(Actions.deleteChargePointTypeSuccess(id));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.delete));
      })
      .catch((error: ApiError) =>
        dispatch(Actions.deleteChargePointTypeError(error))
      );
  };
}

export function updateChargePointType(
  chargePointType: ChargePointType
): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.updateChargePointTypeRequest());

    update(`${CHARGE_POINT_TYPE_URL}/${chargePointType.id}/`, chargePointType)
      .then(() => {
        dispatch(Actions.updateChargePointTypeSuccess(chargePointType));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.update));
      })
      .catch((error: ApiError) =>
        dispatch(Actions.updateChargePointTypeError(error))
      );
  };
}

export function insertChargePointType(
  chargePointType: ChargePointType
): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.insertChargePointTypeRequest());

    insert(`${CHARGE_POINT_TYPE_URL}/`, chargePointType)
      .then(() => {
        dispatch(Actions.insertChargePointTypeSuccess());
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.insert));
      })
      .catch((error: ApiError) =>
        dispatch(Actions.insertChargePointTypeError(error))
      );
  };
}

export function tryToDelete(id: number | null): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.tryToDelete(id));
  };
}

export function unsetChargePointTypeError(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.unsetChargePointTypeError());
  };
}
