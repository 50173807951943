import { ListValues } from "../../../../components/forms/assets/types";

export const evseMaxEventsListValues: ListValues[] = [
  {
    id: "1",
    label: "1",
  },
  {
    id: "2",
    label: "2",
  },
  {
    id: "3",
    label: "3",
  },
  {
    id: "4",
    label: "4",
  },
];

export const energyDistributionModelListValues: ListValues[] = [
  {
    id: "0",
    label: "I don’t know how the maximum charge capacity is split",
  },
  {
    id: "1",
    label: "The maximum charge capacity is split between the active connectors",
  },
  {
    id: "2",
    label: "Each connector can always deliver the maximum charge capacity",
  },
];

export const energyDistributionModelOneConnectorListValues: ListValues[] = [
  {
    id: "2",
    label: "Each connector can always deliver the maximum charge capacity",
  },
];
