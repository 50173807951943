/*FIXME This functions names must be reviewed because their purpose is not specifically convert dates into API formats but into strings*/
export const getDateString = (date: Date) =>
  `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }/${date.getFullYear()}`;

export const getApiDateString = (date: Date) =>
  `${date.getFullYear()}-${
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;

export const getApiHoursString = (date: Date) =>
  `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  }`;

export const convertHoursStringToDate = (hours: string) =>
  new Date(`${getApiDateString(new Date())} ${hours}`);

export const isValidDate = (date: Date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    return isNaN(date.getTime());
  } else {
    return false;
  }
};

export const isDate = (date: Date) =>
  Object.prototype.toString.call(date) === "[object Date]";
