import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { HvGrid, HvLinechart, HvTypography } from "@hitachivantara/uikit-react-core";
import { Box } from "@material-ui/core";
import { Loading } from "../../../components/loading/Loading";
import { NoData } from "../../../components/datagrid/NoData";
import { CSVLink } from "react-csv";
//Assets
import { MainComponentProps } from "../../Layout/types/types";
import { resultsStyle } from "./assets/styles";

import {
  layout,
  getSiteAscKva,
  getSupplyCapacitySmart,
  getSupplyCapacityUnmanaged,
  getTotalLoadSmart,
  getTotalLoadUnmanaged,
  getVehicleDemandSmart,
  getVehicleDemandUnmanaged,
} from "./assets/data";
//Redux
import { getResults, unsetResultsError } from "../../../store/results";
//Hooks
import { useErrorModal } from "../../../hooks/useErrorModal";

export const ScenariosResultsDemandProfile: React.FC<MainComponentProps> = () => {
  const classes = resultsStyle();

  const [unmanagedChartData, setUnmanagedChartData] = useState<any>([]);
  const [smartChartData, setSmartChartData] = useState<any>([]);
  useErrorModal(getResults, unsetResultsError);

  const { isRequesting, detail } = useSelector(getResults);

  useEffect(() => {
    if (detail) {
      const siteAscKva = getSiteAscKva(detail);
      const supplyCapacityUnmanaged = getSupplyCapacityUnmanaged(detail);
      const supplyCapacitySmart = getSupplyCapacitySmart(detail);
      const vehicleDemandUnmanaged = getVehicleDemandUnmanaged(detail);
      const totalLoadUnmanaged = getTotalLoadUnmanaged(detail);
      const vehicleDemandSmart = getVehicleDemandSmart(detail);
      const totalLoadSmart = getTotalLoadSmart(detail);

      var unmanagedChartData = [
        vehicleDemandUnmanaged,
        totalLoadUnmanaged,
        siteAscKva,
        supplyCapacityUnmanaged,
      ];
      var smartChartData = [
        vehicleDemandSmart,
        totalLoadSmart,
        siteAscKva,
        supplyCapacitySmart,
      ];
      setUnmanagedChartData(unmanagedChartData);
      setSmartChartData(smartChartData);
    }
  }, [detail]);

  return (
    <WidgetContainer>
      <HvGrid container>
        <HvGrid item xs={12}>
          <Loading isLoading={isRequesting}>
            {!detail && isRequesting && <NoData label={""} />}
            {!detail && !isRequesting && (
              <NoData
                label={"There are no results for this specific Scenario"}
              />
            )}
            {detail && !detail.unmanaged_charging_feasible ? (
              <NoData
                label={
                  "It was not possible to calculate an operationally feasible load profile for your site. This means that vehicles would not be able to keep above a minimum state of charge throughout the week. This could be due to a few reasons, e.g. too few charge points, vehicles or charge points with a low charge speed, schedules being too demanding, or there not being enough time between trips for vehicles to charge sufficiently. To resolve this, try adjusting your schedules or adding more charge points."
                }
              />
            ) : (
              detail && (
                <HvGrid container>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                    Based on the Scenario that you have entered, the Site Planning Tool has calculated the supply capacity that would be required to power your site. 
                    If you need more help in interpreting these results, see our <a href="https://www.ukpowernetworks.co.uk/optimise-prime/site-planning-tool-guide" target="__blank">help pages</a>.
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                      You entered a site Available Supply Capacity (ASC, your connection limit) of{" "}
                      <strong>
                        {detail.scenario_summary.site.asc_kva} kVA
                      </strong>
                      .
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}></HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="xsTitle">Base Case</HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                      The Base Case load (the maximum possible load if all chargers were providing 
                      full output when the site background load is highest is{" "}
                      <strong>{detail.supply_capacity_base_kva} kVA</strong>.
                      If this is below your ASC ({""} 
                      <strong>
                        {detail.scenario_summary.site.asc_kva} kVA
                      </strong>
                      ) you will not need to request a connection upgrade.
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}></HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="xsTitle">
                      Unmanaged Charging
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                      The following Graph shows the expected load in the ‘unmanaged’ case, 
                      where vehicles plug in and charge as soon as they  return to the site, 
                      taking into account trip schedules and charge point availability. 
                      Where the number of EVs exceeds the number of charge points the vehicles 
                      are modelled to charge in batches.
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                     Your maximum demand during the week in this scenario is represented by the 
                     green dashed line. If this is below the ASC (represented by the red dotted line) 
                     you will not need to request a connection upgrade, providing your use of vehicles 
                     does not significantly change.
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={10}>
                    <Box className={classes.chartContainer}>
                      <HvLinechart
                        className={classes.chart}
                        data={unmanagedChartData}
                        xAxisTitle={"Date/Time"}
                        yAxisTitle={"Capacity required (kVA)"}
                        layout={layout}
                      />
                    </Box>
                  </HvGrid>
                  <HvGrid item xs={12}></HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="xsTitle">
                      Smart Charging
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                    The following graph shows the expected load in the ‘smart’
                     case, where charging is managed to minimise the overall peak in demand. 
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                    Your maximum demand during the week in this scenario is {""} 
                      <strong>
                        {detail.supply_capacity_smart_kva} kVA
                      </strong>. 
                    If this is below the ASC ({""} 
                      <strong>
                        {detail.scenario_summary.site.asc_kva} kVA
                      </strong>
                      )  you may not need to request a connection upgrade, 
                      provided you implement a suitable control system to manage charging 
                      patterns and your use of vehicles does not significantly increase. 
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={10}>
                    <Box className={classes.chartContainer}>
                      {!detail.smart_charging_feasible ? (
                        <NoData
                          label={
                            "We were unable to determine a smart load profile that could lower the recommended supply capacity further in comparison to unmanaged charging."
                          }
                        />
                      ) : (
                        <HvLinechart
                          className={classes.chart}
                          data={smartChartData}
                          xAxisTitle={"Date/Time"}
                          yAxisTitle={"Capacity required (kVA)"}
                          layout={layout}
                        />
                      )}
                    </Box>
                  </HvGrid>
                  <HvGrid item xs={12}></HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="xsTitle">
                      Connection Upgrade
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                      If none of the scenarios result in a peak demand that is lower than the ASC, 
                      you will likely need to upgrade your connection. If your demand in the 
                      above graphs only sometimes exceeds the ASC you may benefit 
                      from a profiled connection, giving you more capacity 
                      at specific times of day. You could also try revising your scenario.
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                      <CSVLink
                        data={detail.output_csv_file_string}
                        filename={`${detail.scenario_summary.scenario_name}-LoadProfile.csv`}
                      >
                        Click Here
                      </CSVLink>{" "}
                      to download a file with calculated load that may be used
                      in requesting a new or upgraded connection.
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                      Your installer will always need to inform you Distribution Network Operator of a 
                      charge point installation. If the charge point installation exceeds 30% of your 
                      connection capacity (or 13.8kVA per phase on a non CT metered supply), 
                      an application to the distribution network operator will have to be made and 
                      approved before installation.
                      <br/><br/>
                      For the next steps on installing a charge point or requesting an increase in 
                      connection capacity please visit the <a href="https://www.ukpowernetworks.co.uk/electricity/electric-vehicle-charging-point" target="__blank">UK Power Networks website</a>.
                      <br/><br/>
                      Please note that connection costs and timeframes can vary significantly dependent 
                      on location and local network capacity.
                    </HvTypography>
                  </HvGrid>
                  <HvGrid item xs={12}>
                    <HvTypography variant="normalText">
                     The results given above simulate the connection
                     requirement based on the data you have supplied. This 
                     result does not constitute a connection offer, or an
                     indication that connection capacity is available on the network.
                    </HvTypography>
                  </HvGrid>
                </HvGrid>
              )
            )}
          </Loading>
        </HvGrid>
      </HvGrid>
    </WidgetContainer>
  );
};
