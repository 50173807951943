import React from "react";
//Types
import { Route } from "./types";
//Components
import { Page } from "../pages/Layout/Page";
import { SubPageCRUD } from "../pages/Layout/SubPageCRUD";
import { Logout } from "../pages/Auth/Logout";
import { Home } from "../pages/Home";
import {
  ScenariosAdd,
  ScenariosDetail,
  ScenariosTable,
} from "../pages/Scenarios/ScenariosManage";
import {
  ScenariosResultsInfo,
  ScenariosResultsChargingCosts,
  ScenariosResultsDemandProfile,
  ScenariosResultsPage,
  ScenariosResultsScenarioDetails,
} from "../pages/Scenarios/ScenariosResults";
import { SitesAdd, SitesDetail, SitesTable } from "../pages/Catalogue/Sites";
import {
  ChargePointTypesAdd,
  ChargePointTypesDetail,
  ChargePointTypesTable,
} from "../pages/Catalogue/ChargePointTypes";
import {
  ElectricVehicleTypesAdd,
  ElectricVehicleTypesDetail,
  ElectricVehicleTypesTable,
} from "../pages/Catalogue/ElectricVehicleTypes";
import {
  SchedulesAdd,
  SchedulesDetail,
  SchedulesTable,
} from "../pages/Catalogue/Schedules";
import { CatalogueInfo } from "../pages/Catalogue/CatalogueInfo";
import { ScenariosInfo } from "../pages/Scenarios/ScenariosManage/ScenariosInfo";
//Icons
import {
  Archives,
  LogOut,
  OpenBook,
  Home as House,
} from "@hitachivantara/uikit-react-icons";

export const routes: Route[] = [
  {
    id: "1",
    label: "Home",
    path: "/",
    Component: Page,
    icon: <House />,
    align: "left",
    children: [
      {
        id: "1.1",
        label: "Welcome",
        path: "/",
        Component: Home,
      },
    ],
  },
  {
    id: "2",
    label: "Catalogue",
    path: "/catalogue",
    Component: Page,
    icon: <OpenBook />,
    align: "left",
    children: [
      {
        id: "2.1",
        label: "Info",
        path: "/catalogue",
        Component: CatalogueInfo,
      },

      {
        id: "2.2",
        label: "Sites",
        path: "/catalogue/sites",
        Component: SubPageCRUD,
        children: [
          {
            id: "2.2.1",
            label: "Sites",
            path: "/catalogue/sites",
            Component: SitesTable,
          },
          {
            id: "2.2.2",
            label: "Sites Insert",
            path: "/catalogue/sites/add",
            Component: SitesAdd,
          },
          {
            id: "2.2.3",
            label: "Sites Details",
            path: "/catalogue/sites/:id",
            Component: SitesDetail,
          },
        ],
      },
      {
        id: "2.3",
        label: "Charge Point Types",
        path: "/catalogue/charge-point-types",
        Component: SubPageCRUD,
        children: [
          {
            id: "2.3.1",
            label: "Charge Point Types",
            path: "/catalogue/charge-point-types",
            Component: ChargePointTypesTable,
          },
          {
            id: "2.3.2",
            label: "Charge Point Types Insert",
            path: "/catalogue/charge-point-types/add",
            Component: ChargePointTypesAdd,
          },
          {
            id: "2.3.3",
            label: "Charge Point Types Details",
            path: "/catalogue/charge-point-types/:id",
            Component: ChargePointTypesDetail,
          },
        ],
      },
      {
        id: "2.4",
        label: "Electric Vehicle Types",
        path: "/catalogue/electric-vehicle-types",
        Component: SubPageCRUD,
        children: [
          {
            id: "2.4.1",
            label: "Electric Vehicle Types",
            path: "/catalogue/electric-vehicle-types",
            Component: ElectricVehicleTypesTable,
          },
          {
            id: "2.4.2",
            label: "Electric Vehicle Types Insert",
            path: "/catalogue/electric-vehicle-types/add",
            Component: ElectricVehicleTypesAdd,
          },
          {
            id: "2.4.3",
            label: "Electric Vehicle Types Details",
            path: "/catalogue/electric-vehicle-types/:id",
            Component: ElectricVehicleTypesDetail,
          },
        ],
      },
      {
        id: "2.5",
        label: "Schedules",
        path: "/catalogue/schedules",
        Component: SubPageCRUD,
        children: [
          {
            id: "2.5.1",
            label: "Schedules",
            path: "/catalogue/schedules",
            Component: SchedulesTable,
          },
          {
            id: "2.5.2",
            label: "Electric Vehicle Types Insert",
            path: "/catalogue/schedules/add",
            Component: SchedulesAdd,
          },
          {
            id: "2.5.3",
            label: "Electric Vehicle Types Details",
            path: "/catalogue/schedules/:id",
            Component: SchedulesDetail,
          },
        ],
      },
    ],
  },
  {
    id: "3",
    label: "Scenarios",
    path: "/scenarios",
    icon: <Archives />,
    align: "left",
    Component: Page,
    children: [
      {
        id: "3.1",
        label: "Info",
        path: "/scenarios",
        Component: ScenariosInfo,
      },
      {
        id: "3.2",
        label: "Scenarios",
        path: "/scenarios/manage",
        Component: SubPageCRUD,
        children: [
          {
            id: "3.2.1",
            label: "Scenarios",
            path: "/scenarios/manage",
            Component: ScenariosTable,
          },
          {
            id: "3.2.2",
            label: "Scenarios Insert",
            path: "/scenarios/manage/add",
            Component: ScenariosAdd,
          },
          {
            id: "3.2.3",
            label: "Scenarios Details",
            path: "/scenarios/manage/:id",
            Component: ScenariosDetail,
          },
        ],
      },
    ],
  },
  {
    id: "4",
    label: "Scenario Results",
    path: "/scenario-results/:id",
    Component: ScenariosResultsPage,
    hidden: true,
    children: [
      {
        id: "4.1",
        label: "Info",
        path: "/scenario-results/:id",
        Component: ScenariosResultsInfo,
      },
      {
        id: "4.2",
        label: "Demand Profile",
        path: "/scenario-results/:id/demand-profile",
        Component: ScenariosResultsDemandProfile,
      },
      {
        id: "4.3",
        label: "Charging Costs",
        path: "/scenario-results/:id/charging-costs/",
        Component: ScenariosResultsChargingCosts,
      },
      {
        id: "4.4",
        label: "Scenario Details",
        path: "/scenario-results/:id/scenario-details/",
        Component: ScenariosResultsScenarioDetails,
      },
    ],
  },
  {
    id: "5",
    label: "Logout",
    path: "/logout",
    Component: Logout,
    hidden: false,
    align: "right",
    icon: <LogOut />,
  },
];
