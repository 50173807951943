import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
//Components
import { ListValueProp } from "@hitachivantara/uikit-react-core";
import { StyledDropdownInput } from "../../../components/styled/inputs";
//Other
import { energyCostModelListValues } from "../ScenariosManage/assets/dropdowns";
import { isTariffScheduleEmpty } from "../../Catalogue/Sites/assets/formattings";
import { getSites } from "../../../store/sites";
import { FormComponent } from "../../../components/forms/assets/types";
import { selectValueFromList } from "../../../utils/dropdowns";

export const EnergyCostModelFormComponent: React.FC<FormComponent> = ({
  name,
  onChange,
  initialValue,
  invalid,
  label,
  readOnly,
  formValues,
}) => {
  const [dropdownState, setDropdownState] = useState<{
    key: string | undefined;
    listValues: ListValueProp[];
  }>({ key: undefined, listValues: [] });

  const { data } = useSelector(getSites);
  const siteId = formValues["site"];

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }
    const selectedSite = data.find(
      (site) => String(site.id) === String(siteId)
    );
    if (
      !selectedSite ||
      !isTariffScheduleEmpty(
        selectedSite.electricity_tariff_pence_per_kwh_variable
      )
    ) {
      setDropdownState({
        key: siteId,
        listValues: selectValueFromList(
          [...energyCostModelListValues],
          initialValue
        ),
      });
    } else {
      setDropdownState({
        key: siteId,
        listValues: selectValueFromList(
          [{ id: "1", label: "Fixed Electricity Price" }],
          initialValue
        ),
      });
    }
  }, [data, siteId, initialValue, name]);

  const handleChange = useCallback(
    (selected: ListValueProp | ListValueProp[] | undefined) => {
      if (selected && !Array.isArray(selected)) {
        onChange(name, selected.id ?? "0");
      } else {
        onChange(name, "0");
      }
    },
    [name, onChange]
  );

  return (
    <>
      {dropdownState.listValues && dropdownState.listValues.length > 0 && (
        <StyledDropdownInput
          key={dropdownState.key}
          id={label}
          className={invalid ? "invalid" : ""}
          values={dropdownState.listValues}
          hasTooltips
          onChange={handleChange}
          disabled={readOnly}
        />
      )}
      {(!dropdownState.listValues || dropdownState.listValues.length === 0) && (
        <StyledDropdownInput id={label} disabled={true} />
      )}
    </>
  );
};
