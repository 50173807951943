import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { HvGrid, ListValueProp } from "@hitachivantara/uikit-react-core";
import { ConfirmModal } from "../modals/ConfirmModal";
import { StyledDropdownMenu } from "../../components/styled/menus";
import { useHistory } from "react-router";
import { MouseEvent } from 'react';

//Actions
import { deleteAccount } from "../../store/account/action";
//Redux
import { getAccount } from "../../store/account";
//Hooks
import useMsal from "../msal/assets/useMsal";
//Others
import { User } from "@hitachivantara/uikit-react-icons";
import { userActionsStyles } from "./styles";
import { ListValues } from "../../components/forms/assets/types";

interface Props {}

export const UserActions: React.FC<Props> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { editProfile, logout } = useMsal();
  const { isAccountDeleted } = useSelector(getAccount);

  const classes = userActionsStyles();
  const [tryingToDelete, setTryingToDelete] = useState(false);

  const userActionsListValues: ListValues[] = [
    {
      id: "1",
      label: "Edit Profile",
    },
    {
      id: "2",
      label: "Delete User",
    }
  ];

  const handleChange = (event: MouseEvent, item: ListValueProp) => {
    switch (item.id) {
      case "1":
        editProfile();
        break;
      case "2":
        setTryingToDelete(true);
        break;
      case "3":
        history.push(`/logout`);
        break;
    }
  };

  const handleConfirmTryingToDelete = () => {
    dispatch(deleteAccount());
  };
  const handleCancelTryingToDelete = () => {
    setTryingToDelete(false);
  };

  useEffect(() => {
    isAccountDeleted && logout();
  }, [isAccountDeleted, logout]);

  return (
    <HvGrid className={classes.userActions} item xs={12}>
      <StyledDropdownMenu
        icon={<User />}
        dataList={userActionsListValues}
        onClick={handleChange}
      />
      <ConfirmModal
        open={tryingToDelete}
        title={"Delete User Account"}
        content={
          "Are you sure you want to delete your user account? Selecting Yes will permanently delete your account and all data that you have entered."
        }
        onConfirm={handleConfirmTryingToDelete}
        onClose={handleCancelTryingToDelete}
      />
    </HvGrid>
  );
};
