import { makeStyles } from "@material-ui/core";

export const formStyles = makeStyles(() => ({
  title: {
    borderBottom: "2px solid #C4C4C4",
  },
  fieldsContainer: {
    paddingLeft: "10px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  fieldLabel: {
    display: "flex",
    alignItems: "center",
  },
  fieldLabelTop: {
    display: "flex",
    alignItems: "flex-start",
    paddingTop: "25px !important",
  },
  fieldValue: {
    display: "flex",
    alignItems: "center",
  },
  fieldError: {
    color: "red !important",
  },
  readOnly: {
    height: "32px",
    paddingTop: "5px",
  },
  actions: {
    borderTop: "2px solid #C4C4C4",
    "& button": {
      marginRight: "5px",
    },
  },
  actionsContainer: {
    display: "flex",
  },
  addButton: {
    marginRight: "5px",
  },
  deleteButton: {
    marginLeft: "auto",
  },
  inputFile: {
    width: "90px",
  },
  inputFileLabel: {
    marginRight: "20px",
    marginLeft: "5px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "350px",
    textOverflow: "ellipsis",
  },
  multiSelect: {
    width: "523px",
  },
  multiSelectMenu: {
    backgroundColor: "#FFFFFF !important",
  },
  columnLabel: {
    fontSize: "13px",
    color: "gray",
  },
  circularButton: {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    marginRight: "5px",
    fontSize: "20px",
  },
  multilineHeader: {
    marginTop: "10px",
  },
  tooltipWrapper: {
    position: "relative",
    marginLeft: "5px",
  },
  tooltipButton: {
    position: "relative",
    background: "#30354a",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
  },
  tooltip: {
    position: "absolute",
    width: "350px",
    top: "25px",
    background: "#30354a",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "4px 8px",
    fontSize: "13px",
    borderRadius: "4px",
    display: "none",
    zIndex: 9999,
    "&[data-show]": {
      display: "block",
    },
  },
}));

export const buttonStyles = makeStyles(() => ({
  title: {
    borderBottom: "2px solid #C4C4C4",
  },
  fieldsContainer: {
    paddingLeft: "10px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  fieldLabel: {
    display: "flex",
    alignItems: "center",
  },
  fieldLabelTop: {
    display: "flex",
    alignItems: "flex-start",
    paddingTop: "25px !important",
  },
  fieldValue: {
    display: "flex",
    alignItems: "center",
  },
  fieldError: {
    color: "red !important",
  },
  readOnly: {
    height: "32px",
    paddingTop: "5px",
  },
  actions: {
    borderTop: "2px solid #C4C4C4",
    "& button": {
      marginRight: "5px",

    },
  },
  actionsContainer: {
    display: "flex",
  },
  addButton: {
    marginRight: "5px",
  },
  deleteButton: {
    marginLeft: "auto",
  },
  inputFile: {
    width: "90px",
  },
  inputFileLabel: {
    marginRight: "20px",
    marginLeft: "5px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "350px",
    textOverflow: "ellipsis",
  },
  multiSelect: {
    width: "523px",
  },
  multiSelectMenu: {
    backgroundColor: "#FFFFFF !important",
  },
  columnLabel: {
    fontSize: "13px",
    color: "gray",
  },
  circularButton: {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    marginRight: "5px",
    fontSize: "20px",
  },
  multilineHeader: {
    marginTop: "10px",
  },
  tooltipWrapper: {
    position: "absolute", 
    display: "inline",
  },
  tooltipButton: {
    position: "relative",
    background: "#f76100",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    fontSize: "8px",
    fontWeight: "bold",
    "&:hover": {
      background: "#e13000",
    }
  },
  tooltip: {
    position: "absolute",
    width: "350px",
    top: "25px",
    background: "#4D4D4D",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "4px 8px",
    fontSize: "13px",
    borderRadius: "4px",
    display: "none",
    zIndex: 9999,
    marginTop: "10px",
    "&[data-show]": {
      display: "block",
    },
  },
  spanIcon: {
    fontSize: "20px",
  },
}));
