import { ActionsUnion, DispatchAction } from "../type";
import { createAction } from "../action";
import { ElectricVehicleType } from "./type";
import { deleteById, fetch, insert, update } from "../../api/apiClient";
import { NOTIFICATIONS_MESSAGES, setSuccess } from "../notifications";
import { ApiError } from "../../api/types";

export const EV_TYPE_URL = "assets/electric-vehicle-types";

export enum ActionTypes {
  FETCH_EV_TYPES_REQUEST = "FETCH_EV_TYPES_REQUEST",
  FETCH_EV_TYPES_SUCCESS = "FETCH_EV_TYPES_SUCCESS",
  FETCH_EV_TYPES_FAILURE = "FETCH_EV_TYPES_FAILURE",
  FETCH_EV_TYPE_REQUEST = "FETCH_EV_TYPE_REQUEST",
  FETCH_EV_TYPE_SUCCESS = "FETCH_EV_TYPE_SUCCESS",
  FETCH_EV_TYPE_FAILURE = "FETCH_EV_TYPE_FAILURE",
  DELETE_EV_TYPE_REQUEST = "DELETE_EV_TYPE_REQUEST",
  DELETE_EV_TYPE_SUCCESS = "DELETE_EV_TYPE_SUCCESS",
  DELETE_EV_TYPE_FAILURE = "DELETE_EV_TYPE_FAILURE",
  UPDATE_EV_TYPE_REQUEST = "UPDATE_EV_TYPE_REQUEST",
  UPDATE_EV_TYPE_SUCCESS = "UPDATE_EV_TYPE_SUCCESS",
  UPDATE_EV_TYPE_FAILURE = "UPDATE_EV_TYPE_FAILURE",
  INSERT_EV_TYPE_REQUEST = "INSERT_EV_TYPE_REQUEST",
  INSERT_EV_TYPE_SUCCESS = "INSERT_EV_TYPE_SUCCESS",
  INSERT_EV_TYPE_FAILURE = "INSERT_EV_TYPE_FAILURE",
  TRY_TO_DELETE_EV_TYPE = "TRY_TO_DELETE_EV_TYPE",
  UNSET_EV_TYPE_ERROR = "UNSET_EV_TYPE_ERROR",
}

export const Actions = {
  fetchElectricVehicleTypesRequest: () =>
    createAction(ActionTypes.FETCH_EV_TYPES_REQUEST),
  fetchElectricVehicleTypesSuccess: (data: ElectricVehicleType[]) =>
    createAction(ActionTypes.FETCH_EV_TYPES_SUCCESS, data),
  fetchElectricVehicleTypesError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_EV_TYPES_FAILURE, error),
  fetchElectricVehicleTypeRequest: () =>
    createAction(ActionTypes.FETCH_EV_TYPE_REQUEST),
  fetchElectricVehicleTypeSuccess: (electricVehicleType: ElectricVehicleType) =>
    createAction(ActionTypes.FETCH_EV_TYPE_SUCCESS, electricVehicleType),
  fetchElectricVehicleTypeError: (error: ApiError) =>
    createAction(ActionTypes.FETCH_EV_TYPE_FAILURE, error),
  deleteElectricVehicleTypeRequest: () =>
    createAction(ActionTypes.DELETE_EV_TYPE_REQUEST),
  deleteElectricVehicleTypeSuccess: (id: number) =>
    createAction(ActionTypes.DELETE_EV_TYPE_SUCCESS, id),
  deleteElectricVehicleTypeError: (error: ApiError) =>
    createAction(ActionTypes.DELETE_EV_TYPE_FAILURE, error),
  updateElectricVehicleTypeRequest: () =>
    createAction(ActionTypes.UPDATE_EV_TYPE_REQUEST),
  updateElectricVehicleTypeSuccess: (
    electricVehicleType: ElectricVehicleType
  ) => createAction(ActionTypes.UPDATE_EV_TYPE_SUCCESS, electricVehicleType),
  updateElectricVehicleTypeError: (error: ApiError) =>
    createAction(ActionTypes.UPDATE_EV_TYPE_FAILURE, error),
  insertElectricVehicleTypeRequest: () =>
    createAction(ActionTypes.INSERT_EV_TYPE_REQUEST),
  insertElectricVehicleTypeSuccess: () =>
    createAction(ActionTypes.INSERT_EV_TYPE_SUCCESS),
  insertElectricVehicleTypeError: (error: ApiError) =>
    createAction(ActionTypes.INSERT_EV_TYPE_FAILURE, error),
  tryToDelete: (id: number | null) =>
    createAction(ActionTypes.TRY_TO_DELETE_EV_TYPE, id),
  unsetEletricVehicleTypeError: () =>
    createAction(ActionTypes.UNSET_EV_TYPE_ERROR),
};

export type Actions = ActionsUnion<typeof Actions>;

export function fetchElectricVehicleTypes(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchElectricVehicleTypesRequest());

    fetch<ElectricVehicleType[]>(`${EV_TYPE_URL}/`)
      .then((data) => {
        dispatch(Actions.fetchElectricVehicleTypesSuccess(data ?? []));
      })
      .catch((error) => {
        dispatch(Actions.fetchElectricVehicleTypesError(error));
      });
  };
}

export function fetchElectricVehicleType(id: string): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.fetchElectricVehicleTypeRequest());

    fetch<ElectricVehicleType>(`${EV_TYPE_URL}/${id}/`)
      .then((data) => dispatch(Actions.fetchElectricVehicleTypeSuccess(data)))
      .catch((error: ApiError) =>
        dispatch(Actions.fetchElectricVehicleTypeError(error))
      );
  };
}

export function deleteElectricVehicleType(id: number): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.deleteElectricVehicleTypeRequest());

    deleteById(`${EV_TYPE_URL}/${id}`)
      .then(() => {
        dispatch(Actions.deleteElectricVehicleTypeSuccess(id));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.delete));
      })
      .catch((error: ApiError) =>
        dispatch(Actions.deleteElectricVehicleTypeError(error))
      );
  };
}

export function updateElectricVehicleType(
  electricVehicleType: ElectricVehicleType
): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.updateElectricVehicleTypeRequest());

    update(`${EV_TYPE_URL}/${electricVehicleType.id}/`, electricVehicleType)
      .then(() => {
        dispatch(Actions.updateElectricVehicleTypeSuccess(electricVehicleType));
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.update));
      })
      .catch((error: ApiError) =>
        dispatch(Actions.updateElectricVehicleTypeError(error))
      );
  };
}

export function insertElectricVehicleType(
  electricVehicleType: ElectricVehicleType
): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.insertElectricVehicleTypeRequest());

    insert(`${EV_TYPE_URL}/`, electricVehicleType)
      .then(() => {
        dispatch(Actions.insertElectricVehicleTypeSuccess());
        dispatch(setSuccess(NOTIFICATIONS_MESSAGES.insert));
      })
      .catch((error: ApiError) => {
        dispatch(Actions.insertElectricVehicleTypeError(error));
      });
  };
}

export function tryToDelete(id: number | null): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.tryToDelete(id));
  };
}

export function unsetEletricVehicleTypeError(): DispatchAction {
  return async (dispatch) => {
    dispatch(Actions.unsetEletricVehicleTypeError());
  };
}
