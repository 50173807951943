import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { WidgetContainer } from "../../../components/layout/WidgetContainer";
import { Form } from "../../../components/forms/Form";
//Redux
import {
  cloneSchedule,
  getSchedules,
  insertSchedule,
  unsetScheduleError,
} from "../../../store/schedules";
//Hooks
import { useErrorModal } from "../../../hooks/useErrorModal";
import { useNavigation } from "../../../hooks/useNavigation";
//Others
import { formatTripsToDetail } from "./assets/formattings";
import { mapScheduleToApiSchedule, mapTripsToApiTrips } from "./assets/mappings";
import { MainComponentProps } from "../../Layout/types/types";
import { detailFields } from "./assets/fields";
import { FormValues, FormValuesLine } from "../../../components/forms/assets/types";
import { Schedule } from "../../../store/schedules/type";
import { SCHEDULE_TITLE } from "./assets/namings";
import { SchedulesDescription } from "./assets/Tooltips";

export const SchedulesAdd: React.FC<MainComponentProps> = () => {
  const dispatch = useDispatch();
  useErrorModal(getSchedules, unsetScheduleError);
  const { navigateToTable } = useNavigation();

  const fields = useMemo(() => detailFields.filter(({ id }) => !id), []);

  const [isInserting, setIsInserting] = useState(false);

  const { isRequesting, detail, error } = useSelector(getSchedules);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const schedule = values as Schedule;
      dispatch(insertSchedule(mapScheduleToApiSchedule(schedule)));
    },
    [dispatch]
  );

  const formatDetail = useMemo(
    () =>
      detail
        ? {
            ...detail,
            mon: formatTripsToDetail(detail.mon),
            tue: formatTripsToDetail(detail.tue),
            wed: formatTripsToDetail(detail.wed),
            thu: formatTripsToDetail(detail.thu),
            fri: formatTripsToDetail(detail.fri),
            sat: formatTripsToDetail(detail.sat),
            sun: formatTripsToDetail(detail.sun),
          }
        : null,
    [detail]
  );

  const handleOnClone = useCallback((formValues: FormValues) => {
    const detail = formValues["cloneDetail"] as Array<FormValuesLine>;
      const scheduleTrip = mapTripsToApiTrips(detail.map((formLine) => ({
        trip_start_time: formLine.trip_start_time,
        trip_end_time: formLine.trip_end_time,
        trip_distance_miles: formLine.trip_distance_miles
      })));

      let scheduleName = "";
      if (formValues?.name)
        scheduleName = formValues.name;

      const cloneDetail = {
        id: 0,
        name: scheduleName,
        mon: scheduleTrip,
        tue: scheduleTrip,
        wed: scheduleTrip,
        thu: scheduleTrip,
        fri: scheduleTrip,
        sat: scheduleTrip,
        sun: scheduleTrip,
      };
      dispatch(cloneSchedule(cloneDetail));
    }, []);

  useEffect(() => {
    if (isRequesting) {
      setIsInserting(true);
    } else if (isInserting) {
      if (error) {
        setIsInserting(false);
      } else {
        navigateToTable();
      }
    }
  }, [isRequesting, error, isInserting, setIsInserting, navigateToTable]);

  return (
    <WidgetContainer>
      <Form
        isLoading={isRequesting}
        title={`Add ${SCHEDULE_TITLE}`}
        description={<SchedulesDescription />}
        fields={fields}
        formErrors={error?.formErrors}
        onSubmit={handleSubmit}
        onCancel={navigateToTable}
        onClone={handleOnClone}
        initialValues={formatDetail}
      />
    </WidgetContainer>
  );
};
