import { TableColumn } from "@hitachivantara/uikit-react-core";
import { FormField } from "../../../../components/forms/assets/types";
import { SiteTariffSchedule } from "../CustomFormComponents/SiteTariffSchedule";
import { formatTariffsToTable } from "./formattings";
import {
  siteFieldNamings,
  tariffFieldNamings,
  tariffScheduleFieldNamings,
} from "./namings";
import {
  validateDay,
  validateFile,
  validateTariffPencePerKwh,
  validateTariffEndTime,
} from "./validations";
import { getCurrencyString } from "../../../../utils/currency";
import { dailyPtusListValues } from "./dropdowns";

import {
  SiteNameTooltip,
  SiteAscKvaTooltip,
  SiteElectricityUseCsvFileTooltip,
  SiteElectricityTariffTooltip,
  SiteTariffScheduleTooltip,
  SiteCapexCostTooltip,
  SiteOpexCostTooltip,
  SiteCloneTooltip
} from "./Tooltips";

export const createDefaultTariff = () => ({
  electricity_tariff_start_daily_ptu: 1,
  electricity_tariff_end_daily_ptu: 1,
  electricity_tariff_pence_per_kwh: 0,
});

export const tableColumns: TableColumn[] = [
  {
    headerText: siteFieldNamings.name.label,
    accessor: siteFieldNamings.name.accessor,
    cellType: "alpha-numeric",
  },
  {
    headerText: siteFieldNamings.location.description,
    accessor: siteFieldNamings.location.accessor,
    cellType: "alpha-numeric",
  },
  {
    headerText: siteFieldNamings.asc_kva.description,
    accessor: siteFieldNamings.asc_kva.accessor,
    cellType: "numeric",
  },
  {
    headerText: siteFieldNamings.electricity_use_kwh_csv_file_name?.description,
    accessor: siteFieldNamings.electricity_use_kwh_csv_file_name?.accessor,
    cellType: "alpha-numeric",
  },
  {
    headerText: siteFieldNamings.electricity_tariff_pence_per_kwh.description,
    accessor: siteFieldNamings.electricity_tariff_pence_per_kwh.accessor,
    cellType: "numeric",
  },
  {
    headerText:
      siteFieldNamings.electricity_tariff_pence_per_kwh_variable.description,
    accessor:
      siteFieldNamings.electricity_tariff_pence_per_kwh_variable.accessor,
    format: (value) => formatTariffsToTable(value.value),
    cellType: "alpha-numeric",
  },
  {
    headerText: siteFieldNamings.capex_cost_gbp.description,
    accessor: siteFieldNamings.capex_cost_gbp.accessor,
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
  {
    headerText: siteFieldNamings.opex_cost_gbp_per_month.description,
    accessor: siteFieldNamings.opex_cost_gbp_per_month.accessor,
    format: (value) =>
      isNaN(value.value) ? value.value : getCurrencyString(value.value),
    cellType: "numeric",
  },
];

export const detailFields: FormField[] = [
  {
    label: siteFieldNamings.name.description,
    accessor: siteFieldNamings.name.accessor,
    required: true,
    maxCharacters: 50,
    tooltipContent: SiteNameTooltip,
  },
  {
    label: siteFieldNamings.location.description,
    accessor: siteFieldNamings.location.accessor,
    required: true,
    maxCharacters: 1000,
  },
  {
    label: siteFieldNamings.asc_kva.description,
    accessor: siteFieldNamings.asc_kva.accessor,
    required: true,
    inputType: "number",
    dataType: "integer",
    maxCharacters: 5,
    range: { min: 0, max: 99999 },
    tooltipContent: SiteAscKvaTooltip,
  },
  {
    label: siteFieldNamings.electricity_use_kwh_csv_file.description,
    accessor: siteFieldNamings.electricity_use_kwh_csv_file.accessor,
    readAccessor: siteFieldNamings.electricity_use_kwh_csv_file_name?.accessor,
    inputType: "file",
    customValidation: (value, values) => validateFile(value),
    boldLabel: true,
    tooltipContent: SiteElectricityUseCsvFileTooltip,
  },
  {
    label: siteFieldNamings.electricity_tariff_pence_per_kwh.description,
    accessor: siteFieldNamings.electricity_tariff_pence_per_kwh.accessor,
    required: true,
    inputType: "number",
    dataType: "number",
    maxCharacters: 10,
    regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
    tooltipContent: SiteElectricityTariffTooltip,
  },
  {
    label:
      siteFieldNamings.electricity_tariff_pence_per_kwh_variable.description,
    accessor:
      siteFieldNamings.electricity_tariff_pence_per_kwh_variable.accessor,
    subtitle: siteFieldNamings.electricity_tariff_pence_per_kwh_variable.subtitle,
    inputType: "customComponent",
    CustomComponent: SiteTariffSchedule,
    tooltipContent: SiteTariffScheduleTooltip,
    multiFields: [
      {
        label: tariffScheduleFieldNamings.mon.description,
        accessor: tariffScheduleFieldNamings.mon.accessor,
        inputType: "multiline",
        customValidation: (value, values) => validateDay(value),
        getDefaultValue: createDefaultTariff,
        cloneOption: true,
        tooltipCloneContent: SiteCloneTooltip,
        multiLineFields: [
          {
            label:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
            customValidation: (value, values) => validateTariffEndTime(values),
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.description,
            accessor:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.accessor,
            inputType: "number",
            dataType: "number",
            maxCharacters: 10,
            regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
            required: true,
            customValidation: (value, values) =>
              validateTariffPencePerKwh(Number(value)),
          },
        ],
      },
      {
        label: tariffScheduleFieldNamings.tue.description,
        accessor: tariffScheduleFieldNamings.tue.accessor,
        inputType: "multiline",
        customValidation: (value, values) => validateDay(value),
        getDefaultValue: createDefaultTariff,
        multiLineFields: [
          {
            label:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
            customValidation: (value, values) => validateTariffEndTime(values),
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.description,
            accessor:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.accessor,
            inputType: "number",
            dataType: "number",
            maxCharacters: 10,
            regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
            required: true,
            customValidation: (value, values) =>
              validateTariffPencePerKwh(Number(value)),
          },
        ],
      },
      {
        label: tariffScheduleFieldNamings.wed.description,
        accessor: tariffScheduleFieldNamings.wed.accessor,
        inputType: "multiline",
        customValidation: (value, values) => validateDay(value),
        getDefaultValue: createDefaultTariff,
        multiLineFields: [
          {
            label:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
            customValidation: (value, values) => validateTariffEndTime(values),
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.description,
            accessor:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.accessor,
            inputType: "number",
            dataType: "number",
            maxCharacters: 10,
            regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
            required: true,
            customValidation: (value, values) =>
              validateTariffPencePerKwh(Number(value)),
          },
        ],
      },
      {
        label: tariffScheduleFieldNamings.thu.description,
        accessor: tariffScheduleFieldNamings.thu.accessor,
        inputType: "multiline",
        customValidation: (value, values) => validateDay(value),
        getDefaultValue: createDefaultTariff,
        multiLineFields: [
          {
            label:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
            customValidation: (value, values) => validateTariffEndTime(values),
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.description,
            accessor:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.accessor,
            inputType: "number",
            dataType: "number",
            maxCharacters: 10,
            regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
            required: true,
            customValidation: (value, values) =>
              validateTariffPencePerKwh(Number(value)),
          },
        ],
      },
      {
        label: tariffScheduleFieldNamings.fri.description,
        accessor: tariffScheduleFieldNamings.fri.accessor,
        inputType: "multiline",
        customValidation: (value, values) => validateDay(value),
        getDefaultValue: createDefaultTariff,
        multiLineFields: [
          {
            label:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
            customValidation: (value, values) => validateTariffEndTime(values),
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.description,
            accessor:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.accessor,
            inputType: "number",
            dataType: "number",
            maxCharacters: 10,
            regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
            required: true,
            customValidation: (value, values) =>
              validateTariffPencePerKwh(Number(value)),
          },
        ],
      },
      {
        label: tariffScheduleFieldNamings.sat.description,
        accessor: tariffScheduleFieldNamings.sat.accessor,
        inputType: "multiline",
        customValidation: (value, values) => validateDay(value),
        getDefaultValue: createDefaultTariff,
        multiLineFields: [
          {
            label:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
            customValidation: (value, values) => validateTariffEndTime(values),
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.description,
            accessor:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.accessor,
            inputType: "number",
            dataType: "number",
            maxCharacters: 10,
            regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
            required: true,
            customValidation: (value, values) =>
              validateTariffPencePerKwh(Number(value)),
          },
        ],
      },
      {
        label: tariffScheduleFieldNamings.sun.description,
        accessor: tariffScheduleFieldNamings.sun.accessor,
        inputType: "multiline",
        customValidation: (value, values) => validateDay(value),
        getDefaultValue: createDefaultTariff,
        multiLineFields: [
          {
            label:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_start_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.description,
            accessor:
              tariffFieldNamings.electricity_tariff_end_daily_ptu.accessor,
            inputType: "list",
            required: true,
            listValues: dailyPtusListValues,
            customValidation: (value, values) => validateTariffEndTime(values),
          },
          {
            label:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.description,
            accessor:
              tariffFieldNamings.electricity_tariff_pence_per_kwh.accessor,
            inputType: "number",
            dataType: "number",
            maxCharacters: 10,
            required: true,
            regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
            customValidation: (value, values) =>
              validateTariffPencePerKwh(Number(value)),
          },
        ],
      },
    ],
  },
  {
    label: siteFieldNamings.capex_cost_gbp.description,
    accessor: siteFieldNamings.capex_cost_gbp.accessor,
    inputType: "number",
    dataType: "number",
    maxCharacters: 10,
    regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
    tooltipContent: SiteCapexCostTooltip,
  },
  {
    label: siteFieldNamings.opex_cost_gbp_per_month.description,
    accessor: siteFieldNamings.opex_cost_gbp_per_month.accessor,
    inputType: "number",
    dataType: "number",
    maxCharacters: 10,
    regex: new RegExp("^\\d+(\\.\\d{1,2})?$"),
    tooltipContent: SiteOpexCostTooltip,
  },
];
