import * as React from "react";
//Components
import { HvTypography } from "@hitachivantara/uikit-react-core";
//Styles
import { noDataStyles } from "./assets/styles";

interface Props {
    label: string;
}

export const NoData: React.FC<Props> = ({
    label
}) => {
    const classes = noDataStyles();

    return (
        <div className={classes.root}>
            <HvTypography variant="normalText">
                { label }
            </HvTypography>
        </div>
    )
} 