import React, { useCallback, useState } from "react";
//Components
import { StyledFormInput } from "../styled/inputs";
import { InputBaseComponentProps } from "@material-ui/core";

interface Props {
  onBlur?: (value: number) => void;
  initialValue?: string;
  invalid?: boolean;
  readOnly?: boolean;
  inputProps?: InputBaseComponentProps;
  className?: string;
  integer?: boolean;
}

export const Numeric: React.FC<Props> = ({
  onBlur,
  initialValue = "",
  invalid,
  readOnly,
  inputProps,
  integer,
  className = "",
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, val: string) => {
      if (integer && val && !new RegExp("^[0-9]*$").test(val)) {
        return "";
      }

      if (isNaN(Number(val))) {
        return "";
      }

      setValue(val);
      return val;
    },
    [integer, setValue]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onBlur && onBlur(Number(event?.target?.value)),
    [onBlur]
  );

  return (
    <StyledFormInput
      disabled={readOnly}
      onBlur={handleBlur}
      invalid={invalid}
      inputProps={inputProps}
      onChange={handleChange}
      className={className}
      value={value}
    />
  );
};
